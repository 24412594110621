import history from 'lib/history'
import { AppState } from 'lib/appState'
// import { onNewServiceWorker } from 'lib/serviceworker'
import api from './api'
import * as actions from './actions'

global.DEBUG = global.DEBUG || {}
global.DEBUG.api = api
global.DEBUG.isDataYogi = isDataYogi // eslint-disable-line

export default new AppState({actions, init})

function init() {

  this.takeAction('newVersion.detect')

  // onNewServiceWorker(() => {
  //   this.takeAction('newVersion.promptToReload')
  // })

  this.setState({ API_SERVER_URL_PREFIX: api.urlPrefix })

  let lastLocationPathnameFirstPart
  const scrollToTop = () => {
    setTimeout(() => { window.scrollTo(0, 0) }, 50)
  }
  const onLocationChange = (_, event) => {
    const usedBackToGetHere = !!event
    const locationPathnameFirstPart = window.location.pathname.split('/')[1] || '__homepage'
    if (
      !usedBackToGetHere &&
      lastLocationPathnameFirstPart &&
      lastLocationPathnameFirstPart !== locationPathnameFirstPart
    ) scrollToTop()
    lastLocationPathnameFirstPart = locationPathnameFirstPart
    // this.takeAction('location.publish')
  }

  history.subscribe(onLocationChange)

  api.on('invalidSession', () => {
    console.warn('logging out because of invalid session')
    this.takeAction('session.recoverFromInvalidSession')
  })

  window.addEventListener('beforeunload', (event) => {
    const hasUnsavedChanges = () => {
      const state = this.getState()
      return (
        Object.keys(state).some(key => key.startsWith('organizationAccountDataStagedChanges:')) ||
        'defaultAccountDataStagedChanges' in state ||
        'newBuyingInterest' in state
      )
    }
    if (hasUnsavedChanges()) {
      return event.returnValue = (
        'Are you sure you want to reload? ' +
        'Changes you made will not be saved.'
      )
    }
  })


  // this.takeAction('chat.startPollingForSynopsis')

  // if (window.location.pathname.match(/^\/verify\/([^\s\/]+)/)){
  //   this.takeAction('externalIdentities.verify', {
  //     verificationCode: RegExp.$1,
  //   })
  // }
  // }else{
  //   this.takeAction('session.restore')

}
