import { useEffect } from 'preact/hooks'

export default function useOnDOMChange({ref, onDOMChange, options = {}}, args = []){
  const {
    attributes = true,
    childList = true,
    subtree = true,
  } = options
  useEffect(
    () => {
      const observer = new global.MutationObserver(onDOMChange)
      observer.observe(
        ref.current,
        { attributes, childList, subtree },
      )
      return () => {
        observer.disconnect()
      }
    },
    [...args, ref, onDOMChange, attributes, childList, subtree],
  )
}
