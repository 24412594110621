import { h } from 'preact'
import PropTypes from 'prop-types'

import './index.sass'

export default function AuthorIconBox({
  className = '',
  children,
  ...props
}){

  return <div
    {...props}
    className={`AuthorIconBox ${className}`}
  >{children}</div>
}

AuthorIconBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
