import { h } from 'preact'
import PropTypes from 'prop-types'

import { useAppState } from 'lib/appState'
import {
  useMyOrganizationMembership,
  useMyPendingOrganizationMemberships,
  useCreateOrganizationMembership,
  useUpdateOrganizationMembership,
  useOrganizationMembershipInviteFor,
} from 'lib/membershipAppStateHooks'
import { useLocation } from 'resources/location'
import { useCurrentUser } from 'resources/auth'

import Button from 'components/Button'

export default function OrganizationMembershipButton({
  organization, disabled, hideIfMember, onJoin,
}) {
  const organizationApikey = organization.apikey
  const {
    createOrganizationMembership,
    creatingOrganizationMembership,
  } = useCreateOrganizationMembership(
    { organizationApikey},
    'OrganizationMembershipButton',
  )
  const location = useLocation()
  const currentUser = useCurrentUser()
  const { organizationMembershipInvite } = useOrganizationMembershipInviteFor(
    organizationApikey,
    'OrganizationMembershipButton'
  )
  const inviteToken = organizationMembershipInvite && organizationMembershipInvite.token

  const { takeAction } = useAppState(undefined, 'OrganizationMembershipButton')
  const { organizationMembership, isAdmin, isMember } =
    useMyOrganizationMembership(organizationApikey, 'OrganizationMembershipButton')
  const {
    updateOrganizationMembership,
    updatingOrganizationMembership,
  } = useUpdateOrganizationMembership(organizationMembership || {}, 'OrganizationMembershipButton')

  const { myPendingOrganizationMemberships } = useMyPendingOrganizationMemberships('OrganizationMembershipButton')
  const pendingMembership = myPendingOrganizationMemberships.find(pendingMembership =>
    pendingMembership.organizationApikey === organizationApikey
  )

  if (
    isAdmin ||
    (
      !isMember &&
      organization.closed_memberships &&
      !organization.users_can_request_membership &&
      !pendingMembership &&
      !inviteToken
    ) ||
    (hideIfMember && isMember)
  ) return null

  const requestedToJoin = pendingMembership && pendingMembership.memberUserDid === pendingMembership.createdByUserDid
  const wasInvited = pendingMembership && pendingMembership.memberUserDid !== pendingMembership.createdByUserDid

  const buttonValue = (
    isMember ? 'Leave' :
    requestedToJoin ? 'Requested to join' :
    organization.closed_memberships && !wasInvited && !inviteToken ? 'Request to join' :
    'Join'
  )

  let href, onClick
  if (currentUser){
    onClick = () => {
      if (organizationMembership && !requestedToJoin && !wasInvited){
        if (!confirm(`Are you sure you want to leave ${organization.name}`)) return // eslint-disable-line
        takeAction('organizationMemberships.remove', { organizationMembership })
      }else if (requestedToJoin && !inviteToken){
        // do nothing
      }else if (wasInvited){
        updateOrganizationMembership({ accept: true })
          .then(() => { if (onJoin) onJoin() })
      }else{
        createOrganizationMembership({ organizationApikey, inviteToken })
          .then(() => { if (onJoin) onJoin() })
      }
    }
  }else{
    href = location.withQuery({ s: 1 }).toSignup().withQuery({ o: organizationApikey })
  }

  return <Button {...{
    className: 'OrganizationMembershipButton',
    type: organizationMembership || pendingMembership ? 'normal' : 'primary',
    disabled: (
      disabled ||
      requestedToJoin ||
      creatingOrganizationMembership ||
      updatingOrganizationMembership
    ),
    value: buttonValue,
    onClick,
    href,
  }}/>
}

OrganizationMembershipButton.propTypes = {
  organization: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  hideIfMember: PropTypes.bool,
  onJoin: PropTypes.func,
  inviteToken: PropTypes.string,
}
