import { h } from 'preact'
import PropTypes from 'prop-types'
import { useState } from 'preact/hooks'
import { useMyPublicProfile } from 'lib/membershipAppStateHooks'
import useOnSuccess from 'lib/useOnSuccessHook'
import useUploadingSet from 'lib/useUploadingSetHook'

import FormBuilder from 'components/FormBuilder'
import PublicProfileFormFields from 'components/PublicProfileFormFields'

export default function EditPublicProfileForm({ className = '' }){
  const [uploading, createUploadingReporter] = useUploadingSet()

  const [changes, setChanges] = useState({})
  const update = changes => {
    if (changes.username) changes.username = changes.username.replace(/([^\w-]+)/g, '')
    setChanges(changes)
  }

  const reset = () => {
    setChanges({})
  }

  const {
    myPublicProfile,
    updatingMyPublicProfile,
    errorUpdatingMyPublicProfile,
    updateMyPublicProfile,
    clearMyPublicProfileError,
  } = useMyPublicProfile('EditPublicProfileForm')

  useOnSuccess(updatingMyPublicProfile, errorUpdatingMyPublicProfile, reset)

  const disabled = !!updatingMyPublicProfile || uploading

  return <FormBuilder
    className={`EditPublicProfileForm ${className}`}
    disabled={disabled}
    error={errorUpdatingMyPublicProfile}
    clearError={clearMyPublicProfileError}
    value={changes}
    persistedValues={myPublicProfile}
    submitting={updatingMyPublicProfile}
    onChange={update}
    onSubmit={updateMyPublicProfile}
    onReset={reset}
    render={form =>
      <PublicProfileFormFields {...{
        form,
        submitText: {
          submit: 'Update Profile',
          submitting: 'Updating Profile…',
        },
        editForm: true,
        onUploading: createUploadingReporter('AvatarInput'),
      }}/>
    }
  />
}

EditPublicProfileForm.propTypes = {
  className: PropTypes.string,
}
