import { h } from 'preact'
import { useState, useRef, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import usePrevious from 'lib/usePreviousHook'
import useFirstRender from 'lib/useFirstRender'

import Icon from 'components/Icon'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import Letterbox from 'components/Letterbox'
import ProgressPie from 'components/ProgressPie'
import RemoveButton from 'components/RemoveButton'
import './index.sass'

export default function MediaSlideshow({
  className,
  slides,
  onRemove,
  ...props
}){
  const sliderRef = useRef()
  const [slide, setSlide] = useState(0)

  const goToPrevSlide = () => {
    setSlide(Math.max(slide - 1, 0))
  }

  const goToNextSlide = () => {
    setSlide(Math.min(slide + 1, slides.length - 1))
  }

  const removeCurrentSlide = () => {
    onRemove(slides[slide], slide)
  }

  useEffect(
    () => {
      const slider = sliderRef.current
      slider.style.left = `-${100 * slide}%`
      Array.from(slider.querySelectorAll('audio,video')).forEach(pauseMedia)
    },
    [slide],
  )

  const firstRender = useFirstRender()
  const prevLength = usePrevious(slides.length)
  useEffect(
    () => {
      if (!firstRender && slides.length > prevLength)
        setSlide(slides.length - 1)
    },
    [slides.length],
  )

  Object.assign(sliderRef, { goToPrevSlide, goToNextSlide })
  useEffect(
    () => {
      const slideDuration = 200
      let lastWheelAt = 0
      let lastSlidAt = 0
      const onWheel = event => {
        if (Math.abs(event.deltaY) >= Math.abs(event.deltaX)) return
        event.preventDefault()
        event.stopPropagation()
        const now = Date.now()
        const x = event.deltaX
        const left = Math.sign(x) === -1
        const msSinceLastWheel = now - lastWheelAt
        const msSinceLastSlide = now - lastSlidAt
        lastWheelAt = now
        if (msSinceLastWheel < 50) return false
        if (msSinceLastSlide < slideDuration) return false
        lastSlidAt = now
        if (left)
          sliderRef.goToPrevSlide()
        else
          sliderRef.goToNextSlide()
        return false
      }
      const slider = sliderRef.current
      slider.addEventListener('wheel', onWheel, {passive:false})
      return () => {
        slider.removeEventListener('wheel', onWheel)
      }
    },
    [],
  )

  const multislide = slides.length > 1

  return <div {...{
    ...props,
    className: classNames('MediaSlideshow', { className }),
  }}>
    <div className="MediaSlideshow-slides">
      <div className="MediaSlideshow-stage">
        <div
          ref={sliderRef}
          className="MediaSlideshow-slider"
          style={{'--number-of-slides': slides.length}}
        >
          {slides.map(slide => <Slide {...slide}/>)}
        </div>
      </div>
      <div className="MediaSlideshow-buttons">
        {onRemove && <RemoveButton {...{
          className: 'MediaSlideshow-remove',
          onClick: removeCurrentSlide,
        }}/>}
        <Button
          type="none"
          className="MediaSlideshow-prev"
          disabled={slide === 0}
          onClick={goToPrevSlide}
        ><Icon type="angle-circled-down"/></Button>
        <Button
          type="none"
          className="MediaSlideshow-next"
          disabled={slide === slides.length - 1}
          onClick={goToNextSlide}
        ><Icon type="angle-circled-down"/></Button>
      </div>
    </div>
    {multislide &&
      <div className="MediaSlideshow-dots">
        {slides.map((_, index) =>
          <Button
            type="none"
            onClick={() => { setSlide(index) }}
            data-selected={slide === index}
          ><div/></Button>
        )}
      </div>
    }
  </div>
}

MediaSlideshow.propTypes = {
  className: PropTypes.string,
  slides: PropTypes.arrayOf(PropTypes.node),
  onRemove: PropTypes.func,
}

function Slide({
  className, type, url: src, height, width,
  uploading, progress, failed
}){
  return <Letterbox {...{
    className: classNames(
      'MediaSlideshow-Slide',
      { className, uploading, failed }
    ),
    height, width,
  }}>
    {
      type === 'video' ? <Video {...{src}}/> :
      type === 'audio' ? <Audio {...{src}}/> :
      /* else         */ <img {...{src}}/>
    }
    {uploading && <div className="MediaSlideshow-uploading">
      {failed
        ? <ErrorMessage error={failed}/>
        : <ProgressPie value={progress || 0} size="xl"/>
      }
    </div>}
  </Letterbox>
}


function pauseMedia(media){
  if (media && typeof media.pause === 'function') media.pause()
}

function Video(props){
  return <video {...props} controls loading="lazy" />
}

function Audio(props){
  return <div className="MediaSlideshow-Audio">
    <div>🔉</div>
    <audio {...props} controls loading="lazy" />
  </div>
}
