import { h, Fragment } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import {
  publicProfileToDisplayName,
  publicProfileToPathname,
} from 'lib/publicProfiles'

import EndUserAvatar from 'components/EndUserAvatar'
import IconRow from 'components/IconRow'

export default function PeopleListMember({
  className,
  disabled,
  publicProfile = {},
  href,
  suffix,
  linkToProfile = false,
  ...props
}){
  if (linkToProfile) href = publicProfileToPathname(publicProfile)
  return <IconRow {...{
    ...props,
    disabled,
    href,
    className: classNames('PeopleListMember', { className }),
    icon: <EndUserAvatar size="sm" publicProfile={publicProfile} />,
    row: <Fragment>
      <span className="PeopleList-Member-name">
        {publicProfileToDisplayName(publicProfile)}
      </span>
      &nbsp;
      {suffix}
    </Fragment>,
    organizationMembership: undefined,
  }}/>
}

PeopleListMember.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  publicProfile: PropTypes.shape({
    username: PropTypes.string.isRequired,
    displayName: PropTypes.string,
    avatar: PropTypes.string,
  }),
  href: IconRow.propTypes.href,
  suffix: PropTypes.node,
  linkToProfile: PropTypes.bool,
  organizationMemberhip: PropTypes.object,
}
