import { h } from 'preact'

import Form from 'components/Form'
import HugeChoiceInput from 'components/HugeChoiceInput'

export default function OrganizationPublicPrivateFormSection({ form, disabled }) {
  const isClosed = form.getValue('is_closed')
  return <Form.Row>
    <Form.Item>
      <Form.Label>PUBLIC OR PRIVATE</Form.Label>
      <HugeChoiceInput
        className="MembershipSettingsStep-PublicPrivateButtons"
        fat
        block
        defaultValue={false}
        disabled={disabled}
        value={
          typeof isClosed !== 'boolean' ? undefined :
          isClosed === true ? 1 :
          0
        }
        onChange={value => {
          if (value === 0){
            form.change({
              is_private: false,
              is_closed: false,
              closed_memberships: false,
              users_can_request_membership: false,
              publicly_listed: true,
            })
          }else if (value === 1){
            form.change({
              is_private: true,
              is_closed: true,
              closed_memberships: true,
              users_can_request_membership: false,
              publicly_listed: false,
            })
          }
        }}
        options={[
          {
            value: 0,
            buttonValue: 'Public',
          },
          {
            value: 1,
            buttonValue: 'Private',
          },
        ]}
      />
    </Form.Item>
  </Form.Row>
}

