import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import IconRow from 'components/IconRow'
import OrganizationNameAndTagLine from 'components/OrganizationNameAndTagLine'
import OrganizationIcon from 'components/OrganizationIcon'

export default function OrganizationListMember({
  className,
  disabled,
  organization,
  buttons,
  ...props
}){
  return <IconRow {...{
    ...props,
    disabled,
    className: classNames('OrganizationListMember', { className }),
    icon: <OrganizationIcon {...{disabled, organization}}/>,
    row: <OrganizationNameAndTagLine {...{
      disabled,
      organizationName: organization.name,
      organizationTagLine: organization.tag_line,
    }}/>,
    buttons,
  }}/>
}

OrganizationListMember.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  organization: OrganizationIcon.propTypes.organization,
  buttons: IconRow.propTypes.button,
}
