import { h } from 'preact'
import PropTypes from 'prop-types'
import { useMemo } from 'preact/hooks'

import classNames from 'lib/classNames'
import {
  useOrganizationFeedSubscription,
} from 'lib/feedSubscriptionHooks'

import Button from 'components/Button'
import Switch from 'components/Switch'

export default function OrganizationSubscribeToFeedButton({
  className,
  organizationApikey,
  subscriberOrganizationApikey,
  disabled,
  asSwitch,
  ...props
}) {
  const {
    subscribe,
    subscribing,
    unsubscribe,
    unsubscribing,
    organizationFeedSubscription,
    loadingOrganizationFeedSubscriptions,
  } = useOrganizationFeedSubscription(
    {
      organizationApikey,
      subscriberOrganizationApikey,
    },
    'OrganizationSubscribeToFeedButton',
  )

  const onClick = useMemo(
    () =>
      organizationFeedSubscription
        ? () => {
          const confirmation = `Are you sure you want to unsubscribe from ${organizationApikey}?`
          const confirmed = confirm(confirmation) // eslint-disable-line
          if (!confirmed) return
          unsubscribe()
        }
        : () => {
          subscribe()
        }
    ,
    [organizationFeedSubscription],
  )

  disabled = (
    disabled ||
    loadingOrganizationFeedSubscriptions ||
    subscribing ||
    unsubscribing
  )

  if (asSwitch) return <Switch {...{
    ...props,
    className: classNames('OrganizationSubscribeToFeedButton', { className }),
    onChange: onClick,
    checked: !!(subscribing || organizationFeedSubscription),
    saving: !!(subscribing || unsubscribing),
    disabled,
  }}/>

  return <Button {...{
    type: organizationFeedSubscription ? 'normal' : 'primary',
    ...props,
    className: classNames('OrganizationSubscribeToFeedButton', { className }),
    disabled,
    onClick,
    value: (
      subscribing ? 'Subscribing…' :
      unsubscribing ? 'Unsubscribing…' :
      organizationFeedSubscription ? 'Unsubscribe' :
      'Subscribe'
    ),
  }}/>
}

OrganizationSubscribeToFeedButton.propTypes = {
  className: PropTypes.string,
  organizationApikey: PropTypes.string.isRequired,
  subscriberOrganizationApikey: PropTypes.string.isRequired,
  asSwitch: PropTypes.bool,
  disabled: PropTypes.bool,
}
