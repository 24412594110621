import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import './index.sass'

export default function Pills({className, children, ...props}){
  return <div {...props} className={classNames('Pills', {className})}>
    {children}
  </div>
}

Pills.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}
