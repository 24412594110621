import { h, Fragment } from 'preact'
import { useState } from 'preact/hooks'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'

import { useMyPublicProfileDid } from 'resources/auth'
import {
  useAcceptedMembershipsForOrganization,
  usePublicProfiles,
} from 'lib/membershipAppStateHooks'
import { useSet } from 'lib/useSetHook'
import { useCreateChatChannel } from 'lib/chatHooks'

import classNames from 'lib/classNames'
import Header from 'components/Header'
import ErrorMessage from 'components/ErrorMessage'
import Button from 'components/Button'
import ButtonRow from 'components/ButtonRow'
import IconButton from 'components/IconButton'
import StyleishModal from 'components/StyleishModal'
import PeopleList from 'components/PeopleList'
import EndUserAvatarStack from 'components/EndUserAvatarStack'
import EndUserDisplayNameList from 'components/EndUserDisplayNameList'
import './index.sass'

export default function NewChatModal({
  className,
  organizationApikey,
  open,
  onClose,
  ...props
}){
  return <StyleishModal scrollInternally {...{
    className: classNames('NewChatModal', { className }),
    title: 'New Message',
    open,
    onClose,
    padded: false,
    scrollInternally: true,
  }}>
    <NewChatForm {...{...props, organizationApikey, onClose}}/>
  </StyleishModal>
}

NewChatModal.propTypes = {
  className: PropTypes.string,
  organizationApikey: PropTypes.string.isRequired,
  open: StyleishModal.propTypes.open,
  onClose: StyleishModal.propTypes.onClose,
}

function NewChatForm({organizationApikey, onClose}){
  const myPublicProfileDid = useMyPublicProfileDid()

  const {
    acceptedOrganizationMemberships,
  } = useAcceptedMembershipsForOrganization(organizationApikey, 'NewChatModal')
  const publicProfileDids = new Set()
  acceptedOrganizationMemberships.forEach(m => {
    if (m.memberUserDid && m.memberUserDid !== myPublicProfileDid)
      publicProfileDids.add(m.memberUserDid)
  })
  const { publicProfiles } = usePublicProfiles(
    [...publicProfileDids],
    'NewChatModal'
  )

  const createChatChannel = useCreateChatChannel('NewChatForm')

  const [recipients, addRecipient, removeRecipient] = useSet()

  const people = publicProfiles.map(publicProfile => {
    const selected = recipients.has(publicProfile.did)
    const onClick = () => {
      (selected ? removeRecipient : addRecipient)(publicProfile.did)
    }
    return {
      ...publicProfile,
      className: classNames('NewChatModal-recipient', { selected }),
      href: null,
      onClick,
      buttons: <IconButton {...{
        type: selected ? 'checkmark' : 'create',
        blue: selected,
        onClick,
      }} />
    }
  })

  const selectedPublicProfiles = publicProfiles
    .filter(pp => recipients.has(pp.did))

  const [createError, setCreateError] = useState()
  const create = () => {
    createChatChannel({
      type: 'dm',
      organizationApikey,
      memberUserDids: [...recipients],
      // message,
      // file,
    }).then(onClose, setCreateError)
  }

  const empty = recipients.size === 0

  return <div className="NewChatModal-form">
    <ErrorMessage error={createError} onDismiss={() => { setCreateError() }}/>
    <Header size="md">
      {empty
        ? `to…`
        : <Fragment>
          <EndUserAvatarStack {...{
            size: 'sm',
            publicProfiles: selectedPublicProfiles,
          }}/>
          <EndUserDisplayNameList
            noLinks publicProfiles={selectedPublicProfiles}
          />
        </Fragment>
      }
    </Header>
    {people.length === 0
      ? <Header size="sm" italic>
        You are the only member of this {PNFO.singular}
      </Header>
      : <PeopleList {...{people}}/>
    }
    <ButtonRow>
      <Button
        type="normal"
        value="cancel"
        onClick={onClose}
      />
      <Button
        type="primary"
        value="create"
        disabled={empty}
        onClick={create}
      />
    </ButtonRow>
  </div>
}
