import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import OrganizationPageSubnav from 'components/OrganizationPageSubnav'
import TwoColumns from 'components/TwoColumns'

import './index.sass'

export default function OrganizationFeedPageLayout(props) {
  const {
    className,
    currentUser,
    isMember,
    icon,
    name,
    belowNav,
    navChildren,
    children,
  } = props

  return <div className={classNames('OrganizationFeedPageLayout', { className })}>
    <OrganizationPageSubnav {...{
      currentUser,
      isMember,
      icon,
      name,
      belowNav,
      children: navChildren,
    }} />
    <TwoColumns>
      {children}
    </TwoColumns>
  </div>
}

OrganizationFeedPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  currentUser: PropTypes.object,
  isMember: PropTypes.bool,
  icon: PropTypes.node,
  name: PropTypes.string,
  belowNav: PropTypes.node,
  navChildren: PropTypes.node,
}
