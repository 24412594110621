import { h, Fragment } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { memo } from 'preact/compat'

import { useAppState } from 'lib/appState'
import useToggle from 'lib/useToggleHook'
import NetworkError from 'lib/NetworkError'
import {
  checkForNewServiceWorker,
  reloadForNewServiceWorker,
} from 'lib/serviceworker'
import { selectNodeTextContents } from 'lib/DOMHelpers'
import * as errorReporting from 'lib/errorReporting'

import Link from 'components/Link'
import Card from 'components/Card'
import ErrorMessage from 'components/ErrorMessage'
import Button from 'components/Button'
import ButtonRow from 'components/ButtonRow'
import Spinner from 'components/Spinner'
import './index.sass'

const AppError = memo(({ error }) => {
  logError(error)
  const networkError = (
    error instanceof NetworkError ||
    `${error}`.includes('Failed to fetch')
  )
  return <Card className="AppError">
    {networkError
      ? <NetworkErrorContent/>
      : <AppErrorContent {...{error}}/>
    }
  </Card>
})

AppError.propTypes = {
  error: ErrorMessage.propTypes.error.isRequired,
}

export default AppError

function AppErrorContent({ error }){
  const [showingDetails, showDetails, hideDetails] = useToggle(
    process.env.NODE_ENV !== 'production'
  )
  const [report, setReport] = useState(errorReporting.getReport(error))
  const { currentUser } = useAppState(['currentUser'], 'AppError')
  useEffect(
    () => {
      if (report) return
      const context = { currentUser }
      errorReporting.reportError(error, context).then(setReport)
    },
    [error]
  )
  return <Fragment>
    <Card.Header>App Error</Card.Header>
    <Card.Body>
      <p>Sorry. We've encountered a bit of a problem.</p>
      <p>Please try reloading the page.</p>
      {showingDetails
        ? <Fragment>
          <p className="AppError-reportStatus">
            {
              (report && report.ignored) ? '' : // empty intentionally
              (report && report.failed) ? 'Report failed' :
              (report && report.reportedAt) ? `Reported: UUID ${report.uuid}` :
              <Fragment>reporting… <Spinner /></Fragment>
            }
          </p>
          <Details {...{error, report}}/>
          <Link type="link" onClick={hideDetails}>hide details</Link>
        </Fragment>
        : <Link type="link" onClick={showDetails}>show details</Link>
      }
      <ButtonRow>
        <Button
          type="link"
          onClick={logoutAndReload}
          tabIndex={2}
        >logout and reload</Button>
        <Button
          tabIndex={1}
          autoFocus
          type="primary"
          onClick={reloadThePage}
        >reload</Button>
      </ButtonRow>
    </Card.Body>
  </Fragment>
}

function Details({ error, report }){
  let details = errorToString(error)
  if (report && report.failed) details += '\n\n' +
    JSON.stringify({...report, failed: undefined}, null, 2)
  return <div className="AppError-Details" onClick={selectAll}>
    {details}
  </div>
}

const selectAll = event => {
  selectNodeTextContents(event.target)
}

async function reloadThePage(){
  await checkForNewServiceWorker()
  await reloadForNewServiceWorker()
  global.location.reload()
}

async function logoutAndReload(){
  global.localStorage.clear()
  global.sessionStorage.clear()
  await reloadThePage()
}

function NetworkErrorContent(){
  useEffect(() => {
    global.addEventListener('online', reloadThePage)
    return () => global.removeEventListener('online', reloadThePage)
  })
  return <Fragment>
    <Card.Header>Network Error</Card.Header>
    <Card.Body>
      <p>You appear to be offline.</p>
      <p>Please check your internet connection and try reloading the page.</p>
      <Button type="primary" onClick={reloadThePage}>reload</Button>
    </Card.Body>
  </Fragment>
}

const logError = error => {
  window.APP_ERROR = error
  console.log(
    `%cwindow.APP_ERROR = ${errorToString(error)}`,
    `
    color: red;
    font-weight: bolder;
    font-size: 120%;
    `
  )
  console.error(error)
}

const errorToString = error =>
  error instanceof Error ? `${error.message}\n${error.stack}` : `${error}`
