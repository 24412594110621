import { h, Fragment } from 'preact'
import { useMemo, useRef, useEffect } from 'preact/hooks'

import {
  usePublicProfiles,
  useOrganizationMembershipRequests,
  useUpdateOrganizationMembership,
  useRemoveOrganizationMembership,
  useUpdateOrganizationMembershipError,
} from 'lib/membershipAppStateHooks'
import { publicProfileToPathname } from 'lib/publicProfiles'

import PeopleList from 'components/PeopleList'
import HeaderedContentBox from 'components/HeaderedContentBox'
import CopyButton from 'components/CopyButton'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import OrganizationMembershipInvites from 'components/OrganizationMembershipInvites'
import OrganizationPageSubnav from 'components/OrganizationPageSubnav'
import ParallelContentBoxes from 'components/ParallelContentBoxes'
import OrganizationMembersList from 'components/OrganizationMembersList'
import OrganizationAdminsList from 'components/OrganizationAdminsList'
import './index.sass'

export default function OrganizationMembersPage(props){
  return <PageContent {...props}/>
}

function PageContent({ params, organization, isMember, isAdmin, isCurator, currentUser }){
  const canAdmin = isAdmin || isCurator
  const selectedUsername = params.username
  const { errorRemovingMembership } = useRemoveOrganizationMembership('OrganizationMembersPage')

  const {
    organizationMembershipRequests,
  } = useOrganizationMembershipRequests(organization.apikey, 'OrganizationMembersPage')

  const ref = useRef()
  useEffect(
    () => {
      const node = ref.current
      if (!selectedUsername || !node) return
      const expanded = node.querySelector('.IconRowAccordion > [data-expanded]')
      if (!expanded) return
      setTimeout(
        () => {
          expanded.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        },
        200
      )
    },
    [selectedUsername]
  )
  return <div ref={ref} className="OrganizationMembersPage">
    <ErrorMessage error={errorRemovingMembership} />
    <OrganizationPageSubnav {...{
      currentUser,
      isMember,
      icon: 'people',
      name: 'Members',
    }}>
      <InviteButton {...{organization, isCurator}}/>
    </OrganizationPageSubnav>

    <ParallelContentBoxes className="OrganizationMembersPage-lists">
      <OrganizationMembersList {...{
        header: 'Members',
        collapsable: true,
        organization,
        admins: false,
        selectedUsername,
      }}/>
      <OrganizationAdminsList {...{
        organization,
        selectedUsername,
        isMember,
      }}/>
    </ParallelContentBoxes>
    {
      canAdmin && (
        (organization.closed_memberships && organization.users_can_request_membership) ||
        organizationMembershipRequests.length > 0
      ) &&
      <OrganizationMembershipRequests {...{ organizationMembershipRequests }}/>
    }
    {
      canAdmin &&
      organization.closed_memberships &&
      <OrganizationMembershipInvites {...{organization}}/>
    }
  </div>
}

OrganizationMembersPage.pageTitle = 'People'

function InviteButton({ organization, isCurator }){
  if (
    isCurator &&
    (!organization.closed_memberships || organization.users_can_request_membership)
  ) return <CopyButton {...{
    type: 'normal',
    value: 'Invite Members',
    copiedValue: 'Invite link copied',
    copyValue: `${window.location.origin}/${organization.apikey}/join`,
  }}/>
}

function OrganizationMembershipRequests({ organizationMembershipRequests }){
  const publicProfileDids = organizationMembershipRequests.map(m => m.memberUserDid)
  const { publicProfiles } = usePublicProfiles(publicProfileDids, 'OrganizationMembersPage')
  const errorUpdating = useUpdateOrganizationMembershipError(organizationMembershipRequests, 'OrganizationMembersPage')

  const people = publicProfiles.map(publicProfile => ({
    ...publicProfile,
    href: publicProfileToPathname(publicProfile),
    buttons: <AcceptAndRejectButtons {...{
      organizationMembershipRequests, publicProfile,
    }}/>,
  }))
  return <HeaderedContentBox
    header={`Membership Requests ${people.length > 0 ? '❗' : ''}`}
    padded
    collapsable
  >
    <ErrorMessage error={errorUpdating} />
    <PeopleList {...{
      people,
      onEmpty: `You have no membership requests`,
    }}/>
  </HeaderedContentBox>
}

function AcceptAndRejectButtons({ organizationMembershipRequests, publicProfile }) {
  const organizationMembership = organizationMembershipRequests
    .find(m => m.memberUserDid === publicProfile.did)

  const {
    updateOrganizationMembership,
    updatingOrganizationMembership,
  } = useUpdateOrganizationMembership(organizationMembership, 'OrganizationMembersPage')

  const disabled = !!(updatingOrganizationMembership)

  const [accept, reject] = useMemo(
    () => [true, false].map(accept =>
      () => updateOrganizationMembership({ organizationMembership, accept })
    ),
    [organizationMembership, updateOrganizationMembership]
  )

  return <Fragment>
    <Button {...{
      disabled,
      type: 'normal',
      value: 'reject',
      onClick: reject,
    }}/>
    <Button {...{
      disabled,
      type: 'primary',
      value: 'Accept',
      onClick: accept,
    }}/>
  </Fragment>
}
