import JLINCAPI from 'lib/JLINCAPI'

class AliceAPI extends JLINCAPI {

  sendHelpRequest(helpRequest){
    return this.postJSON('/help_request', helpRequest)
  }

  signup({
    email,
    mobile,
    password,
    passwordConfirmation,
    destinationPath,
    keepMeLoggedIn,
    organizationApikey,
    sisaInvite,
    membershipInviteToken,
    joinOrganization,
    source,
  }){
    return this.postJSON(
      '/signup',
      {
        email,
        mobile,
        password,
        passwordConfirmation,
        destinationPath,
        keepMeLoggedIn,
        source,
        organizationApikey,
        sisaInvite,
        membershipInviteToken,
        joinOrganization,
      }
    )
      .then(session => {
        session.login = session.login || mobile || email
        return session
      })
  }

  verifyExternalIdentity({ verificationCode }){
    return this.postJSON('/external_identities/verify', { verificationCode })
  }

  login({ login, password, keepMeLoggedIn }){
    return this.postJSON('/login', { login, password, keepMeLoggedIn })
  }

  verifySession(){
    return this.getJSON('/verify_session')
  }

  logout(){
    this.postJSON('/logout', {})
    this.sessionId = null
  }

  getOverview(){
    return this.getJSON('/overview')
  }

  getPersonalDataForInviteToken({ organizationApikey, inviteToken }){
    return this.getJSON(`/invite_token/${organizationApikey}/${inviteToken}`)
  }

  signSisa({ organizationApikey, inviteToken }){
    return this.postJSON(`/sign_sisa`, { organizationApikey, inviteToken })
  }

  getPublicOrganizations(){
    return this.getJSON('/public_organizations')
  }

  getOrganizationApikeyAvailability(organizationApikey){
    return this.getJSON(`/organizations/${organizationApikey}/availability`)
  }

  createOrganization({ organization, organizationMembershipInviteToken }){
    return this.postJSON(`/organizations`, { organization, organizationMembershipInviteToken })
  }

  updateOrganization({ organizationApikey, changes }){
    return this.postJSON(`/organizations/${organizationApikey}`, changes)
  }

  getOrganization({ organizationApikey }){
    return this.getJSON(`/organizations/${organizationApikey}`)
  }

  // we have to batch these requests incase we go over the 2083 url character limit
  getOrganizations({ organizationApikeys }){
    const apikeys = Array.from(new Set(organizationApikeys))
    const batches = ['']
    apikeys.forEach(apikey => {
      if (batches[0] === '') {
        batches[0] = apikey
        return
      }
      const addition = batches[0] + '~' + apikey
      if (addition.length <= 2000) batches[0] = addition
      else batches.unshift(apikey)
    })
    return Promise.all(batches.map(apikeys =>
      this.getJSON(`/organizations`, { apikeys })
    )).then(responses => {
      return responses.reduce((a, b) => {
        const merged = {}
        'organizations memberships publicProfiles'.split(' ').forEach(key => {
          merged[key] = [...a[key], ...b[key]]
        })
        return merged
      })
    })
  }

  getOrganizationAccountdata({ organizationApikey }){
    return this.getJSON(`/organizations/accountData/${organizationApikey}`)
      .then(({ organizationAccountData }) => organizationAccountData)
  }

  updateOrganizationsAccountData({ organizationApikeys, changes }){
    return this.postJSON(`/organizations/accountData`, { organizationApikeys, changes })
  }

  getDefaultAccountdata(){
    return this.getJSON(`/default-account-data`)
      .then(({ defaultAccountData }) => defaultAccountData)
  }

  updateDefaultAccountData({ defaultAccountData }){
    return this.postJSON(`/default-account-data`, { defaultAccountData })
      .then(({ defaultAccountData }) => defaultAccountData)
  }

  updatePreferences({ preferences }){
    return this.postJSON(`/preferences`, { preferences })
  }

  getExternalIdentities(){
    return this.getJSON(`/external_identities`)
  }

  addExternalIdentity({ type, value, destinationPath }){
    return this.postJSON(`/external_identities`, { type, value, destinationPath })
  }

  resendExternalIdentityVerificationCode({ value }){
    return this.postJSON(`/external_identities/resend_verification_code`, { value })
  }

  deleteExternalIdentity({ value }){
    return this.postJSON(`/external_identities/delete`, { value })
  }

  createBuyingInterest({ buyingInterest }){
    return this.postJSON(`/buying_interest/create`, { buyingInterest })
  }

  getBuyingInterests(){
    return this.getJSON(`/buying_interest`)
  }

  deleteBuyingInterest({ buyingInterestUid }){
    return this.postJSON(`/buying_interest/delete`, { buyingInterestUid })
  }

  getSisaEventsForOrganization({ organizationApikey }){
    return this.getJSON(`/organizations/sisa_events/${organizationApikey}`)
      .then(({ organizationSisaEvents }) => organizationSisaEvents)
  }

  getOrganizationByApiKey({ organizationApikey }){
    return this.getJSON(`/organization/${organizationApikey}`)
      .then(({ organization }) => organization)
  }

  requestPasswordReset({ login }){
    return this.postJSON(`/reset_password/request`, { login })
  }

  verifyResetPasswordToken({ resetPasswordToken }){
    return this.getJSON(`/reset_password/verify/${resetPasswordToken}`)
      .then(({ email }) => email)
  }

  resetPassword({ resetPasswordToken, password, passwordConfirmation }){
    return this.postJSON(`/reset_password`, { resetPasswordToken, password, passwordConfirmation })
  }

  getFeedPosts({ username, organizationApikey, published, order, before }){
    published = published ? '1' : undefined
    if (before instanceof Date) before = before.toISOString()
    return this.getJSON(`/feed/posts`, { username, organizationApikey, published, order, before })
  }

  getFeedPost({ feedPostUid }){
    return this.getJSON(`/feed/posts/${feedPostUid}`)
  }

  getFeedPostProvenance({ feedPostUid }){
    return this.getJSON(`/feed/posts/${feedPostUid}/provenance`)
  }

  createFeedPost({ post }){
    return this.postJSON(`/feed/posts`, { post })
  }

  updateFeedPost({ feedPostUid, changes }){
    return this.postJSON(`/feed/posts/${feedPostUid}`, { changes })
  }

  repostFeedPost({
    feedPostUid,
    posterOrganizationApikey,
    feedOrganizationApikey,
    visibleTo,
    maxVisibleTo,
  }){
    return this.postJSON(`/feed/posts/${feedPostUid}/repost`, {
      posterOrganizationApikey,
      feedOrganizationApikey,
      visibleTo,
      maxVisibleTo,
    })
  }

  publishFeedPost({ feedPostUid, visibleTo, maxVisibleTo }){
    return this.postJSON(`/feed/posts/${feedPostUid}/publish`, {visibleTo, maxVisibleTo})
  }

  deleteFeedPost({ feedPostUid }){
    return this.postJSON(`/feed/posts/${feedPostUid}/delete`, { feedPostUid })
  }

  hideFeedPostFromMe({ feedPostUid }){
    return this.postJSON(`/feed/posts/${feedPostUid}/hide-from-me`)
  }

  hideFeedPost({ feedPostUid }){
    return this.postJSON(`/feed/posts/${feedPostUid}/hide`, { feedPostUid })
  }

  voteOnFeedPost({ feedPostUid, vote }){
    return this.postJSON(`/feed/posts/${feedPostUid}/vote`, { vote })
  }

  createFeedPostComment({ feedPostUid, comment }){
    return this.postJSON(`/feed/posts/${feedPostUid}/comments`, { comment })
  }

  deleteFeedPostComment({ commentUid }){
    return this.postJSON(`/feed/comments/${commentUid}/delete`)
  }

  getFeedPostComments({ feedPostUid, before }){
    return this.getJSON(`/feed/posts/${feedPostUid}/comments`, { before })
  }

  getAssetUploadToken(){
    return this.postJSON(`/create_asset_upload_token`)
      .then(({ assetUploadToken }) => assetUploadToken)
  }

  getProductsAndServices(){
    return this.getJSON(`/products_and_services`)
  }

  createProductOrService({ productOrService }){
    return this.postJSON(`/products_and_services`, { productOrService })
  }

  getProductOrService({ uid }){
    return this.getJSON(`/products_and_services/${uid}`)
  }

  deleteProductOrService({ uid }){
    return this.postJSON(`/products_and_services/${uid}/delete`)
  }

  getPublicProfile(){
    return this.getJSON('/public_profile')
  }

  updatePublicProfile({ publicProfile }){
    return this.postJSON(`/public_profile`, { publicProfile })
  }

  getPublicProfiles({ dids, usernames }){
    return this.getJSON('/public_profiles', { 'dids[]': dids, 'usernames[]': usernames })
  }

  async getOrganizationMemberships(){
    return (await this.getJSON('/organizations/memberships')).organizationMemberships
  }

  createOrganizationMembership({ organizationApikey, inviteToken, memberUserDid }){
    return this.postJSON(
      `/organizations/${organizationApikey}/memberships`,
      { inviteToken, memberUserDid },
    )
  }

  updateOrganizationMembership({ organizationApikey, organiztionMembershipUid, changes }){
    return this.postJSON(`/organizations/${organizationApikey}/memberships/${organiztionMembershipUid}`, changes)
  }

  deleteOrganizationMembership({ organizationApikey, membershipUid }){
    return this.postJSON(`/organizations/${organizationApikey}/memberships/${membershipUid}/delete`)
  }

  resolveOrganizationMembershipRequest({organizationApikey, membershipRequestUid, accept}){
    return this.postJSON(
      `/organizations/${organizationApikey}/membership_requests/${membershipRequestUid}/resolve`,
      { accept },
    )
  }

  createOrganizationMembershipInvite({ organizationApikey }){
    return this.postJSON(`/organizations/${organizationApikey}/membership_invites`)
  }

  getOrganizationMembershipInvite({ token }){
    return this.getJSON(`/organizations/membership_invites/${token}`)
  }

  getOrganizationMembershipInvites({ organizationApikey }){
    return this.getJSON(`/organizations/${organizationApikey}/membership_invites`)
  }

  getMembershipsForPublicProfile({ publicProfileDid }){
    return this.getJSON(`/public_profile/${publicProfileDid}/memberships`)
  }

  createCovidReport(covidReport) {
    return this.postJSON(`/covid_report`, { covidReport })
  }

  getCovidReports() {
    return this.getJSON(`/covid_reports`)
  }

  getNotifications({ before }) {
    return this.getJSON('/notifications', { before })
  }

  getNotificationSettings() {
    return this.getJSON('/notifications/settings')
  }

  updateNotificationSettings(notificationSettings) {
    return this.postJSON('/notifications/settings', { notificationSettings })
  }

  markNotificationsAsRead({ uids }){
    return this.postJSON(`/notifications/seen`, { uids })
  }

  subscribeToOrganizationFeed({ organizationApikey, subscriberOrganizationApikey }) {
    return this.postJSON(`/organizations/${organizationApikey}/feed/subscriptions`, { subscriberOrganizationApikey })
  }

  unsubscribeFromOrganizationFeed({ organizationFeedSubscriptionUid, subscriberOrganizationApikey }) {
    return this.postJSON(
      `/organizations/feed_subscriptions/${organizationFeedSubscriptionUid}/delete`,
      { subscriberOrganizationApikey },
    )
  }

  getMyOrganizationFeedSubscriptions() {
    return this.getJSON(`/organizations/feed_subscriptions`)
  }

  getOrganizationFeedSubscriptions(organizationApikey) {
    return this.getJSON(`/organizations/${organizationApikey}/feed/subscriptions`)
  }

  search(searchQuery, page){
    return this.getJSON('/search', { q: searchQuery, page })
  }

  searchForOrganizations(searchQuery, page){
    return this.getJSON('/organizations/search', { q: searchQuery, page })
  }

  inviteUserToOrganization({ organizationApikey, publicProfileDid }){
    return this.postJSON(`/organizations/${organizationApikey}/invite/${publicProfileDid}`, {})
  }

  getUrlPreview(url){
    return this.getJSON(`/url-preview`, { url })
  }

  async getChatSynopsis(){
    return (await this.getJSON('/chat')).chatSynopsis
  }

  async createChatChannel(chatChannel){
    return (await this.postJSON(`/chat`, chatChannel)).chatChannel
  }

  async getChatMessages(chatChannelUid){
    return (await this.getJSON(`/chat/${chatChannelUid}`)).chatMessages
  }

  async createChatMessage(chatChannelUid, message){
    return (await this.postJSON(`/chat/${chatChannelUid}`, { message })).chatMessage
  }

  async markChatMessagesAsRead(messageUid){
    return (await this.postJSON(`/chat/${messageUid}/read`)).chatMessages
  }

  async getOrganizationNetworkMemberships(organizationApikey){
    const { organizationNetworkMemberships } =
      await this.getJSON(`/organizations/${organizationApikey}/network/memberships`)
    return organizationNetworkMemberships
  }

  async createOrganizationNetworkMembership(organizationApikey, memberOrganizationApikey){
    const { organizationNetworkMembership } = await this.postJSON(
      `/organizations/${organizationApikey}/network/memberships`,
      { memberOrganizationApikey },
    )
    return organizationNetworkMembership
  }

  async updateOrganizationNetworkMembership(organizationApikey, memberOrganizationApikey, changes){
    const { organizationNetworkMembership } = await this.postJSON(
      `/organizations/${organizationApikey}/network/memberships/${memberOrganizationApikey}`,
      changes,
    )
    return organizationNetworkMembership
  }

  async removeOrganizationNetworkMembership(organizationApikey, memberOrganizationApikey){
    const { organizationNetworkMembership } = await this.postJSON(
      `/organizations/${organizationApikey}/network/memberships/${memberOrganizationApikey}/remove`,
    )
    return organizationNetworkMembership
  }

  async sortOrganizationNetworkMemberships(organizationApikey, sortOrder){
    const { organizationNetworkMemberships } = await this.postJSON(
      `/organizations/${organizationApikey}/network/memberships/sort`,
      { sortOrder },
    )
    return organizationNetworkMemberships
  }
}

export default new AliceAPI({ urlPrefix: JLINC_A_API })
