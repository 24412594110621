import { useState, useRef, useEffect, useCallback } from 'preact/hooks'
import { createLogger } from 'lib/logger'
import history from 'lib/history'
import {
  Location,
  getLocation,
  locationToHref,
  set,
  replace,
  setQuery,
} from 'lib/location'

const logger = createLogger('location', 'color: yellow')

export { Location, setQuery, getLocation }

export function setLocation(location, _replace){
  logger.debug(`${_replace ? 'replace' : 'set'}: ${locationToHref(location)}`)
  return (_replace ? replace : set)(location)
}

export function replaceLocation(location){
  return setLocation(location, true)
}

export function setLocationQuery(query, replace){
  return setLocation(getLocation().withQuery(query, replace))
}

export function onLocationChange(_onChange){
  const onChange = () => _onChange(getLocation())
  history.subscribe(onChange)
  return () => {
    history.unsubscribe(onChange)
  }
}

// HOOKS

export function useLocation(){
  let [location, setLocation] = useState(getLocation())
  const ref = useRef()
  ref.location = location
  useEffect(() =>
    onLocationChange(newLocation => {
      if (locationToHref(newLocation) !== locationToHref(ref.location)){
        setLocation(newLocation)
      }
    })
  )
  return location
}

export function useLocationQueryState(options, componentName) {
  const { query, defaultValue, replace } = options
  const location = useLocation(componentName)
  return [
    location.query[query] || defaultValue,
    useCallback(
      value => setQuery({ [query]: value }, undefined, replace),
      [],
    )
  ]
}

export function useRedirect(options){
  const {to: location, replace} = options
  const condition = ('if' in options) ? !!options['if'] : true
  useEffect(
    () => {
      if (condition === true) setLocation(location, replace)
    },
    [location, condition, replace]
  )
}


Object.assign(DEBUG, {
  getLocation,
  setLocation,
  replaceLocation,
})
