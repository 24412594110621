import { h } from 'preact'
import { useEffect } from 'preact/hooks'

import defer from 'lib/defer'
import classNames from 'lib/classNames'
import { setLocation, replaceLocation } from 'resources/location'

import './index.sass'

export default function RedirectPage({
  className,
  replace,
  to,
  children,
  ...props
}){
  useEffect(
    () => {
      // This fixes a bug when this page is the initial page
      // and it was changing the location before AppRoot could bind
      // to changes to location ::shrug:: - Jared
      defer(() => {
        if (replace) { replaceLocation(to) }else{ setLocation(to) }
      })
    },
    [replace, to]
  )
  className = 'fullpage ' + classNames('RedirectPage', { className })
  return <div {...{...props, className}}>
    {children}
  </div>
}

RedirectPage.redirectTo = (destinationPath, children) =>
  () =>
    <RedirectPage to={destinationPath}>
      {children}
    </RedirectPage>

RedirectPage.replaceWith = (destinationPath, children) =>
  () =>
    <RedirectPage to={destinationPath} replace>
      {children}
    </RedirectPage>
