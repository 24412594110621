import { h } from 'preact'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import ButtonRow from 'components/ButtonRow'
import './index.sass'

export default function HeaderWithButtons({
  className = '',
  children,
  buttons,
  underlined,
  ...props
}){
  className = `HeaderWithButtons ${className}`
  if (underlined) className += ' HeaderWithButtons-underlined'
  return <div className={className}>
    <Header {...props}>{children}</Header>
    <ButtonRow>{buttons}</ButtonRow>
  </div>
}

HeaderWithButtons.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  buttons: PropTypes.node,
  underlined: PropTypes.bool,
}
