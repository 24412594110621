import { h } from 'preact'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'

import { useOrganizationsIAdmin } from 'lib/membershipAppStateHooks'
import { useOrganizationFeedSubscriptions } from 'lib/feedSubscriptionHooks'

import StyleishModal from 'components/StyleishModal'
import OrganizationList from 'components/OrganizationList'
import OrganizationSubscribeToFeedButton from 'components/OrganizationSubscribeToFeedButton'
import UserSubscribeToFeedButton from 'components/UserSubscribeToFeedButton'
import HeaderedContent from 'components/HeaderedContent'
import './index.sass'

export default function ManageOrganizationFeedSubscriptionsModal({
  organization, open, onClose, className = '',
}){
  return <StyleishModal
    className={`ManageOrganizationFeedSubscriptionsModal ${className}`}
    scrollInternally
    open={open}
    onClose={onClose}
    title={`Subscribe to ${organization.name}`}
    padded={false}
  >
    <Content {...{organization}}/>
  </StyleishModal>
}

ManageOrganizationFeedSubscriptionsModal.propTypes = {
  className: PropTypes.string,
  organization: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

const Content = function({organization}){
  const { organizationsIAdmin } = useOrganizationsIAdmin('ManageOrganizationFeedSubscriptionsModal')
  const {
    errorSubscribingToOrganizationFeed,
    errorUnsubscribingFromOrganizationFeed,
  } = useOrganizationFeedSubscriptions(organization.apikey, 'ManageOrganizationFeedSubscriptionsModal')

  const organizations = organizationsIAdmin
    .filter(organizationIAdmin => organizationIAdmin.apikey !== organization.apikey)
    .map(organizationIAdmin => {
      return {
        ...organizationIAdmin,
        buttons: <OrganizationSubscribeToFeedButton
          organizationApikey={organization.apikey}
          subscriberOrganizationApikey={organizationIAdmin.apikey}
          asSwitch
        />,
      }
    })

  return <div>
    <HeaderedContent {...{
      header: 'Personal Subscription',
      padded: true,
    }}>
      <UserSubscribeToFeedButton {...{organizationApikey: organization.apikey}} />
    </HeaderedContent>
    <HeaderedContent {...{
      header: `Subscribe as a ${PNFO.singular}`
    }}>
      <OrganizationList
        error={errorSubscribingToOrganizationFeed || errorUnsubscribingFromOrganizationFeed}
        organizations={organizations}
      />
    </HeaderedContent>
  </div>
}
