import { h } from 'preact'

import useIsPWA from 'lib/useIsPWAHook'

import Link from 'components/Link'

const href = BRANDING_SITE_URL

const domain = (() => {
  const a = global.document.createElement('a')
  a.href = href
  return a.hostname
})()

export default function LinkToBrandingPage({ children, ...props }){
  props = {
    type: 'text',
    newWindow: false,
    ...props,
    href,
  }
  const isPWA = useIsPWA()
  if (isPWA) props.newWindow = true
  return <Link {...props}>{children || domain}</Link>
}
