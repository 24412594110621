import { h, Fragment } from 'preact'
import { useEffect } from 'preact/hooks'
import PNFO from 'jlinc-shared/PNFO'

import { useAppState } from 'lib/appState'
import { COMMUNICATION_CHANNELS_HELP, areThereStagedConsentChanges } from 'lib/accountDataSpec'

import ContentBox from 'components/ContentBox'
import HeaderedContentBox from 'components/HeaderedContentBox'
import Loading from 'components/Loading'
import Alert from 'components/Alert'
import ErrorMessage from 'components/ErrorMessage'
import Button from 'components/Button'
import ButtonRow from 'components/ButtonRow'
import ButtonWithDropdown from 'components/ButtonWithDropdown'
import AliceConsentsForm from 'components/AliceConsentsForm'
import AliceOrganizationPersonalDataForm from 'components/AliceOrganizationPersonalDataForm'
import AliceOrganizationCommunicationChannelsForm from 'components/AliceOrganizationCommunicationChannelsForm'
import HeaderedContentWithUnsaved from 'components/HeaderedContentWithUnsaved'
import HeaderedContentWithUnsavedAndHelpButton from 'components/HeaderedContentWithUnsavedAndHelpButton'

import './index.sass'

export default function OrganizationPersonalDataPage(props){
  const { organizationApikey, organization } = props
  const {
    takeAction,
    mySISAs,
    updateError,
    changes,
    updatingOrganizationAccountData,
    importDefaultAccountDataError,
    organizationAccountData,
    loadingDefaultAccountData,
    defaultAccountData,
  } = useAppState(
    {
      'mySISAs': 'mySISAs',
      [`updatingOrganizationAccountDataError:${organizationApikey}`]: 'updateError',
      [`organizationAccountDataStagedChanges:${organizationApikey}`]: 'changes',
      [`updatingOrganizationAccountData:${organizationApikey}`]: 'updatingOrganizationAccountData',
      [`importDefaultAccountDataError:${organizationApikey}`]: 'importDefaultAccountDataError',
      [`organizationAccountData:${organizationApikey}`]: 'organizationAccountData',
      loadingDefaultAccountData: 'loadingDefaultAccountData',
      defaultAccountData: 'defaultAccountData',
    },
    'OrganizationPersonalDataPage'
  )

  useEffect(() => {
    takeAction('loadDefaultAccountData')
    takeAction('loadOrganizationAccountData', organizationApikey)
  })

  const stageOrganizationAccountDataChange = prop =>
    change => {
      takeAction(
        'stageOrganizationAccountDataChanges',
        organizationApikey,
        { [prop]: change }
      )
    }

  return <div className="OrganizationPersonalDataPage">
    <OrganizationPersonalDataPageContent {...{
      mySISAs,
      organizationApikey,
      organization,
      organizationAccountData,
      changes: changes || {},
      updating: !!updatingOrganizationAccountData,
      loadingDefaultAccountData,
      defaultAccountData,
      error: importDefaultAccountDataError || updateError,
      importDefaultAccountData: () => takeAction('importDefaultAccountData', organizationApikey),
      onCommunicationChannelsChange: stageOrganizationAccountDataChange('communication_channels'),
      onSharedPersonalDataChange: stageOrganizationAccountDataChange('shared_personal_data'),
      onPersonalDataChange: stageOrganizationAccountDataChange('personal_data'),
      onConsentChange: stageOrganizationAccountDataChange('consents'),
      reset: () => takeAction('clearOrganizationAccountDataStagedChanges', organizationApikey),
      save: options => takeAction('commitOrganizationAccountDataStagedChanges', {organizationApikey, ...options}),
    }}/>
  </div>
}

OrganizationPersonalDataPage.pageTitle = 'My Data'


const OrganizationPersonalDataPageContent = props => {
  const {
    mySISAs,
    organizationApikey,
    organization,
    organizationAccountData,
    loadingDefaultAccountData,
    defaultAccountData,
    onCommunicationChannelsChange,
    onSharedPersonalDataChange,
    onConsentChange,
    onPersonalDataChange,
    importDefaultAccountData,
    updating,
    changes,
    error,
    reset,
    save,
  } = props

  if (!mySISAs.has(organizationApikey)) return <ContentBox padded>
    <Alert type="error">You do not have a SISA with {organization.name}</Alert>
  </ContentBox>

  if (
    !organizationAccountData ||
    !defaultAccountData
  ) return <Loading type="fullPage"/>

  const hasChanges = Object.keys(changes).length > 0
  const disabled = updating

  return <HeaderedContentBox
    padded
    header="SISA - Shared Data"
    size="lg"
    buttons={
      <Buttons
        loadingDefaultAccountData={loadingDefaultAccountData}
        importDefaultAccountData={importDefaultAccountData}
        organization={organization}
        hasChanges={hasChanges}
        updating={updating}
        reset={reset}
        save={save}
      />
    }
  >
    <ErrorMessage error={error} />
    <PersonalDataForms
      changes={changes}
      organization={organization}
      disabled={disabled}
      organizationAccountData={organizationAccountData}
      onPersonalDataChange={onPersonalDataChange}
      onSharedPersonalDataChange={onSharedPersonalDataChange}
      onConsentChange={onConsentChange}
      onCommunicationChannelsChange={onCommunicationChannelsChange}
    />
  </HeaderedContentBox>
}

const PersonalDataForms = ({
  changes,
  disabled,
  organization,
  organizationAccountData,
  onPersonalDataChange,
  onSharedPersonalDataChange,
  onConsentChange,
  onCommunicationChannelsChange,
}) => (
  <Fragment>
    <HeaderedContentWithUnsaved
      header="Personal Data"
      unsaved={!!changes.personal_data || !!changes.shared_personal_data}
    >
      <AliceOrganizationPersonalDataForm
        disabled={disabled}
        requestedPersonalData={organization.requested_data}
        personalData={organizationAccountData.personal_data}
        personalDataStagedChanges={changes.personal_data}
        onPersonalDataChange={onPersonalDataChange}
        sharedPersonalData={organizationAccountData.shared_personal_data}
        sharedPersonalDataStagedChanges={changes.shared_personal_data}
        onSharedPersonalDataChange={onSharedPersonalDataChange}
      />
    </HeaderedContentWithUnsaved>
    <HeaderedContentWithUnsaved
      header="GDPR Permissions"
      unsaved={areThereStagedConsentChanges({
        type: 'GDPR',
        changes: changes.consents,
      })}
    >
      <AliceConsentsForm
        organization={organization}
        disabled={disabled}
        consents={organizationAccountData.consents}
        stagedChanges={changes.consents}
        onChange={onConsentChange}
        useOrgNameInDescriptions
        type="GDPR"
      />
    </HeaderedContentWithUnsaved>
    <HeaderedContentWithUnsaved
      header="CCPA Permissions"
      unsaved={areThereStagedConsentChanges({
        type: 'CCPA',
        changes: changes.consents,
      })}
    >
      <AliceConsentsForm
        organization={organization}
        disabled={disabled}
        consents={organizationAccountData.consents}
        stagedChanges={changes.consents}
        onChange={onConsentChange}
        useOrgNameInDescriptions
        type="CCPA"
      />
    </HeaderedContentWithUnsaved>
    <HeaderedContentWithUnsavedAndHelpButton
      header="Communication Channel Preferences"
      unsaved={!!changes.communication_channels}
      helpText={COMMUNICATION_CHANNELS_HELP}
    >
      <AliceOrganizationCommunicationChannelsForm
        disabled={disabled}
        requestedCommunicationChannels={organization.communication_channels}
        communicationChannels={organizationAccountData.communication_channels}
        communicationChannelsStagedChanges={changes.communication_channels}
        onChange={onCommunicationChannelsChange}
      />
    </HeaderedContentWithUnsavedAndHelpButton>
  </Fragment>
)

const Buttons = props => {
  const {
    updating,
    hasChanges,
    reset,
    save,
    importDefaultAccountData,
    organization,
  } = props

  const buttons = []

  const saveButtonValue = updating
    ? "Saving…"
    : "Save"

  if (hasChanges || updating){
    if (!updating){
      buttons.push(
        <Button
          key="cancel"
          type="normal"
          value="reset"
          disabled={updating}
          onClick={reset}
        />
      )
    }
    buttons.push(
      <ButtonWithDropdown
        key="save"
        type="success"
        disabled={updating}
        buttonValue={saveButtonValue}
        onClick={save}
        dropdownOptions={[
          {
            title: 'Save & Apply to Defaults',
            onClick: () => { save({ applyToDefaults: true }) }
          },
          {
            title: `Save & Apply to all of my ${PNFO.plural}`,
            onClick: () => { save({ applyToAllOrgs: true }) }
          },
        ]}
      />
    )
  } else {
    buttons.push(
      <Button
        key="View SISA"
        type="normal"
        value="View SISA"
        href={`/${organization.apikey}/sisa`}
      />
    )
    if (!isDataYogi)
      buttons.push(
        <Button
          key="Import My Defaults"
          type="primary"
          disabled={hasChanges || updating}
          value={'Load Defaults'}
          onClick={importDefaultAccountData}
        />
      )
  }

  return <ButtonRow>{buttons}</ButtonRow>
}
