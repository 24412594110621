import { h } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

import classNames from 'lib/classNames'

import MyOrganizationsList from 'components/MyOrganizationsList'

export default function OrganizationsIFollowList({ className, ...props }){
  return <MyOrganizationsList {...{
    ...props,
    className: classNames('OrganizationsIFollowList', {className}),
    filter: o => o.subscription,
    onEmpty: `You are not following any ${PNFO.plural} yet.`,
  }}/>
}
