import { h, Fragment } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import {
  publicProfileToDisplayName,
  publicProfileToPathname,
} from 'lib/publicProfiles'

import Link from 'components/Link'
import SingleLine from 'components/SingleLine'
import './index.sass'

export default function EndUserDisplayNameList({
  className,
  publicProfiles,
  noLinks,
  asMembersOf,
  ...props
}){

  const displayNames = []
  publicProfiles.forEach((pp, index) => {
    if (!pp) return
    const key = pp.did || index
    const displayName = publicProfileToDisplayName(pp)
    displayNames.push(displayName)
    const href = publicProfileToPathname({ ...pp, asMemberOf: asMembersOf })
    return (noLinks || !href)
      ? <span {...{ key }}>{displayName}</span>
      : <Link subtle {...{ key, href }}>{displayName}</Link>
  })

  return <SingleLine {...{
    ...props,
    className: classNames('EndUserDisplayNameList', { className }),
    title: displayNames.join(', '),
  }}>
    <SingleLine.Shrink>
      {displayNames.map((displayName, index) =>
        <Fragment>
          {displayName}
          {index < displayNames.length - 1 && <span>,&nbsp;</span>}
        </Fragment>
      )}
    </SingleLine.Shrink>
  </SingleLine>
}

EndUserDisplayNameList.propTypes = {
  className: PropTypes.string,
  noLinks: PropTypes.bool,
  asMembersOf: PropTypes.string, // organizationApikey
  publicProfiles: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
    })
  ),
}
