import { h } from 'preact'

import { useAppState } from 'lib/appState'
import { useSisa } from 'lib/membershipAppStateHooks'
import useToggle from 'lib/useToggleHook'
import {
  ALICE_SISA_NOTICE,
  ALICE_SISA_SUMMARY,
  FULL_SISA_URL,
  DATAYOGI_ALICE_SISA_NOTICE,
} from 'lib/accountDataSpec'

import ContentBox from 'components/ContentBox'
import HeaderedContentBox from 'components/HeaderedContentBox'
import Link from 'components/Link'
import Header from 'components/Header'
import SisaText from 'components/SisaText'
import HelpButton from 'components/HelpButton'
import Timestamp from 'components/Timestamp'
import TimeAgo from 'components/TimeAgo'
import Button from 'components/Button'
import ButtonRow from 'components/ButtonRow'
import ErrorMessage from 'components/ErrorMessage'
import OrganizationPageSubnav from 'components/OrganizationPageSubnav'

export default function OrganizationSisaPage(props){
  const { location, currentUser, organization, isMember } = props
  const { sisa } = useSisa(organization.apikey, 'OrganizationSisaPage')
  return <div className="OrganizationSisaPage">
    <OrganizationPageSubnav {...{
      currentUser,
      isMember,
      icon: 'data-tr',
      name: 'SISA',
    }}/>
    {sisa
      ? <SISA {...{organization, sisa}}/>
      : <SignSisaPrompt {...{location, currentUser, organization}}/>
    }
  </div>
}
OrganizationSisaPage.pageTitle = 'SISA'

function SISA({ organization, sisa }){
  const signedByRightsHolderDate = sisa.acceptedSisa.createdAt
  const signedByOrganizationDate = sisa.acceptedSisa.offeredSisa.createdAt
  const editButton = <Button
    type="normal"
    value="Edit Shared Data"
    href={`/${organization.apikey}/my-data`}
  />
  return <HeaderedContentBox padded header="SISA" size="lg" buttons={editButton}>
    <p>You signed a</p>
    <Header size="lg" className="OrganizationSisaPage-header">
      Standard Information Sharing Agreement
    </Header>
    <p>with</p>
    <Header size="lg" className="OrganizationSisaPage-header">{organization.name}</Header>
    <p><TimeAgo time={signedByRightsHolderDate}/> (<Timestamp time={signedByRightsHolderDate} />)</p>
    <p>using the following DID:<DIDHelpIcon/></p>
    <p>{sisa.acceptedSisa.rightsHolderDid}</p>
    <p><SisaText text={ALICE_SISA_SUMMARY} organization={organization} /></p>
    <p>
      Here is the&nbsp;
      <Link type="link" newWindow href={FULL_SISA_URL}>
        Full Standard Information Sharing Agreement text
      </Link>
    </p>
    <p>
      This agreement was signed by {organization.name}&nbsp;
      <TimeAgo time={signedByOrganizationDate}/> (<Timestamp time={signedByOrganizationDate} />)
    </p>
  </HeaderedContentBox>
}

const DIDHelpIcon = () =>
  <HelpButton>
    <Header size="lg">What is a DID?</Header>
    <p>
      A DID is a <Link type="link" href="https://w3c-ccg.github.io/did-spec/" newWindow>Decentralized Identifier</Link> that
      JLINC uses to sign SISAS on your behalf.
    </p>
    <p>
      Decentralized Identifiers (DIDs) are a new type of identifier for verifiable, "self-sovereign" digital identity.
      DIDs are fully under the control of the DID subject, independent from any centralized registry, identity provider,
      or certificate authority. DIDs are URLs that relate a DID subject to means for trustable interactions with that
      subject. DIDs resolve to DID Documents — simple documents that describe how to use that specific DID. Each DID
      Document may contain at least three things: proof purposes, verification methods, and service endpoints. Proof
      purposes are combined with verification methods to provide mechanisms for proving things. For example, a DID
      Document can specify that a particular verification method, such as a cryptographic public key or pseudonymous
      biometric protocol, can be used to verify a proof that was created for the purpose of authentication. Service
      endpoints enable trusted interactions with the DID controller.
    </p>
  </HelpButton>

function SignSisaPrompt({ currentUser, organization }){
  const organizationApikey = organization.apikey
  const {
    appAction,
    signingSisaWith,
    errorSigningSisa,
  } = useAppState(
    [
      'signingSisaWith',
      'errorSigningSisa',
    ],
    'OrganizationSisaPage'
  )

  const [showingSisaSummary, showSisaSummary, hideSisaSummary] = useToggle(false)
  const signingSisa = signingSisaWith === organizationApikey
  const disabled = signingSisa
  const signSisa = appAction('signSisa', { organizationApikey })

  return <ContentBox padded>
    <Header size="md" underlined>
      {organization.name} is inviting you to sign a Standard Information Sharing Agreement (SISA)
    </Header>
    <ErrorMessage error={errorSigningSisa} />
    { showingSisaSummary
      ? <SISASummary {...{ organization, hideSisaSummary }}/>
      : <SisaText
        text={isDataYogi ? DATAYOGI_ALICE_SISA_NOTICE : ALICE_SISA_NOTICE}
        organization={organization}
        onSummaryClick={showSisaSummary}
      />
    }
    <ButtonRow>
      <Button
        disabled={disabled}
        type="primary"
        value={signingSisa ? 'Signing SISA…' : 'Sign SISA'}
        onClick={currentUser && signSisa}
        href={currentUser ? undefined : `/${organizationApikey}/join`}
      />
    </ButtonRow>
  </ContentBox>
}

function SISASummary({ organization, hideSisaSummary }){
  return <div>
    <SisaText text={ALICE_SISA_SUMMARY} organization={organization} />
    <br />
    <br />
    <Link type="link" onClick={hideSisaSummary}>
      Close SISA Summary
    </Link>
    <span> | </span>
    <Link type="link" href={FULL_SISA_URL} newWindow>
      View Full SISA
    </Link>
  </div>
}
