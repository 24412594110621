import { h, Fragment } from 'preact'
import { useEffect, useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'

import { useAppState } from 'lib/appState'
import useToggle from 'lib/useToggleHook'
import {
  usePublicProfiles,
  useOrganizations,
} from 'lib/membershipAppStateHooks'

import ErrorMessage from 'components/ErrorMessage'
import Link from 'components/Link'
import Button from 'components/Button'
import Header from 'components/Header'
import StyleishModal from 'components/StyleishModal'
import CopyBox from 'components/CopyBox'
import HeaderedContentBox from 'components/HeaderedContentBox'
import Loading from 'components/Loading'
import TimeAgo from 'components/TimeAgo'
import CopyButton from 'components/CopyButton'
import Table from 'components/Table'
import LinkToPublicProfile from 'components/LinkToPublicProfile'
import { hrefToOrganization } from 'components/LinkToOrganization'
import './index.sass'

export default function OrganizationMembershipInvites({ className = '', organization }){
  const organizationApikey = organization.apikey

  const {
    takeAction,
    loading,
    loadingError,
    allInvites = [],
    creatingInvite,
    createdInvite,
    creatingError,
  } = useAppState(
    {
      [`organizationMembershipInvites:${organizationApikey}`]: 'allInvites',
      [`organizationMembershipInvites:${organizationApikey}:loading`]: 'loading',
      [`organizationMembershipInvites:${organizationApikey}:loading:error`]: 'loadingError',
      [`organizationMembershipInvite:${organizationApikey}:created`]: 'createdInvite',
      [`organizationMembershipInvite:${organizationApikey}:creating`]: 'creatingInvite',
      [`organizationMembershipInvite:${organizationApikey}:creating:error`]: 'creatingError',
    },
    'OrganizationMembershipInvites'
  )

  useEffect(
    () => {
      takeAction('organizationMembershipInvites.loadInvites', organizationApikey)
    },
    [organizationApikey]
  )

  const createInvite = useCallback(
    () => {
      takeAction('organizationMembershipInvites.create', {organizationApikey})
    },
    [organizationApikey],
  )

  const inviteTokenToUrl = inviteToken => {
    return `${window.location.origin}/${organizationApikey}/join?i=${inviteToken}`
  }

  const invites = allInvites
    .map(invite => ({
      ...invite,
      url: inviteTokenToUrl(invite.token)
    }))
    .sort((a, b) => {
      a = a.createdAt
      b = b.createdAt
      return a < b ? 1 : a > b ? -1 : 0
    })

  const createInviteButton = <CreateInviteButton {...{
    createInvite,
    creatingInvite,
    inviteUrl: createdInvite && inviteTokenToUrl(createdInvite),
    creatingError,
  }}/>

  return <HeaderedContentBox
    className={`OrganizationMembershipInvites ${className}`}
    size="md"
    buttons={createInviteButton}
    header={`Invite to ${organization.name}`}
  >
    <InvitesList {...{
      organizationApikey,
      loading,
      loadingError,
      invites,
    }}/>
  </HeaderedContentBox>
}

OrganizationMembershipInvites.propTypes = {
  className: PropTypes.string,
  organization: PropTypes.shape({
    apikey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
}

function CreateInviteButton({ createInvite, ...props }){
  const [modalOpen, openModal, closeModal] = useToggle(false)
  const action = 'Invite a Member'
  return <Fragment>
    <StyleishModal
      className="CreateInviteModal"
      open={modalOpen}
      onClose={closeModal}
      title={action}
      body={
        <CreateInviteModal {...props}/>
      }
    />
    <Button
      type="primary"
      onClick={() => {
        createInvite()
        openModal()
      }}
      value={action}
    />
  </Fragment>
}

function CreateInviteModal({
  creatingInvite,
  inviteUrl,
  creatingError,
}){
  return <Fragment>
    <p>
      <span>The following URL is a one-time use invite url that allows the user to </span>
      <span>join your {PNFO.singular}.</span>
    </p>
    <ErrorMessage error={creatingError}/>
    {creatingInvite && <Loading type="block" size="md"/>}
    {inviteUrl &&
      <p>
        <CopyBox value={inviteUrl} />
      </p>
    }
  </Fragment>
}

function InvitesList({
  organizationApikey,
  loading,
  loadingError,
  invites = [],
}){
  const { publicProfiles } = usePublicProfiles(
    invites
      .filter(i => i.usedByUserDid)
      .map(i => i.usedByUserDid),
    'OrganizationMembershipInvites'
  )
  const { organizations } = useOrganizations(
    invites
      .filter(i => i.usedByOrganizationApikey)
      .map(i => i.usedByOrganizationApikey),
    'OrganizationMembershipInvites'
  )

  if (loadingError) return <ErrorMessage error={loadingError}/>
  if (loading) return <Loading type="block" size="md"/>
  if (invites.length === 0) return <p>
    <Header size="sm" centered italic>You have no outgoing invites</Header>
  </p>

  return <Table
    columns={['Token', 'Created', 'Used', 'Actions']}
    rows={invites}
    keyProperty="token"
    renderRow={invite => {
      const publicProfile = invite.usedByUserDid &&
        publicProfiles.find(p => p.did === invite.usedByUserDid)
      const organization = invite.usedByOrganizationApikey &&
        organizations.find(o => o.apikey === invite.usedByOrganizationApikey)
      return [
        <div>{invite.token}</div>,
        <TimeAgo time={invite.createdAt} />,
        invite.usedAt
          ? <span>
            <span>used <TimeAgo time={invite.usedAt} /></span>
            {publicProfile &&
              <span>
                <span> by </span>
                <LinkToPublicProfile {...{publicProfile}}/>
              </span>
            }
            {organization &&
              <span>
                <span> to create </span>
                <Link
                  type="link"
                  href={hrefToOrganization(organizationApikey)}
                >{organization.name}</Link>
              </span>
            }
          </span>
          : null,
        invite.usedAt
          ? null
          : <CopyButton
            type="normal"
            value="Copy Link"
            copyValue={invite.url}
          />
      ]
    }}
  />
}
