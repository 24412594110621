import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import { setLocation } from 'resources/location'

import {
  useLoadOrganizationMembershipInvite,
  useOrganizationMembershipInviteFor,
  useMyPendingOrganizationMemberships,
} from 'lib/membershipAppStateHooks'
import useInvitedToOrganization from 'lib/useInvitedToOrganizationHook'

import Header from 'components/Header'
import Loading from 'components/Loading'
import Button from 'components/Button'
import ButtonRow from 'components/ButtonRow'
import ErrorMessage from 'components/ErrorMessage'
import OrganizationMembershipButton from 'components/OrganizationMembershipButton'
import AliceSignupForm from 'components/AliceSignupForm'
import AliceLoginForm from 'components/AliceLoginForm'
import StyleishModal from 'components/StyleishModal'
import OrganizationIcon from 'components/OrganizationIcon'
import { hrefToOrganization } from 'components/LinkToOrganization'
import './index.sass'

export default function OrganizationJoinModal(props){
  const { organization, location, currentUser, isMember } = props
  const organizationApikey = organization.apikey
  const { i: inviteToken, s: showJoinModal, l: onLogin } = location.query
  const { invitedToOrganization, setInvitedToOrganization } = useInvitedToOrganization(organizationApikey)

  const { myPendingOrganizationMemberships } = useMyPendingOrganizationMemberships('OrganizationJoinModal')
  const pendingMembership = myPendingOrganizationMemberships.find(pendingMembership =>
    pendingMembership.organizationApikey === organizationApikey
  )
  const requestedToJoin = pendingMembership && pendingMembership.memberUserDid === pendingMembership.createdByUserDid
  const {
    loadingOrganizationMembershipInvite: loadingInvite,
    errorLoadingOrganizationMembershipInvite: errorLoadingInvite,
  } = useLoadOrganizationMembershipInvite(inviteToken, 'OrganizationJoinModal')
  const {
    organizationMembershipInvite,
  } = useOrganizationMembershipInviteFor(organizationApikey, 'OrganizationJoinModal')

  const onClose = useCallback(
    () => { setLocation(location.withQuery({ s: undefined, i: undefined, l: undefined }).toString()) },
    [location]
  )

  const isInvited = organizationMembershipInvite || invitedToOrganization
  const loggedIn = !!currentUser

  if (
    (organization.is_private && !organization.users_can_request_membership) &&
    (!organizationMembershipInvite && !isMember && !errorLoadingInvite)
  ) return

  let title
  if (isInvited && !loggedIn) title = `${organization.name} has invited you to join ${APP_NAME}`
  else if (loggedIn && requestedToJoin) title = `Requested to join ${organization.name}`
  else if ((!isInvited && !loggedIn) || (loggedIn && !isMember)) title = `Join ${organization.name}`
  else title = `You have joined ${organization.name}`

  return <StyleishModal {...{ className: 'OrganizationJoinModal', open: showJoinModal, title, onClose }}>
    <ErrorMessage error={errorLoadingInvite} />
    {inviteToken && loadingInvite && <Loading />}
    {!loadingInvite && !errorLoadingInvite && currentUser &&
      <MembershipConfirmation {...{
        organization, inviteToken, location, setInvitedToOrganization, setLocation,
      }}/>
    }
    {!loadingInvite && !errorLoadingInvite && !currentUser &&
      <SignupOrLoginForm {...{
        organization,
        location,
        inviteToken: inviteToken || (organizationMembershipInvite && organizationMembershipInvite.token),
        onLogin,
      }}/>
    }
  </StyleishModal>
}

function SignupOrLoginForm({ organization, location, inviteToken, onLogin }) {
  const signupOrLoginLinkHref = location
    .withQuery({ s: '1', i: inviteToken, l: onLogin ? undefined : '1' })
    .toString()

  return onLogin
    ? <AliceLoginForm {...{ signupLinkHref: signupOrLoginLinkHref }}/>
    : <AliceSignupForm {...{
      className: 'OrganizationJoinModal',
      joinOrganization: organization && organization.apikey,
      destinationPath: location.withQuery({ i: undefined }).toString(),
      loginLinkHref: signupOrLoginLinkHref,
    }}/>
}

function MembershipConfirmation({ organization, location, setInvitedToOrganization, setLocation }) {
  const organizationApikey = organization.apikey

  const onJoin = useCallback(
    () => {
      setInvitedToOrganization()
      setLocation(location.replaceQuery({ s: 1 }))
    },
    [location]
  )

  const buttons = [
    <Button {...{
      type: 'subtle',
      value: 'Close',
      href: hrefToOrganization(organizationApikey),
    }}/>,
    <Button {...{
      type: 'primary',
      value: 'Take Tour',
      href: `/${organizationApikey}?iw=0`
    }}/>,
    <OrganizationMembershipButton {...{
      organization,
      onJoin
    }}/>
  ]

  return <div className="OrganizationJoinModal-MembershipConfirmation">
    <OrganizationIcon size="lg" {...{organization}}/>
    <Header size="md" centered>{organization.short_description}</Header>
    <ButtonRow>
      {buttons}
    </ButtonRow>
  </div>
}
