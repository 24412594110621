import { h } from 'preact'

import { Location } from 'resources/location'
import RedirectPage from '../RedirectPage'

export default function OrganizationJoinPage(props){
  const { location, params: { organizationApikey } } = props
  const inviteToken = location.query.i
  const to = new Location({
    pathname: `/signup`,
    query: { o: organizationApikey, i: inviteToken },
  })
  return <RedirectPage {...{ to }}/>
}
