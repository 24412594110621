import { h } from 'preact'
import { useEffect } from 'preact/hooks'
import moment from 'moment'

import { useAppState } from 'lib/appState'
import { setPageTitle } from 'lib/Page'

import PERSONAL_DATA_KEYS from 'jlinc-shared/personal_data_keys'
import CONSENTS from 'jlinc-shared/consents'
import COMMUNICATION_CHANNELS from 'jlinc-shared/communication_channels'
import COMMUNICATION_CHANNEL_TITLES from 'jlinc-shared/communication_channel_titles'
import { getLabelForPersonalDataField } from 'lib/accountDataSpec'

// import OrganizationPageLayout from 'components/OrganizationPageLayout'
import NotFoundPage from '../NotFoundPage'
import ContentBox from 'components/ContentBox'
import Link from 'components/Link'
import HelpButton from 'components/HelpButton'
import Header from 'components/Header'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'

import './index.sass'

export default function OrganizationSisaEventPage(props){
  const { organizationApikey, organization, params: { sisaEventId } } = props
  setPageTitle(`SISA Event - ${sisaEventId}`)

  const {
    takeAction,
    loading,
    loadingError,
    notFound,
    sisaEvent,
  } = useAppState(
    {
      [`sisaEvents:${organizationApikey}:loading`]: 'loading',
      [`sisaEvents:${organizationApikey}:loading:error`]: 'loadingError',
      [`sisaEvents:${organizationApikey}:notFound`]: 'notFound',
      [`sisaEvent:${sisaEventId}`]: 'sisaEvent',
    },
    'OrganizationSisaEventPage'
  )

  const needsLoading = !loading && !loadingError && !notFound && !sisaEvent
  useEffect(
    () => {
      if (needsLoading)
        takeAction('organization.loadSisaEvents', organizationApikey)
    },
    [needsLoading]
  )

  const renderError = error =>
    <ContentBox padded className="OrganizationSisaEventPage">
      <ErrorMessage error={error} />
    </ContentBox>

  if (notFound) return <NotFoundPage />

  if (loadingError) return renderError(loadingError)

  if (loading) return <ContentBox padded className="OrganizationSisaEventPage">
    <Loading type="block" />
  </ContentBox>

  if (!sisaEvent) return <NotFoundPage />

  return <ContentBox padded className="OrganizationSisaEventPage">
    <div className="OrganizationSisaEventPage-row">
      <Header size="sm">Event ID</Header>
      <div className="OrganizationSisaEventPage-row-text">
        {sisaEventId}
      </div>
    </div>

    <div className="OrganizationSisaEventPage-row">
      <Header size="sm">Organization</Header>
      <div className="OrganizationSisaEventPage-row-text">
        {organization.name}
      </div>
    </div>

    <div className="OrganizationSisaEventPage-row">
      <Header size="sm">Date/Time Signed</Header>
      <div className="OrganizationSisaEventPage-row-text">
        {moment(new Date(sisaEvent.audit.createdAt)).format('LLLL')}
      </div>
    </div>


    <div className="OrganizationSisaEventPage-row">
      <Header size="sm">Event Type</Header>
      <div className="OrganizationSisaEventPage-row-text">
        {sisaEvent.audit.eventType.replace(/Event$/, '')}
      </div>
    </div>

    <div className="OrganizationSisaEventPage-row">
      <Header size="sm" className="OrganizationSisaEventPage-row-headerWithHelpButton">
        <span>Rights Holder DID</span>
        <HelpButton>
          The Rights Holder is you and this DID <DidDocsLink /> may be the only way that
          this Data Custodian can identify you if you have not given them your name or
          other identifying info.
        </HelpButton>
      </Header>
      <div className="OrganizationSisaEventPage-row-text">
        {sisaEvent.audit.rightsHolderDid}
      </div>
    </div>

    <div className="OrganizationSisaEventPage-row">
      <Header size="sm" className="OrganizationSisaEventPage-row-headerWithHelpButton">
        <span>Data Custodian DID</span>
        <HelpButton>
          The DID <DidDocsLink /> for the Data Custodian "{organization.name}"
          the party that has custody of your data.
        </HelpButton>
      </Header>
      <div className="OrganizationSisaEventPage-row-text">
        {sisaEvent.audit.dataCustodianDid}
      </div>
    </div>


    <div className="OrganizationSisaEventPage-row">
      <Header size="sm">Previous Sisa Event</Header>
      <div className="OrganizationSisaEventPage-row-text">
        { sisaEvent.audit.previousId
          ? <Link href={`/${sisaEvent.organizationApikey}/sisa-events/${sisaEvent.audit.previousId}`}>
            {sisaEvent.audit.previousId}
          </Link>
          : null
        }
      </div>
    </div>

    <div className="OrganizationSisaEventPage-row">
      <Header size="sm">Changes</Header>
      <EventDetails event={sisaEvent.event} />
    </div>
  </ContentBox>
}


const EventDetails = ({ event }) => {
  const changes = []

  if (event.shared_personal_data) {
    Object.keys(event.shared_personal_data)
      .sort(sortPersonalData)
      .forEach(key => {
        const value = event.shared_personal_data[key]
        const label = getLabelForPersonalDataField(key)
        changes.push(
          <div key={`shared_personal_data.${key}`} className="OrganizationSisaEventPage-change">
            <span>{value ? 'Started' : 'Stopped'} sharing</span>&nbsp;
            <Field>{label}</Field>
          </div>
        )
      })
  }

  if (event.personal_data){
    Object.keys(event.personal_data)
      .sort(sortPersonalData)
      .forEach(personalDataKey => {
        const value = event.personal_data[personalDataKey]
        const label = getLabelForPersonalDataField(personalDataKey)
        if (event.shared_personal_data && event.shared_personal_data[personalDataKey] === false) {
          return
        }else if (value === null) {
          changes.push(
            <div key={`personal_data.${personalDataKey}`} className="OrganizationSisaEventPage-change">
              <span>Stopped sharing</span>&nbsp;
              <Field>{label}</Field>
            </div>
          )
        }else{
          changes.push(
            <div key={`personal_data.${personalDataKey}`} className="OrganizationSisaEventPage-change">
              <Field>{label}:</Field>&nbsp;
              <div className="OrganizationSisaEventPage-change-changedTo">
                {value}
              </div>
            </div>
          )
        }
      })
  }

  if (event.consents){
    Object.keys(event.consents)
      .sort(sortConsents)
      .forEach(consent => {
        const enabled = event.consents[consent]
        changes.push(
          <div key={`consents.${consent}`} className="OrganizationSisaEventPage-change">
            <span>Permission:</span>&nbsp;
            <Field>{consent}</Field>&nbsp;
            <span>{enabled ? 'enabled' : 'disabled'}</span>
          </div>
        )
      })
  }

  if (event.communication_channels){
    Object.keys(event.communication_channels)
      .sort(sortCommunicationChannels)
      .forEach(communicationChannel => {
        const title = COMMUNICATION_CHANNEL_TITLES[communicationChannel] || communicationChannel
        const enabled = event.communication_channels[communicationChannel]
        changes.push(
          <div key={`communication_channels.${communicationChannel}`} className="OrganizationSisaEventPage-change">
            <span>Communication Channel:</span>&nbsp;
            <Field>{title}</Field>&nbsp;
            <span>{enabled ? 'enabled' : 'disabled'}</span>
          </div>
        )
      })
  }

  return <div className="OrganizationSisaEventPage-changesWrapper">{changes}</div>
}

const Field = ({children}) =>
  <span className="OrganizationSisaEventPage-Field">{children}</span>


const sortPersonalData = (a, b) => {
  a = PERSONAL_DATA_KEYS.indexOf(a)
  b = PERSONAL_DATA_KEYS.indexOf(b)
  return a < b ? -1 : a > b ? 1 : 0
}

const sortConsents = (a, b) => {
  a = CONSENTS.indexOf(a)
  b = CONSENTS.indexOf(b)
  return a < b ? -1 : a > b ? 1 : 0
}

const sortCommunicationChannels = (a, b) => {
  a = COMMUNICATION_CHANNELS.indexOf(a)
  b = COMMUNICATION_CHANNELS.indexOf(b)
  return a < b ? -1 : a > b ? 1 : 0
}


const DidDocsLink = () =>
  <Link type="link" href="https://w3c-ccg.github.io/did-spec/" newWindow><i>Decentralized Identifier</i></Link>
