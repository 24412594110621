import { useEffect } from 'preact/hooks'
import history from 'lib/history'

export default function useScrollUpOnNaturalPageChange(args = []){
  useEffect(
    () => {
      if (history.natural) window.document.documentElement.scrollTop = 0
    },
    args,
  )
}
