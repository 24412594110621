import { h, Fragment } from 'preact'
import { memo } from 'preact/compat'
import PropTypes from 'prop-types'

import { useAppState } from 'lib/appState'
import { useOrganizationFeedPostComments } from 'lib/feedPostHooks'
import {
  usePublicProfile,
  useOrganization,
  useMyOrganizationMemberships,
} from 'lib/membershipAppStateHooks'
import * as wysiwyg from 'lib/WYSIWYG'

import InfiniteScrollDown from 'components/InfiniteScrollDown'
import FeedContentBox from 'components/FeedContentBox'
import TimeAgo from 'components/TimeAgo'
import AuthorIconBox from 'components/AuthorIconBox'
import OrganizationFeedPostAuthorIcon from 'components/OrganizationFeedPostAuthorIcon'
import LinkToPublicProfile from 'components/LinkToPublicProfile'
import LinkToOrganization from 'components/LinkToOrganization'
import PlainText from 'components/PlainText'
import WYSIWYGContent from 'components/WYSIWYGContent'
import RichMedia from 'components/RichMedia'

import './index.sass'

export default function OrganizationFeedPostComments({
  organizationApikey,
  feedPostUid,
  className = '',
}){
  const {
    feedPostComments,
    feedPostCommentsLoading,
    feedPostCommentsLoadingError,
    feedPostCommentsFullyLoaded,
    loadMoreFeedPostComments,
  } = useOrganizationFeedPostComments(
    feedPostUid,
    'OrganizationFeedPostComments',
  )

  const { myPublicProfileDid, myOrganizationMemberships } =
    useMyOrganizationMemberships('OrganizationFeedPostComments')

  const membership = myOrganizationMemberships.find(membership =>
    membership.organizationApikey === organizationApikey
  )
  const isAdmin = membership && membership.admin

  return <InfiniteScrollDown {...{
    className: `OrganizationFeedPostComments ${className}`,
    name: 'comments',
    loading: feedPostCommentsLoading,
    loadingError: feedPostCommentsLoadingError,
    fullyLoaded: feedPostCommentsFullyLoaded,
    loadMore: loadMoreFeedPostComments,
  }}>
    {feedPostComments
      .filter(comment => !comment.deletedAt)
      .map(comment =>
        <OrganizationFeedPostComment
          key={comment.uid}
          sortBy={comment.createdAt}
          comment={comment}
          isAdmin={isAdmin}
          myComment={comment.posterUserDid === myPublicProfileDid}
        />
      )
    }
  </InfiniteScrollDown>
}

OrganizationFeedPostComments.propTypes = {
  className: PropTypes.string,
  organizationApikey: PropTypes.string.isRequired,
  feedPostUid: PropTypes.string.isRequired,
}

const OrganizationFeedPostComment = memo(({ comment, isAdmin, myComment, disabled }) => {
  const {
    takeAction,
    deleting,
    deletingError,
  } = useAppState(
    {
      [`organizationFeedPostComment:${comment.uid}:deleting`]: 'deleting',
      [`organizationFeedPostComment:${comment.uid}:deleting:error`]: 'deletingError',
    },
    'OrganizationFeedPostComments',
  )
  const authorIcon = <OrganizationFeedPostAuthorIcon {...{
    organizationApikey: comment.posterOrganizationApikey && comment.posterOrganizationApikey,
    publicProfileDid: comment.posterOrganizationApikey || comment.posterUserDid,
    bordered: !!comment.posterOrganizationApikey,
  }}/>

  const isRichMedia = RichMedia.is(comment.comment)
  const body = isRichMedia
    ? <RichMedia body={comment.comment} bigify={false}/>
    : wysiwyg.is(comment.comment)
      ? <WYSIWYGContent source={comment.comment}/>
      : <PlainText text={comment.comment} bigify={false} />

  return <AuthorIconBox className="OrganizationFeedPostComments-Comment">
    {authorIcon}
    <FeedContentBox {...{
      disabled: disabled || deleting,
      error: deletingError,
      icon: authorIcon,
      title: <Title {...{comment}}/>,
      actions: getActions({takeAction, comment, isAdmin, myComment}),
      body,
      truncateBody: !isRichMedia,
    }}/>
  </AuthorIconBox>
})

function Title({ comment }){
  return <Fragment>
    {comment.posterOrganizationApikey
      ? <CommentAuthorOrg {...{comment}}/>
      : <CommentAuthorUser {...{comment}}/>
    }
    <span>&nbsp;•&nbsp;</span>
    <TimeAgo time={comment.createdAt} />
  </Fragment>
}

function CommentAuthorOrg({ comment }){
  const { organization } = useOrganization(
    comment.posterOrganizationApikey,
    'OrganizationFeedPostComments.CommentAuthorOrg'
  )
  return <LinkToOrganization organizationApikey={comment.posterOrganizationApikey}>
    {organization.name}
  </LinkToOrganization>
}

function CommentAuthorUser({ comment }){
  const { publicProfile } = usePublicProfile(
    comment.posterUserDid,
    'OrganizationFeedPostComments.CommentAuthorUser'
  )
  return <LinkToPublicProfile publicProfile={publicProfile}/>
}

function getActions({takeAction, comment, isAdmin, myComment}){
  const options = []
  if (isAdmin || myComment){
    options.push({
      value: 'Delete',
      onSelect: () => {
        if (confirm( // eslint-disable-line
          `Are you sure you want to delete this post?`
        )) takeAction('feedPosts.deleteComment', { comment })
      },
    })
  }
  return options
}
