import { h } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

import { useOrganizations } from 'lib/membershipAppStateHooks'
import { useOrganizationFeedSubscriptions } from 'lib/feedSubscriptionHooks'
import classNames from 'lib/classNames'

import HeaderedContentBox from 'components/HeaderedContentBox'
import OrganizationConnectionsList from 'components/OrganizationConnectionsList'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'

export default function OrganizationFeedSubscriberList({
  className,
  rootPagePath,
  organization,
  collapsedByDefault = false,
  ...props
}){
  const {
    organizationFeedSubscriptions,
    errorLoadingOrganizationFeedSubscriptions,
    loadingOrganizationFeedSubscriptions,
  } = useOrganizationFeedSubscriptions(
    organization.apikey,
    'OrganizationFeedSubscriberList',
  )
  const subscriberOrganizationApikeys = organizationFeedSubscriptions
    .filter(s => s.subscriberOrganizationApikey && (s.organizationApikey === organization.apikey))
    .map(s => s.subscriberOrganizationApikey)
  const {
    organizations,
    organizationsLoading,
    organizationsLoadingError,
  } = useOrganizations(subscriberOrganizationApikeys, 'OrganizationFeedSubscriberList')

  const loading = loadingOrganizationFeedSubscriptions || organizationsLoading
  return <HeaderedContentBox {...{
    className: classNames('OrganizationFeedSubscriberList', { className }),
    icon: 'circle-thin',
    header: `Publishes to these ${PNFO.plural}`,
    collapsable: true,
    collapsedByDefault,
  }}>
    <ErrorMessage error={errorLoadingOrganizationFeedSubscriptions || organizationsLoadingError}/>
    {loading
      ? <Loading />
      : <OrganizationConnectionsList {...{
        ...props,
        organization,
        organizations,
        getLinkToPreview: apikey => `${rootPagePath}/${apikey}`,
        onEmpty: `No ${PNFO.plural} are subscribed to ${organization.name}`,
      }}/>
    }
  </HeaderedContentBox>
}
