import { h } from 'preact'
import PropTypes from 'prop-types'

import history from 'lib/history'

import Link from 'components/Link'
import Copier from 'components/Copier'

export default function CopyLink({
  className = '',
  href,
  value,
  copiedValue,
  onClick,
  ...props
}){
  return <Copier value={history.expandHref(href)}>
    {copied =>
      <Link
        className={`CopyLink ${className}`}
        href={href}
        onClick={event => {
          if (
            !event.ctrlKey &&
            !event.metaKey &&
            !event.shiftKey
          ) event.preventDefault()
          if (onClick) return onClick(event)
        }}
        {...props}
      >{copied ? copiedValue : value}</Link>
    }
  </Copier>
}

CopyLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired,
  value: PropTypes.node.isRequired,
  copiedValue: PropTypes.node.isRequired,
  onClick: PropTypes.func,
}
