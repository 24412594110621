import PNFO from 'jlinc-shared/PNFO'
import { h, Fragment } from 'preact'
import { useState, useRef, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import {
  byWeightDescCreatedAtDesc,
  useOrganization,
  useOrganizations,
} from 'lib/membershipAppStateHooks'
import {
  useOrganizationNetworkMembershipsWithOrganizations,
} from 'lib/organizationNetworkMembershipHooks'
import useFirstRender from 'lib/useFirstRender'

import Link from 'components/Link'
import Icon from 'components/Icon'
import Header from 'components/Header'
import ErrorMessage from 'components/ErrorMessage'
import SingleLine from 'components/SingleLine'
import BreadCrumbs from 'components/BreadCrumbs'
import OrganizationIcon from 'components/OrganizationIcon'
import OrganizationInfo from 'components/OrganizationInfo'
import OrganizationList from 'components/OrganizationList'
import './index.sass'

export default function NetworkNavigator({
  className,
  organizationApikey: networkOrganizationApikey,
  trail = [],
  ...props
}){
  const panelsRef = useRef()
  const firstRender = useFirstRender()

  const [renderedTrail, setRenderedTrail] = useState(trail)
  useEffect(
    () => {
      let timeout
      const panels = panelsRef.current
      const allPanels = panels.querySelectorAll('.NetworkNavigator-Panel')

      const update = () => { setRenderedTrail(trail) }
      const scrollToPanel = (index, cb) => {
        const smooth = !firstRender

        const left = panels.scrollLeft
        const halfSized = Math.abs(panels.clientWidth - allPanels[0].clientWidth) > 100
        let newLeft = allPanels[0].clientWidth * (halfSized ? (index - 1) : index)
        if (newLeft < 0) newLeft = 0

        const distance = smooth ? Math.abs(newLeft - left) : 0
        const duration = distance * 0.6
        panels.scrollTo({
          left: newLeft,
          behavior: smooth ? 'smooth' : undefined,
        })
        if (cb) timeout = setTimeout(cb, duration)
      }
      const scrollToLastPanel = () => {
        scrollToPanel(allPanels.length)
      }
      const updateAndScrollToLastPanel = () => {
        update()
        timeout = setTimeout(scrollToLastPanel, 10)
      }

      const firstLeaving = renderedTrail.findIndex((apikey, index) =>
        !trail[index] || trail[index] !== apikey
      )
      if (
        firstLeaving !== -1 &&
        (renderedTrail.length !== trail.length || firstLeaving !== trail.length - 1)
      ){
        // scroll to panel before the first leaving channel
        // its off by one because the first panel isnt part of the trail
        scrollToPanel(firstLeaving, updateAndScrollToLastPanel)
      }else{
        updateAndScrollToLastPanel()
      }
      return () => {
        clearTimeout(timeout)
      }
    },
    [networkOrganizationApikey, trail.join('/')]
  )

  return <div
    {...props}
    className={classNames('NetworkNavigator', { className })}
  >
    <BreadcrumbTrail {...{networkOrganizationApikey, trail}}/>
    <div ref={panelsRef} className="NetworkNavigator-Panels">
      <Panel {...{
        trail: [],
        selected: renderedTrail[0],
        networkOrganizationApikey,
        organizationApikey: networkOrganizationApikey,
      }}/>
      {renderedTrail.length === 0 && <HelpPanel {...{}}/> }
      {renderedTrail.map((organizationApikey, index) =>
        <Panel {...{
          trail: renderedTrail.slice(0, index + 1),
          selected: renderedTrail[index + 1],
          networkOrganizationApikey,
          organizationApikey,
        }}/>
      )}
    </div>
  </div>
}

NetworkNavigator.propTypes = {
  className: PropTypes.string,
  organizationApikey: PropTypes.string.isRequired,
  trail: PropTypes.arrayOf(PropTypes.string).isRequired,
}


function BreadcrumbTrail({ networkOrganizationApikey, trail = [] }){
  const organizationApikeys = [networkOrganizationApikey, ...trail]
  const { organizations } = useOrganizations(organizationApikeys, 'NetworkNavigator')
  let href = ''
  const crumbs = organizationApikeys.map((organizationApikey, index) => {
    const key = `${index}-${organizationApikey}`
    const organization = organizations.find(o => o.apikey === organizationApikey)
    const value = <div className="NetworkNavigator-crumb">
      <OrganizationIcon bordered {...{organization, size:  'sm'}}/>
      <SingleLine>{(organization && organization.name) || organizationApikey}</SingleLine>
    </div>
    if (href === '')
      href = `/${networkOrganizationApikey}/network`
    else
      href += `/${organizationApikey}`
    return { key, value, href }
  })
  return <BreadCrumbs {...{crumbs}}/>
}

function Panel(props){
  const { organizationApikey } = props
  const { organization } = useOrganization(organizationApikey, 'NetworkNavigator')

  const href = `/${organizationApikey}` + ((organization && organization.is_network) ? '/network' : '')
  return <div className="NetworkNavigator-Panel">

    <Header size="lg" centered className="NetworkNavigator-Panel-header">
      <Link href={href}>
        <OrganizationIcon bordered {...{ organization, size:  'lg'}} />
      </Link>
      <Link href={href}>
        <div>{(organization && organization.name) || organizationApikey}</div>
      </Link>
    </Header>
    {organization && (
      organization.is_network
        ? <NetworkMembers {...props}/>
        : <Fragment>
          <br/>
          <Header size="md" italic centered padded>
            {organization.name} is not a Network
          </Header>
          <OrganizationInfo {...{organization}}/>
        </Fragment>
    )}
  </div>
}

function NetworkMembers({ trail, selected, networkOrganizationApikey, organizationApikey }){
  const {
    organizationNetworkMemberships,
    organizationNetworkMembershipsLoading: organizationsLoading,
    organizationNetworkMembershipsLoadingError: error,
  } = useOrganizationNetworkMembershipsWithOrganizations(organizationApikey, 'NetworkNavigator')

  const organizations = organizationNetworkMemberships
    .filter(m => m.accepted && m.organizationApikey === organizationApikey)
    .map(membership => {
      const organizationApikey = membership.memberOrganizationApikey
      const isSelected = organizationApikey === selected
      let path = `/${organizationApikey}`
      if (organizationApikey === networkOrganizationApikey){
        path = ''
      }else if (trail.length > 0){
        const trailIndex = trail.indexOf(organizationApikey)
        if (trailIndex === -1){
          path = '/' + trail.join('/') + (isSelected ? '' : `/${organizationApikey}`)
        }else{
          path = '/' + trail.slice(0, trailIndex + 1).join('/')
        }
      }
      return {
        ...membership.memberOrganization,
        membership,
        href: `/${networkOrganizationApikey}/network${path}`,
        selected: isSelected,
        className: classNames('NetworkNavigator-org', {
          "selected": isSelected,
          "not-selected": !!selected && !isSelected,
        }),
      }
    })
    .sort(byWeightDescCreatedAtDesc)

  return <Fragment>
    <ErrorMessage {...{error}}/>
    <Header size="sm" underlined italic className="NetworkNavigator-NetworkMembers-header">
      Listed {PNFO.plural}:
    </Header>
    <OrganizationList {...{
      organizationsLoading,
      organizations,
      onEmpty: <Fragment>
        <Header size="md" italic centered padded>
          There are no {PNFO.plural} is this Network
        </Header>
      </Fragment>
    }}/>
  </Fragment>
}

function HelpPanel({ }){
  return <div className="NetworkNavigator-Panel NetworkNavigator-HelpPanel">
    <Icon type="network" />
    <Header size="xl" centered>Network Navigator</Header>
    <br/>
    <Header size="md" centered>
      click on one of the {PNFO.plural} on
      the left to view its network.
    </Header>
  </div>
}
