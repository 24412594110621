import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { useLocation } from 'resources/location'
import {
  normalizePathname,
  getPathnameFromHref,
} from 'lib/location'

import Button from 'components/Button'
import IconButton from 'components/IconButton'
import Tooltip from 'components/Tooltip'
import './index.sass'

export default function PageTab({
  className,
  href,
  tooltip,
  selected,
  selectedIfExact = false,
  ...props
}){
  const location = useLocation()
  const currentPathname = normalizePathname(location.pathname)
  if (
    href &&
    (selectedIfExact
      ? currentPathname === normalizePathname(getPathnameFromHref(href))
      : currentPathname.startsWith(normalizePathname(getPathnameFromHref(href)))
    )
  ) selected = true
  className = classNames('PageTab', {selected, className})
  const button = props.icon
    ? <IconButton {...{...props, className, type: props.icon, href}}/>
    : <Button {...{...props, className, type: 'none', href}}/>
  if (tooltip) return <Tooltip text={tooltip}>{button}</Tooltip>
  return button
}

PageTab.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  selectedIfExact: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
}

