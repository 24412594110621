import { h, Component } from 'preact'
import PropTypes from 'prop-types'
const PERSONAL_DATA_LABELS = require('jlinc-shared/personal_data_labels')
import { getLabelForPersonalDataField } from 'lib/accountDataSpec'

import Form from 'components/Form'
import TextInputWithSwitch from 'components/TextInputWithSwitch'
import TextAreaWithSwitch from 'components/TextAreaWithSwitch'
import CountryDropdownPicker from 'components/CountryDropdownPicker'
import Switch from 'components/Switch'
import Tooltip from 'components/Tooltip'

import './index.sass'

import {
  SHARED_PERSONAL_DATA_PROP_TYPE,
  PERSONAL_DATA_PROP_TYPE,
} from 'lib/accountDataSpec'

export default class AliceDefaultPersonalDataForm extends Component {

  static propTypes = {
    disabled:                        PropTypes.bool,
    customPersonalDataFields:        PropTypes.array,
    sharedPersonalData:              SHARED_PERSONAL_DATA_PROP_TYPE.isRequired,
    sharedPersonalDataStagedChanges: SHARED_PERSONAL_DATA_PROP_TYPE,
    personalData:                    PERSONAL_DATA_PROP_TYPE.isRequired,
    personalDataStagedChanges:       PERSONAL_DATA_PROP_TYPE,
    onPersonalDataChange:            PropTypes.func.isRequired,
    onSharedPersonalDataChange:      PropTypes.func.isRequired,
  }

  render(){
    const {
      disabled,
      customPersonalDataFields,
      sharedPersonalData,
      sharedPersonalDataStagedChanges = {},
      personalData,
      personalDataStagedChanges = {},
      onPersonalDataChange,
      onSharedPersonalDataChange,
    } = this.props

    const tooltipText = name => `Please enter a ${name} to enable sharing`
    const formItem = (prop, name, type, autocomplete = "off") => {
      const label = getLabelForPersonalDataField(prop).toUpperCase()
      const unsaved = prop in personalDataStagedChanges
      const value = unsaved ? personalDataStagedChanges[prop] : personalData[prop]
      const shareUnsaved = prop in sharedPersonalDataStagedChanges
      const shared = shareUnsaved ? sharedPersonalDataStagedChanges[prop] : sharedPersonalData[prop]
      return <Form.Item>
        <Form.Label>{label}</Form.Label>
        { type === 'textarea'
          ? <TextAreaWithSwitch
            disabled={disabled}
            textAreaUnsaved={unsaved}
            switchUnsaved={shareUnsaved}
            switchDisabled={!value || disabled}
            switchTooltip={!value}
            tooltipText={!value ? tooltipText(label) : ''}
            value={value || ''}
            checked={shared}
            name={name}
            maxLines={3}
            onTextAreaInput={value => { onPersonalDataChange({[prop]: value}) }}
            onSwitchChange={checked => { onSharedPersonalDataChange({[prop]: checked}) }}
            autocomplete={autocomplete}
          />
          : <TextInputWithSwitch
            disabled={disabled}
            textInputUnsaved={unsaved}
            switchDisabled={!value || disabled}
            switchUnsaved={shareUnsaved}
            switchTooltip={!value}
            tooltipText={!value ? tooltipText(label) : ''}
            value={value || ''}
            checked={shared}
            name={name}
            type={type}
            onTextInput={value => { onPersonalDataChange({[prop]: value}) }}
            onSwitchChange={checked => { onSharedPersonalDataChange({[prop]: checked}) }}
            autocomplete={autocomplete}
            noDatePickerIcon
          />
        }
      </Form.Item>
    }

    const countryFormItem = prop => {
      const shareUnsaved = prop in sharedPersonalDataStagedChanges
      const shared = shareUnsaved ? sharedPersonalDataStagedChanges[prop] : sharedPersonalData[prop]
      const unsaved = prop in personalDataStagedChanges
      const value = unsaved ? personalDataStagedChanges[prop] : personalData[prop]
      const label = PERSONAL_DATA_LABELS[prop].toUpperCase()
      let switchElement = <Switch
        disabled={!value || disabled}
        checked={shared}
        unsaved={shareUnsaved}
        onChange={checked => { onSharedPersonalDataChange({[prop]: checked}) }}
      />
      if (!value) {
        switchElement = <Tooltip text={tooltipText(label)}>
          {switchElement}
        </Tooltip>
      }
      return <Form.Item>
        <Form.Label>{label}</Form.Label>
        <div className="AliceDefaultPersonalDataForm-countryField">
          <CountryDropdownPicker
            unsaved={unsaved}
            onChange={country => { onPersonalDataChange({[prop]: country}) }}
            disabled={disabled}
            value={value}
          />
          {switchElement}
        </div>
      </Form.Item>
    }

    return <Form className="AliceDefaultPersonalDataForm">
      <Form.Row>
        {formItem('firstname', 'given-name', 'text', 'given-name')}
        {formItem('lastname', 'family-name', 'text', 'family-name')}
      </Form.Row>

      <Form.Row>
        {formItem('email', 'email', 'email', 'email')}
        {formItem('salutation', 'honorific-suffix', 'text', 'salutation')}
      </Form.Row>

      {formItem('mailingstreet', 'street-address', 'textarea', 'street-address')}

      <Form.Row>
        {formItem('mailingcity', 'city', 'text', 'address-level1 locality city')}
        {formItem('mailingstate', 'state', 'text', 'administrative-area state province region')}
      </Form.Row>

      <Form.Row>
        {formItem('mailingpostalcode', 'postal-code', 'text', 'postal-code')}
        {countryFormItem('mailingcountry')}
      </Form.Row>

      <Form.Row>
        {formItem('birthdate', 'birthday', 'date', 'birthday')}
        {formItem('gender', 'gender', 'text', 'gender')}
      </Form.Row>

      <Form.Row>
        {formItem('mobilephone', 'mobile', 'tel', 'tel')}
        {formItem('homephone', 'phone', 'tel', 'tel')}
      </Form.Row>

      <Form.Row>
        {formItem('faxnumber', 'fax', 'tel')}
        {formItem('twitter', 'twitter')}
      </Form.Row>

      <Form.Row>
        {formItem('linkedin', 'linkedin')}
        {formItem('google', 'google')}
      </Form.Row>

      <Form.Row>
        {formItem('instagram', 'instagram')}
        {formItem('facebook', 'facebook')}
      </Form.Row>

      <Form.Row>
        {formItem('businessname', 'organization')}
        {formItem('businessindustry', 'organization-industry')}
      </Form.Row>

      <Form.Row>
        {formItem('title', 'organization-title')}
      </Form.Row>

      <Form.Row>
        {formItem('businessstreet', 'organization-street-address', 'textarea')}
      </Form.Row>

      <Form.Row>
        {formItem('businesscity', 'organization-city')}
        {countryFormItem('businesscountry')}
      </Form.Row>

      <Form.Row>
        {formItem('businesspostalcode', 'organization-postal-code')}
        {formItem('businessphone', 'organization-phone', 'tel')}
      </Form.Row>

      {customPersonalDataFields.map(propName =>
        <Form.Row>
          {formItem(propName)}
        </Form.Row>
      )}
    </Form>
  }
}
