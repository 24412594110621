import { h } from 'preact'

import { setPageTitle } from 'lib/Page'

import TwoColumns from 'components/TwoColumns'
import ContentBox from 'components/ContentBox'
import Navbar from 'components/Navbar'
import Icon from 'components/Icon'
import Header from 'components/Header'
import MyDataDefaultsForm from 'components/MyDataDefaultsForm'
import HeaderedContentBox from 'components/HeaderedContentBox'

export default function MyDataDefaultsPage(){

  setPageTitle('My Data Defaults')

  return <TwoColumns className="MyDataDefaultsPage">
    <div>
      <ContentBox className="MyDataDefaultsPage-nav">
        <Navbar>
          <Header size="lg">
            <Icon type="cog" size="lg" blue/>
            My Data Defaults
          </Header>
        </Navbar>
      </ContentBox>
      <MyDataDefaultsForm />
    </div>
    <MyDataPageExplanation/>
  </TwoColumns>
}

const MyDataPageExplanation = () =>
  <HeaderedContentBox padded header="Control your personal data defaults">
    <p>
      Your personal data sharing permissions and settings are controlled
      here.
    </p>
    <p>
      This system was built for GDPR, a new law in Europe that give each person rights to control
      their personal data. In the US, this system is available for companies that wish to offer it to you.
    </p>
    <p>
      Each organization that shares the data they have for you contributes it to this set,
      where you can add to or edit it. The system allows you to save your own default data and permissions,
      and then share that with new organizations in one click.
    </p>
    <p>
      When you share personal data with an organization under a Standard Information Sharing Agreement (SISA),
      that digitally signed agreement governs what they can do with your data. This gives you control over
      your data - after it is shared.
    </p>
    <p>
      If you change the permission or update the data, the organization's system automatically signs that update
      and follows your instruction.
    </p>
    <p>
      This provides a working solution for legally binding data rights under GDPR in the EU. It also provides
      the basis for a far more valuable marketing relationship for leading companies in the US and around the world.
    </p>
  </HeaderedContentBox>
