import { h } from 'preact'

import { useOrganizationFeedPost } from 'lib/feedPostHooks'

import Loading from 'components/Loading'
import PageNotFound from 'components/PageNotFound'
import ContentBox from 'components/ContentBox'
import ErrorMessage from 'components/ErrorMessage'
import OrganizationFeedPost from 'components/OrganizationFeedPost'
import OrganizationPageSubnav from 'components/OrganizationPageSubnav'
import OrganizationFeedSubscriptionsButton from 'components/OrganizationFeedSubscriptionsButton'
import './index.sass'

export default function OrganizationFeedPostPage(props){
  const { currentUser, isMember, organization, params: { feedPostUid } } = props

  return <div className="OrganizationFeedPostPage">
    <OrganizationPageSubnav {...{
      currentUser,
      isMember,
      icon: 'public-feed',
      name: 'Published Feed Post',
      children: <OrganizationFeedSubscriptionsButton {...{ organization }}/>,
    }}/>

    <div className="OrganizationFeedPostPage-center">
      <PageContent key={feedPostUid} {...{...props, feedPostUid}}/>
    </div>
  </div>
}

function PageContent(props){
  const {
    feedPostUid,
  } = props

  const {
    feedPost,
    feedPostLoading,
    feedPostLoadingError,
    feedPostNotFound,
  } = useOrganizationFeedPost(
    feedPostUid,
    'OrganizationFeedPostPage',
  )

  if (feedPostLoadingError) return <ContentBox padded>
    <ErrorMessage error={feedPostLoadingError} />
  </ContentBox>

  if (feedPostNotFound) return <PageNotFound {...props}/>

  if (feedPostLoading || !feedPost) return <Loading />

  return <OrganizationFeedPost {...{post: feedPost}}/>
}
