import { h } from 'preact'
import PropTypes from 'prop-types'
import filesize from 'filesize'

import classNames from 'lib/classNames'

export default function FileSize({className, size, ...props}){
  let humanized
  try{ humanized = filesize(size) }catch(e){ /* */ }
  return <span {...{
    className: classNames('FileSize', { className }),
    title: humanized,
    ...props
  }}>{humanized}</span>
}

FileSize.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number.isRequired,
}
