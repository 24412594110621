import { h } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

import classNames from 'lib/classNames'
import {
  useMyOrganizations,
  usePublicOrganizations,
} from 'lib/membershipAppStateHooks'

import MyOrganizationsList from 'components/MyOrganizationsList'

export default function PublicOrganizationsList({ className, ...props }){
  const {
    publicOrganizations,
    publicOrganizationsLoading,
    publicOrganizationsLoadingError,
  } = usePublicOrganizations('PublicOrganizationsList')

  const {
    myOrganizations = [],
  } = useMyOrganizations('PublicOrganizationsList')

  const organizationsLoading = (
    !publicOrganizations ||
    publicOrganizationsLoading
  )

  const organizations = organizationsLoading
    ? undefined
    : [...myOrganizations, ...publicOrganizations]

  return <MyOrganizationsList {...{
    ...props,
    error: publicOrganizationsLoadingError,
    className: classNames('PublicOrganizationsList', {className}),
    organizationsLoading,
    organizations,
    onEmpty: `There are no public ${PNFO.plural}`,
  }}/>
}
