import { h, Fragment } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

import Form from 'components/Form'
import OrganizationIconInput from 'components/OrganizationIconInput'
import CropImageInputFormRow from 'components/CropImageInputFormRow'
import IconRow from 'components/IconRow'
import OrganizationIcon from 'components/OrganizationIcon'
import OrganizationNameAndTagLine from 'components/OrganizationNameAndTagLine'
import OrganizationEditForm from 'components/OrganizationEditForm'

export default function NameAndBrandingPage({
  organization, changes, setChanges
}){
  return <OrganizationEditForm {...{
    className: 'OrganizationAdminPage-padded',
    organization,
    value: changes,
    onChange: setChanges,
    render: ({ form, formControls }) =>
      <Fragment>
        <CropImageInputFormRow
          cropImageInput={
            <Fragment>
              <Form.Label>ICON</Form.Label>
              {form.bindInput({
                valueProp: 'consumer_icon',
                input: <OrganizationIconInput />
              })}
              <div>
                <small>{OrganizationIconInput.width}px/{OrganizationIconInput.height}px</small>
              </div>
            </Fragment>
          }
        >
          <Form.Row>
            {form.textItem({
              valueProp: 'name',
              label: `NAME OF ${PNFO.singular.toUpperCase()}`,
              required: true,
              maxLength: 29,
            })}
          </Form.Row>
          <Form.Row>
            {form.textItem({
              valueProp: 'tag_line',
              label: 'SUBTITLE',
              maxLength: 51,
            })}
          </Form.Row>
        </CropImageInputFormRow>
        <Form.Row>
          <Form.Item>
            <Form.Label>PREVIEW</Form.Label>
            <IconRow
              icon={<OrganizationIcon {...{organization}}/>}
              row={<OrganizationNameAndTagLine
                organizationName={organization.name}
                organizationTagLine={organization.tag_line}
              />}
            />
          </Form.Item>
        </Form.Row>
        <Form.Row>
          {form.textAreaItem({
            required: true,
            className: 'OrganizationAdminPage-short_description',
            valueProp: 'short_description',
            label: 'SHORT DESCRIPTION',
            maxLength: 280,
            showCounter: true,
          })}
        </Form.Row>
        {formControls()}
      </Fragment>
  }}/>
}

