import { createLogger } from 'lib/logger'
import defer from 'lib/defer'
import { tx, createInMemoryStore } from 'lib/stores'
import aServer from './aServer'
import oldAppState from './oldAppState'

import {
  getSession,
  setSession,
  useSession,
  setKeepMeLoggedIn,
  useSessionId,
  // setSessionId,
  onAuthChange,
} from './session'

import { setPageError } from './pageAlerts'

const logger = createLogger('auth', 'color: pink')

const state = createInMemoryStore({
  // signupFormValues: {},
  // loggingIn: false,
  // loggingInError: undefined,
  // verifying: false,
  // verifyingError: undefined,
  // loadingOverview: false,
  // loadingOverviewError: undefined,
})


// ACTIONS

export function getCurrentUser(){
  const { userId, publicProfileDid, username } = getSession()
  return { userId, publicProfileDid, username }
}

export function clearCurrentUser(){
  setSession({}, true)
}

export function setLogin(login){
  setSession({ login })
}

export function setUsername(username){
  setSession({ username })
}

export function isLoggedIn(){
  return !!getSession().sessionId
}

export function assertLoggedIn(){
  if (!isLoggedIn()) throw new Error(`you must be logged in`)
}

export function assertNotLoggedIn(){
  if (isLoggedIn()) throw new Error(`you cannot be logged in`)
}

export async function checkUsername(username){
  logger.into(`checking username "${username}"`)
  throw new Error('NOT DONE YET')
}

export function setSignUpError(error){
  logger.error(error)
  setPageError('Signup Failed ', error)
}

export function signUp({
  username,
  email,
  mobile,
  password,
  destinationPath,
  source,
  joinOrganization,
  organizationInviteToken,
}){
  logger.info('signing up', {
    username,
    email,
    mobile,
    // password,
    destinationPath,
    source,
    joinOrganization,
  })
  return tx(state, 'signingUp', async () => {
    setSignUpError()
    const { keepMeLoggedIn } = getSession()
    try{
      const newSession = await aServer.postJSON('/signup', {
        username,
        email,
        mobile,
        password,
        destinationPath,
        keepMeLoggedIn,
        source,
        joinOrganization,
        // organizationApikey,
        // sisaInvite,
        membershipInviteToken: organizationInviteToken,
      })
      logger.info('signup successful', newSession)
      setSession(newSession)
      logger.info('signup successful', getSession())

    }catch(signUpError){
      if (`${signUpError}`.match(/a user with that (email|mobile) already exists/i)){
        return await loginAs({
          login: RegExp.$1 === 'email' ? email : mobile,
          password,
          joinOrganization
        }).catch(() => {
          setSignUpError(signUpError)
        })
      }
      setSignUpError(signUpError)
    }
  })
}
export function dismissSignupError(){
  state.set({ signingUpError: undefined })
}

export async function loginAs({login, password, joinOrganization}){
  return tx(state, 'loggingIn', async () => {
    setPageError('Login Failed')
    const { identifier, keepMeLoggedIn } = getSession()
    logger.info('logging in as', identifier)
    try{
      const newSession = await aServer.postJSON('/login', {
        login,
        password,
        keepMeLoggedIn,
        joinOrganization,
      })
      setSession(newSession)
      logger.info('login successful', getSession())
    }catch(loginError){
      setPageError('Login Failed', loginError)
      throw loginError
    }
  })
}

export async function logout(){
  logger.info('logging out')
  // TODO post to server
  clearCurrentUser()


  // TEMP until we can wire things up better
  // we load things when logged out that need to be reloaded
  // after  login since the data we loaded has subtle changes
  // depending on if you are logged or not.
  //
  // Until we can fix this, delete such keys here at login.
  oldAppState.deleteKeys(
    /^(my|organization|membership|notification|preferences)/,
  )
  /* END OF STUPID HACK */

}

export function loadOverview(){
  logger.info('loading overview')
  assertLoggedIn()
  return tx(state, 'loadingOverview', async () => {
    const overview = await aServer.getJSON('/overview')
    logger.info('overview loaded', overview)
    const {
      userId,
      publicProfileDid,
      username,
      sisas,
      preferences,
      myPublicProfile,
    } = overview
    setSession({ userId, publicProfileDid, username })
    const newState = { preferences, mySISAs: new Set() }
    sisas.forEach(sisa => {
      newState.mySISAs.add(sisa.organizationApikey)
      newState[`sisa:${sisa.organizationApikey}`] = sisa
    })
    oldAppState.setState(newState)
    oldAppState.takeAction('publicProfiles.addPublicProfilesToAppState', [myPublicProfile])
  })
}


// HOOKS

export function useLogin(){
  const { login, keepMeLoggedIn } = useSession()
  return {
    ...state.useStore(['loggingIn', 'loggingInError']),
    login,
    setLogin,
    keepMeLoggedIn,
    setKeepMeLoggedIn,
  }
}

export function useSignup(){
  return {
    ...useSession(['username', 'login']),
    ...state.useStore(['signingUp', 'signingUpError']),
    signUp,
    setSignUpError,
    setLogin,
    setUsername,
  }
}

export function useLoggedIn(){
  return !!useSessionId()
}

export function useCurrentUser(){
  return useSession(s =>
    s.sessionId
      ? {
        userId: s.userId,
        publicProfileDid: s.publicProfileDid,
        username: s.username,
      }
      : undefined
  )
}

export function useMyPublicProfileDid(){
  return useSession(s => s.publicProfileDid)
}


// INIT

defer(() => {
  if (isLoggedIn()) loadOverview()
  onAuthChange(() => {
    if (isLoggedIn()) loadOverview()
    else clearCurrentUser()
  })
})


// DEBUG

Object.assign(DEBUG, {
  loginAs, logout, useSessionId, getCurrentUser,
  authState: state,
})
