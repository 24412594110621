import { h } from 'preact'

import { useSisa } from 'lib/membershipAppStateHooks'
import classNames from 'lib/classNames'

import Button from 'components/Button'

export default function OrganizationSISAButton({
  className,
  organizationApikey,
}) {
  const { sisa } = useSisa(organizationApikey, 'OrganizationSISAButton')

  if (isDataYogi && sisa) return

  const href = sisa ? `/${organizationApikey}/my-data` : `/${organizationApikey}/sisa`
  const type = 'normal'
  const value = sisa ? 'My Data' : isDataYogi ? 'Sign Standard Information Sharing Agreement' : 'Co-manage My Data'
  const fat = !!isDataYogi

  return <Button {...{ className: classNames('OrganizationSISAButton', { className }), href, type, value, fat }} />
}
