import { h } from 'preact'

import { useLoggedIn } from 'resources/auth'

export default function OnlyWhenLoggedIn(props){
  const loggedIn = useLoggedIn()
  if (!loggedIn) return null
  return typeof props.children === 'function'
    ? props.children()
    : props.children
}

OnlyWhenLoggedIn.wrap = Component => props =>
  <OnlyWhenLoggedIn>
    <Component {...props}/>
  </OnlyWhenLoggedIn>

