import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Form from 'components/Form'
import TextInput from 'components/TextInput'
import Button from 'components/Button'
import ErrorMessage from 'components/ErrorMessage'
import './index.sass'

const SIX_DIGITS = /^\d{6}$/

export default class MobilePhoneVerificationForm extends Component {

  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitting: PropTypes.bool,
    error: ErrorMessage.propTypes.error,
  }

  componentDidMount(){
    this.focusInput()
  }

  componentWillReceiveProps(nextProps){
    if (!this.props.error && nextProps.error) this.focusInput()
  }

  state = {
    code: '',
  }

  focusInput(){
    setTimeout(
      () => TextInput.focus(this.input),
      10,
    )
  }

  onInput = code => {
    const { code: prevCode } = this.state
    code = code.replace(/\D/g, '').slice(0, 6)
    this.setState({ code }, () => {
      if (code.length === 6 && code !== prevCode)
        this.submit()
    })
  }

  onFocus = event => {
    if (event.target.value) event.target.select()
  }

  submit = () => {
    if (this.props.submitting) return
    this.props.onSubmit(this.state.code)
  }

  render(){
    const disabled = !!this.props.submitting
    const { code } = this.state
    const complete = SIX_DIGITS.test(code)
    return <Form className="MobilePhoneVerificationForm" onSubmit={this.submit}>
      <Header size="lg" centered>Verify your mobile</Header>
      <p className="MobilePhoneVerificationForm-explainerText">
        {APP_NAME} has sent you an SMS to verify that you are a human and not an evil bot.
      </p>
      <Header size="sm" centered>
        Please enter the code sent to your mobile:
      </Header>
      <ErrorMessage error={this.props.error} />
      <TextInput
        type="number"
        autoFocus
        ref={input => { this.input = input }}
        className="MobilePhoneVerificationForm-number"
        max={999999}
        value={code}
        onInput={this.onInput}
        onFocus={this.onFocus}
        autocomplete="new-password"
        disabled={disabled}
      />
      <Button
        submit
        block
        fat
        type="primary"
        value="Verify Account"
        disabled={disabled || !complete}
      />
    </Form>
  }
}
