import { useEffect } from 'preact/hooks'
import { useAppActions } from 'lib/appState'

export function useCreateAlert(componentName){
  const createAlert = useAppActions(componentName).appAction('ui.alert')
  for (const type of ['success', 'info', 'warning', 'error'])
    createAlert[type] = (message) => createAlert({ type, message })
  return createAlert
}

export function useAlertIf(condition, alert, componentName){
  const createAlert = useCreateAlert(componentName)
  useEffect(
    () => { if (condition) createAlert(alert) },
    [condition],
  )
}

export function useAlertErrorIf(error, message, componentName){
  useAlertIf(!!error, {type: 'error', message}, componentName)
}
