import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import Link from 'components/Link'
import './index.sass'

export default function IconRow({
  className = '',
  disabled,
  inline,
  href,
  onClick,
  icon,
  row,
  buttons,
  ...props
}){
  return <div {...{
    ...props,
    className: classNames('IconRow', { className, disabled, inline }),
  }}>
    <Link {...{disabled, href, onClick, className: 'IconRow-icon', tabIndex: -1}}>{icon}</Link>
    <Link {...{disabled, href, onClick, className: 'IconRow-row'}}>{row}</Link>
    { !disabled && buttons && <div className="IconRow-buttons">{buttons}</div> }
  </div>
}

IconRow.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  inline: PropTypes.bool,
  href: Link.propTypes.href,
  onClick: Link.propTypes.onClick,
  icon: PropTypes.node,
  row: PropTypes.node,
  buttons: PropTypes.node,
}
