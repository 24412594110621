import { h, Fragment, cloneElement } from 'preact'
import {
  useRef,
  useCallback,
  useEffect,
  useErrorBoundary,
} from 'preact/hooks'
import PNFO from 'jlinc-shared/PNFO'

import isMobile from 'lib/isMobile'
import metaKeyCharacter from 'lib/metaKeyCharacter'
import history from 'lib/history'
import { useMyPublicProfile } from 'lib/membershipAppStateHooks'
import useOnScroll from 'lib/useOnScrollHook'
import usePageKeyPress from 'lib/usePageKeyPressHook'
import useIsPWA from 'lib/useIsPWAHook'
import useToggle from 'lib/useToggleHook'
import { publicProfileToPathname } from 'lib/publicProfiles'
import useOnlineStatus from 'lib/useOnlineStatusHook'
import { useCurrentUser } from 'resources/auth'
import { logout } from 'resources/auth'

import AppError from 'components/AppError'
import Link from 'components/Link'
import Icon from 'components/Icon'
import IconButton from 'components/IconButton'
import TruLogo from 'components/TruLogo'
import SearchInput from 'components/SearchInput'
import LinkToPublicProfile from 'components/LinkToPublicProfile'
import EndUserAvatar from 'components/EndUserAvatar'
import DropdownMenu from 'components/DropdownMenu'
import Navbar from 'components/Navbar'
import PageTab from 'components/PageTab'
import Tooltip from 'components/Tooltip'
import NotificationsButton from 'components/NotificationsButton'
import EndUserLoginDropdown from 'components/EndUserLoginDropdown'
import ChatNotificationsButton from 'components/ChatNotificationsButton'
import Alert from 'components/Alert'

import './index.sass'

export default function Layout({ location, params, children }){
  const currentUser = useCurrentUser()
  const rootRef = useRef()

  useEffect(
    () => {
      // this is here so the overscroll color matches the new page background color
      const style = global.getComputedStyle(rootRef.current)
      global.document.body.style.backgroundColor = style.getPropertyValue('--body-background-color')
      return () => {
        global.document.body.style.backgroundColor = null
      }
    },
    []
  )

  const scrollYRef = useRef(global.window.scrollY)
  useOnScroll(rootRef, () => {
    const previousScrollY = scrollYRef.current
    const scrollY = global.window.scrollY
    scrollYRef.current = scrollY
    const hideNav = scrollY > 200 && previousScrollY < scrollY
    rootRef.current.classList[hideNav ? 'add' : 'remove'](
      'Layout-TopNav-hidden'
    )
  })

  const searchQuery = (
    location.pathname.startsWith('/search/') &&
    params.searchQuery
  )

  return <div ref={rootRef} className="Layout">
    <TopNav {...{location, currentUser, searchQuery}}/>
    <div className="Layout-main">
      <RenderErrorBoundry>{children}</RenderErrorBoundry>
    </div>
  </div>
}

function RenderErrorBoundry({ children }){
  const [error, dismissError] = useErrorBoundary()
  return error
    ? <div className="Layout-renderError">
      <AppError error={error} onDismiss={dismissError}/>
    </div>
    : children
}

const TopNavPageTab = ({
  children, icon, size, name, href,
  selectedIfExact = true
}) =>
  <PageTab {...{
    href,
    key: `${name}-btn`,
    className: `Layout-TopNav-Button Layout-TopNav-${name.replace(/\s/g, '-')}`,
    selectedIfExact,
  }}>
    {children}
    <Icon type={icon} size={size} />
    <span>&nbsp;&nbsp;{name}</span>
  </PageTab>


function TopNav({location, currentUser, searchQuery}){
  const isPWA = useIsPWA()
  const loggedIn = !!currentUser
  const onLoginPage = location.pathname.match(/^\/(login|reset-password)$/i)
  const onSignupPage = location.pathname.match(/^\/(signup|join|[^\/]+\/join)$/i)

  usePageKeyPress(event => {
    if (event.key === '/'){
      const searchInputs = global.document.body.querySelectorAll('.Layout-SearchBar input')
      const searchInput = Array.from(searchInputs).find(i => i.getBoundingClientRect().y > 0)
      if (searchInput) searchInput.focus()
      event.preventDefault()
      return false
    }
  })

  const buttons = size =>
    isDataYogi
      ? <Fragment>
        <TopNavPageTab {...{icon: 'home', size, name: 'Home', href: '/DataYogi'}}/>
        {!loggedIn && !onLoginPage &&
          <LoginDropdown {...{location}}>
            <TopNavPageTab {...{icon: 'master-data', size, name: 'Login', href: location.toLogin()}}/>
          </LoginDropdown>
        }
        {!loggedIn && !onSignupPage &&
          <TopNavPageTab {...{icon: 'donate', size, name: 'Signup', href: location.toSignup()}}/>
        }
      </Fragment>
      : <Fragment>
        {loggedIn &&
          <TopNavPageTab {...{icon: 'my-feed', size, name: 'My Feed', href: '/'}}/>
        }
        <TopNavPageTab {...{
          icon: 'hubs', size, name: PNFO.plural,
          href: `/${PNFO.plural}${loggedIn ? '' : '/all'}`
        }}/>
        {loggedIn || <LoginAndSignup {...{
          location,
          onLoginPage,
          onSignupPage,
        }}/>}
      </Fragment>

  const searchBar = <SearchBar {...{location, query: searchQuery}}/>
  const logo = <Link key="logo" href={isDataYogi ? '/DataYogi' : '/'} className="Layout-logo">
    <TruLogo size="sm"/>
  </Link>
  return <div className="Layout-TopNav">
    <Navbar className="Layout-TopNav-desktop">
      {isPWA && <PWAControls />}
      {logo}
      {searchBar}
      <OnlineStatus />
      {buttons('lg')}
      {currentUser && !isDataYogi && <ChatNotificationsButton />}
      {currentUser && <CurrentUser {...currentUser}/>}
      {currentUser && <NotificationsButton/>}
    </Navbar>
    <Navbar className="Layout-TopNav-mobile">
      {isPWA && <PWAControls />}
      {logo}
      {searchBar}
      <OnlineStatus />
      {currentUser && <CurrentUser {...currentUser}/>}
    </Navbar>
    <Navbar className="Layout-TopNav-mobile">
      {buttons('lg')}
      {currentUser && !isDataYogi && <ChatNotificationsButton />}
      {currentUser && <NotificationsButton/>}
    </Navbar>
  </div>
}

function OnlineStatus(){
  const online = useOnlineStatus()
  if (online) return
  return <Alert type="error"><b>OFFLINE</b></Alert>
}

function LoginAndSignup({
  size,
  location,
  onLoginPage,
  onSignupPage,
}){
  return <Fragment>
    <TopNavPageTab {...{icon: 'login', size, name: 'Login', href: location.toLogin()}}/>
    {!onLoginPage && !onSignupPage &&
      <LoginDropdown {...{location}}>
        <PageTab className="Layout-TopNav-LoginDropdown" href={location.toLogin()}>
          <Icon type="login" size={size} />
          <span>&nbsp;&nbsp;Login</span>
        </PageTab>
      </LoginDropdown>
    }
    {!onLoginPage && !onSignupPage &&
      <TopNavPageTab {...{icon: 'user-add', size, name: 'Signup', href: location.toSignup()}}/>
    }
  </Fragment>
}

function SearchBar({location, query}){
  const pageChangeDebounceTimeoutRef = useRef()

  const value = query ? decodeURIComponent(query) : ''

  const whereWeCameFromRef = useRef()
  useEffect(
    () => {
      const pathnameParts = location.pathname.split('/')
      const onSearchPage = pathnameParts[1] === 'search'
      if (!onSearchPage) whereWeCameFromRef.current = location.pathname
    },
    [location.pathname],
  )

  const onClear = useCallback(
    () => {
      history.visit(whereWeCameFromRef.current || `/${PNFO.plural}/all`)
    },
    [],
  )

  const onInput = useCallback(
    query => {
      if (pageChangeDebounceTimeoutRef.current)
        clearTimeout(pageChangeDebounceTimeoutRef.current)
      pageChangeDebounceTimeoutRef.current = setTimeout(
        () => {
          if (query) {
            history.pushState(
              null,
              `search - ${query}`,
              `/search/${encodeURIComponent(query)}`
            )
          }else{
            onClear()
          }
        },
        500
      )
    },
    [location.pathname]
  )
  return <SearchInput
    {...{value, onInput, onClear}}
    className="Layout-SearchBar"
    placeholder="Search…"
  />
}

function CurrentUser(){
  const { myPublicProfile } = useMyPublicProfile('Layout.CurrentUser')

  if (!myPublicProfile) return null

  const dropdownOptions = [
    {value: 'Profile', href: `${publicProfileToPathname(myPublicProfile)}${isDataYogi ? '/about' : ''}`},
    {value: `Create ${PNFO.singular}`, href: `/${PNFO.singular}/new`},
    {value: 'Settings', href: '/settings'},
  ]
  if (!isDataYogi) dropdownOptions.push({value: 'My Data',   href: '/my-defaults'})
  dropdownOptions.push(
    '--',
    {value: 'Logout', onSelect: logout},
  )

  return <DropdownMenu
    className="Layout-CurrentUser-Menu"
    type="menu"
    options={dropdownOptions}
    rightAligned
  >
    <LinkToPublicProfile
      publicProfile={myPublicProfile}
      className="Layout-CurrentUser-avatar"
    >
      <EndUserAvatar {...{ publicProfile: myPublicProfile, size: 'sm' }} />
    </LinkToPublicProfile>
  </DropdownMenu>
}

const PWA_TOOL_TIPS = {
  back: (
    'back' +
    (isMobile ? '' : ` ${metaKeyCharacter}[`)
  ),
  forward: (
    'forward' +
    (isMobile ? '' : ` ${metaKeyCharacter}]`)
  ),
  reload: (
    'reload' +
    (isMobile ? '' : ` ${metaKeyCharacter}R`)
  ),
}

const PWAControls = () =>
  <Fragment>
    <Tooltip {...{
      text: PWA_TOOL_TIPS.back,
      key: 'back',
    }}>
      <IconButton {...{
        className: 'Layout-backButton',
        type: 'left-open',
        disabled: !history.canGoBack,
        onClick: () => history.back(),
      }}/>
    </Tooltip>
    <Tooltip {...{
      text: PWA_TOOL_TIPS.forward,
      key: 'forward',
    }}>
      <IconButton {...{
        className: 'Layout-forwardButton',
        disabled: !history.canGoForward,
        type: 'right-open',
        onClick: () => history.forward(),
      }}/>
    </Tooltip>
    <Tooltip {...{
      text: PWA_TOOL_TIPS.reload,
      key: 'reload',
    }}>
      <IconButton {...{
        className: 'Layout-reloadButton',
        type: 'history',
        onClick: () => history.reload(),
      }}/>
    </Tooltip>
  </Fragment>


function LoginDropdown({ children, location }){
  const buttonRef = useRef()
  const [isOpen, open, close] = useToggle(false)
  const onClick = useCallback(
    event => {
      if (event.altKey || event.shiftKey || event.ctrlKey || event.metaKey) return
      event.preventDefault()
      open()
    },
    [open]
  )
  const button = cloneElement(children, {
    ref: buttonRef,
    onClick,
  })
  return <Fragment>
    {button}
    <EndUserLoginDropdown rightAligned {...{
      open: isOpen,
      onClose: close,
      anchorRef: buttonRef,
      location,
    }}/>
  </Fragment>
}
