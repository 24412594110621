import { h } from 'preact'
import { useEffect, useRef } from 'preact/hooks'
import PropTypes from 'prop-types'

import { useAppState } from 'lib/appState'
import humanizeNumber from 'lib/humanizeNumber'

import Button from 'components/Button'
import Icon from 'components/Icon'
import './index.sass'

export default function VoteArrow({ up = false, disabled, post, voting }){
  const { takeAction } = useAppState(undefined, 'VoteArrow')
  const activeValue = up ? 1 : -1
  let active = post.myVote === activeValue
  let count = (up ? post.upvoteCount : post.downvoteCount) || 0

  if (voting){
    if (active) {
      active = false
      count--
    }else if (voting.vote === activeValue){
      active = true
      count++
    }
  }

  let className = 'VoteArrow'
  if (active) className += ' VoteArrow-active'

  const firstRenderRef = useRef(true)
  const arrowRef = useRef()
  useEffect(
    () => {
      if (firstRenderRef.current){
        firstRenderRef.current = false; return
      }
      if (!active) return
      const className = `VoteArrow-arrowBounce${up ? 'Up' : 'Down'}`
      const element = arrowRef.current.base
      element.classList.add(className)
      const removeClassName = () => {
        element.classList.remove(className)
      }
      const timeout = setTimeout(removeClassName, 1000)
      return () => { clearTimeout(timeout); removeClassName() }
    },
    [active]
  )

  return <Button {...{
    type: 'none',
    className,
    disabled,
    onClick: () => {
      // if (disabled || voting) return
      takeAction('feedPosts.voteOnPost', {
        feedPostUid: post.uid,
        vote: active ? 0 : activeValue
      })
    }
  }}>
    <Icon ref={arrowRef} type={up ? 'up-bold' : 'down-bold'}/>
    <span>{humanizeNumber(count)}</span>
  </Button>
}

const votePropType = PropTypes.oneOf([-1, 0, 1])
VoteArrow.propTypes = {
  className: PropTypes.string,
  up: PropTypes.bool,
  disabled: PropTypes.bool,
  post: PropTypes.shape({
    uid: PropTypes.string.isRequired,
    myVote: votePropType,
    upvoteCount: PropTypes.number,
    downvoteCount: PropTypes.number,
  }).isRequired,
  voting: PropTypes.shape({
    vote: votePropType.isRequired,
  })
}

