import PropTypes from 'prop-types'

export const invitePropType = PropTypes.shape({
  inviteToken: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  isForExistingUser: PropTypes.bool.isRequired,
  organizationApikey: PropTypes.string.isRequired,
  organization: PropTypes.shape({
    domain: PropTypes.string,
    description: PropTypes.string,
    name: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
})
