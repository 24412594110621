import dismissError from 'lib/dismissError'

const DEFAULT_TIMEOUT = 5000

let alertSequence = 1
export function alert(alert){
  if (typeof alert !== 'object')
    alert = { type: 'warning', message: `${alert}`}
  alert.id = alertSequence++
  let { alerts = [] } = this.getState()
  alerts = [...alerts, alert]
  this.setState({ alerts })
  const timeout = 'timeout' in alert ? alert.timeout : DEFAULT_TIMEOUT
  if (timeout > 0) setTimeout(
    () => { this.takeAction('ui.dismissAlert', alert) },
    timeout
  )
}

export function dismissAlert(alert){
  let { alerts = [] } = this.getState()
  alerts = alerts.filter(a => a !== alert)
  this.setState({ alerts })
}

export { dismissError }
