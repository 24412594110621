import { h } from 'preact'

import { useRedirect } from 'resources/location'
import ContentBox from 'components/ContentBox'
import NetworkNavigator from 'components/NetworkNavigator'
import './index.sass'

export default function OrganizationNetworkPage(props){
  const {
    params,
    organizationApikey,
    organization,
  } = props

  const trail = params.path ? params.path.split('/') : []

  useRedirect({
    to: `/${organization.apikey}`,
    if: !organization.is_network,
    replace: true,
  })
  if (!organization.is_network) return

  return <div className="OrganizationNetworkPage">
    <ContentBox>
      <NetworkNavigator {...{ organizationApikey, trail }}/>
    </ContentBox>
  </div>
}

OrganizationNetworkPage.pageTitle = 'Network'
OrganizationNetworkPage.onAuthorization = ({ organization, isMember, loggedIn }) =>
  organization.is_private ? isMember : loggedIn
