import { h } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

import { useMyOrganizationFeedSubscriptions } from 'lib/feedSubscriptionHooks'
import { setPageTitle } from 'lib/Page'
import { useLocalStorageToggle } from 'lib/storageHooks'

import Loading from 'components/Loading'
import TwoColumns from 'components/TwoColumns'
import Link from 'components/Link'
import Icon from 'components/Icon'
import Header from 'components/Header'
import ContentBox from 'components/ContentBox'
import Navbar from 'components/Navbar'
import OrganizationFeedPosts from 'components/OrganizationFeedPosts'
import OrganizationsIFollowList from 'components/OrganizationsIFollowList'
import HeaderedContentBox from 'components/HeaderedContentBox'
import RedirectPage from '../RedirectPage'
import './index.sass'

export default function HomePage(){
  setPageTitle('Home')

  const { myOrganizationFeedSubscriptions } = useMyOrganizationFeedSubscriptions('HomePage')

  if (isDataYogi) return <RedirectPage className="HomePage" to="/DataYogi" />

  return <TwoColumns className="HomePage">
    <div>
      <ContentBox className="HomePage-nav">
        <Navbar>
          <Header size="lg">
            <Icon type="my-feed" size="lg" blue/>
            My Feed
          </Header>
          <Link type="subtle" href={`/${PNFO.plural}/subscriber`}>{PNFO.plural} you subscribe to</Link>
        </Navbar>
      </ContentBox>
      {Array.isArray(myOrganizationFeedSubscriptions)
        ? myOrganizationFeedSubscriptions.length === 0
          ? <p className="HomePage-noOrgs">You do not yet subscribe to any {PNFO.plural}.</p>
          : <OrganizationFeedPosts feed="homepage:feed" usersFeed />
        : <Loading type="block"/>
      }
    </div>
    <FollowingContentBox />
  </TwoColumns>
}

function FollowingContentBox(){
  const [collapsed, , , toggle] = useLocalStorageToggle('HomePage-OrganizationsIFollowList', true)

  return <HeaderedContentBox
    header={`${PNFO.plural} I subscribe to`}
    icon="sub-to"
    collapsable
    collapsedByDefault
    collapsed={collapsed}
    toggle={toggle}
  >
    <OrganizationsIFollowList
      showMembershipPills
    />
  </HeaderedContentBox>
}
