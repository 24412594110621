import { h } from 'preact'
import { useState } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { isInvalidPassword } from 'lib/validators'

import { useSignup, signUp } from 'resources/auth'

import Form from 'components/Form'
import Link from 'components/Link'
import Header from 'components/Header'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import Spinner from 'components/Spinner'
import APortalAuthForm from 'components/APortalAuthForm'
import TextInput from 'components/TextInput'
import UsernameInput from 'components/UsernameInput'
import PhoneInput from 'components/PhoneInput'
import './index.sass'

export default function APortalSignupForm({
  className,
  disabled,
  source = 'portalsSignupPage',
  organizationInviteToken,
  organization,
  destinationPath,
}){
  const joinOrganization = organization && organization.apikey

  const {
    login,
    setLogin,
    username,
    setUsername,
    setSignUpError,
    signingUp,
  } = useSignup()

  const [optedForEmail, setOptedForEmail] = useState(
    login && login.includes('@')
  )

  const toggleOptedForEmail = () => {
    setOptedForEmail(!optedForEmail)
    setLogin('')
  }

  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [showPassword, setShowPassword] = useState(false)

  if (signingUp) disabled = true

  return <APortalAuthForm
    className={classNames('APortalSignupForm', { className })}
  >
    <IntroContent {...{ organization, invited: !!organizationInviteToken }}/>
    <Form {...{
      disabled,
      onSubmit(){
        const value = {
          username,
          [optedForEmail ? 'email' : 'mobile']: login,
          password,
        }
        const error = validate({
          optedForEmail,
          passwordConfirmation,
          ...value
        })
        if (error) setSignUpError(error)
        else signUp({
          ...value,
          source,
          joinOrganization,
          organizationInviteToken,
        })
      },
    }}>
      <Form.Row>
        <Form.Item>
          <Form.Label>USERNAME</Form.Label>
          <UsernameInput {...{
            value: username,
            onInput: setUsername,
            name: 'username',
            autocomplete: 'username',
            required: true,
            placeholder: 'PartyPerson',
            lpignore: false,
          }}/>
        </Form.Item>
      </Form.Row>
      <Form.Row>
        <Form.Item className="APortalSignupForm-emailOrMobile">
          <Button type="link" className="APortalSignupForm-toggleOptedForEmail" onClick={toggleOptedForEmail}>
            <small>{`use ${optedForEmail ? 'mobile phone' : 'email'} instead`}</small>
          </Button>
          <Form.Label>
            {optedForEmail ? 'EMAIL' : 'MOBILE PHONE'}
          </Form.Label>
          {optedForEmail
            ? <TextInput {...{
              value: login,
              onInput: setLogin,
              name: 'email',
              autocomplete: 'email',
              required: true,
              placeholder: 'you@example.com',
              lpignore: false,
            }}/>
            : <PhoneInput {...{
              value: login,
              onInput: setLogin,
              name: 'mobile',
              autocomplete: 'tel',
              required: true,
              lpignore: false,
            }}/>
          }
        </Form.Item>
      </Form.Row>
      <APortalAuthForm.Passwords {...{
        disabled,
        password,
        setPassword,
        passwordConfirmation,
        setPasswordConfirmation,
        showPassword,
        setShowPassword,
      }}/>
      <Form.Row>
        <Button submit fat block {...{
          disabled,
          type: 'primary',
          value: (signingUp
            ? <span><Spinner />&nbsp;Signing Up…</span>
            : 'Sign up' + (organization ? ` and join ${organization.name}` : '')
          ),
        }}/>
      </Form.Row>
    </Form>
    <APortalAuthForm.Footer>
      <span>Already have an account? </span>
      <Link {...{
        type: 'text',
        pathname: '/login',
        query: {r: destinationPath, o: organization && organization.apikey}
      }}>Login</Link>.
    </APortalAuthForm.Footer>
  </APortalAuthForm>
}

APortalSignupForm.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  destinationPath: PropTypes.string,
  organizationInviteToken: PropTypes.string,
  organization: PropTypes.shape({
    apikey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    users_can_request_membership: PropTypes.bool.isRequired,
  }),
}

function IntroContent({ organization, invited }) {
  // const { organization = {} } = useOrganization(joinOrganizationApikey, 'APortalSignupForm')
  // const { invitedToOrganization } = useInvitedToOrganization(joinOrganizationApikey)
  return <APortalAuthForm.Intro>
    <APortalAuthForm.Logo {...{organization}}/>
    {organization &&
      <Header size="xl">
        {`${invited ? 'You have been invite to join' : 'Join'} ${organization.name}`}
      </Header>
    }
    <p className="APortalSignupForm-introText">
      {`Create a free account on ${APP_NAME}`}
      {organization && ' to join'}
    </p>
  </APortalAuthForm.Intro>
}

function validate({ optedForEmail, username, email, mobile, password, passwordConfirmation }){
  console.log('validate', { optedForEmail, username, email, mobile, password, passwordConfirmation })
  if (!username) return 'username is required'
  if (optedForEmail && !email) return 'email is required'
  if (!optedForEmail && !mobile) return 'mobile is required'
  if (!password) return 'password is required'

  const badUsername = UsernameInput.isInvalid(username)
  if (badUsername) return `Invalid username: ${badUsername}`
  const invalidPassword = isInvalidPassword(password)
  if (invalidPassword) return `Invalid password: ${invalidPassword}`
  if (password !== passwordConfirmation) return 'passwords do not match'
}

