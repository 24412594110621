import { h } from 'preact'
import PropTypes from 'prop-types'

import isMobile from 'lib/isMobile'
import classNames from 'lib/classNames'

import HeaderWithButtons from 'components/HeaderWithButtons'
import ContextMenu from 'components/ContextMenu'
import StyleishModal from 'components/StyleishModal'
import NotificationsList from 'components/NotificationsList'
import IconButton from 'components/IconButton'
import './index.sass'

export default function NotificationsModal(props){
  return isMobile ? <Modal {...props}/> : <Menu {...props}/>
}

NotificationsModal.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  menuRef: PropTypes.object,
  anchorRef: PropTypes.object.isRequired,
  rightAligned: PropTypes.bool,
}

function Menu({
  className,
  open,
  onClose,
  menuRef,
  anchorRef,
  rightAligned,
  ...props
}){
  return <ContextMenu {...{
    className: classNames('NotificationsModal-Menu', { className }),
    menuRef,
    open,
    onClose,
    anchorRef,
    rightAligned,
    ...props
  }}>
    <HeaderWithButtons {...{
      size: 'md',
      underlined: true,
      buttons: [
        <IconButton {...{
          type: 'cog',
          href: '/settings',
          onClick: onClose,
        }}/>
      ]
    }}>
      Notifications
    </HeaderWithButtons>
    <NotificationsList onNotificationClick={onClose} />
  </ContextMenu>
}

function Modal({ className, open, onClose }){
  return <StyleishModal scrollInternally {...{
    className: classNames('NotificationsModal-Modal', { className }),
    title: 'Notifications',
    open,
    onClose,
    padded: false,
  }}>
    <NotificationsList onNotificationClick={onClose} />
  </StyleishModal>
}
