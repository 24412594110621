import { h, Fragment } from 'preact'
import { useRef, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import { cloneElementAndAddClassName } from 'lib/preactHelpers'
import classNames from 'lib/classNames'
import useOnScroll from 'lib/useOnScrollHook'

import Navbar from 'components/Navbar'
import PageTab from 'components/PageTab'
import Icon from 'components/Icon'
import ContentBox from 'components/ContentBox'
import './index.sass'

export default function BannerNav({
  banner,
  name,
  icon,
  navButtons,
  extraButtons,
}){
  navButtons = <NavButtons {...{navButtons}}/>
  // this component does not render wrapped in a div because
  // DesktopNav needs to be up one level for position: sticky
  // to work
  return <Fragment>
    {cloneElementAndAddClassName(banner, 'BannerNav-Banner')}
    <MobileNav {...{key: 'MobileNav', icon, navButtons}}/>
    <DesktopNav {...{
      key: 'DesktopNav',
      name, icon, navButtons, extraButtons,
    }}/>
  </Fragment>
}

BannerNav.propTypes = {
  banner: PropTypes.node.isRequired,
  bannerChildren: PropTypes.node,
  name: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
  navButtons: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      icon: Icon.propTypes.type.isRequired,
      href: PropTypes.string.isRequired,
      tooltip: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ).isRequired,
  extraButtons: PropTypes.node,
}

BannerNav.defaultProps = {
  adminOptions: [],
}

const NavButtons = function NavButtons({navButtons}){
  return <Fragment>
    {navButtons.map(({value, icon, className, ...props}) =>
      <PageTab {...{
        ...props,
        key: value,
        className: classNames('BannerNav-NavButton', {className}),
      }}>
        <div>
          <Icon type={icon} size="lg"/>
          <div>{value}</div>
        </div>
      </PageTab>
    )}
  </Fragment>
}

function DesktopNav({name, icon, navButtons, extraButtons}){
  const rootRef = useRef()
  const iconRef = useRef()
  useOnScroll(rootRef, () => {
    const nav = rootRef.current.base
    const icon = iconRef.current
    const iconStyle = global.getComputedStyle(icon)
    const navStyle = global.getComputedStyle(nav)
    const { y } = nav.getBoundingClientRect()
    const top = parseInt(navStyle.top, 10)
    const minSize = parseInt(iconStyle.getPropertyValue('--min-size'), 10)
    const maxSize = parseInt(iconStyle.getPropertyValue('--max-size'), 10)
    const paddingTop = parseInt(iconStyle.paddingTop, 10)
    const paddingBottom = parseInt(iconStyle.paddingBottom, 10)
    let newSize = minSize + y - paddingTop - paddingBottom - top
    if (newSize < minSize) newSize = minSize
    else if (newSize > maxSize) newSize = maxSize
    let scale = (newSize - minSize) / (maxSize - minSize)
    icon.style.setProperty('--scale', `${scale}`)
  })

  return <Navbar ref={rootRef} className="BannerNav-DesktopNav">
    <div ref={iconRef} className="BannerNav-DesktopNav-icon">{icon}</div>
    <div className="BannerNav-DesktopNav-name">{name}</div>
    {navButtons}
    <Navbar.Grow/>
    {extraButtons &&
      <div className="BannerNav-DesktopNav-extraButtons">
        {extraButtons}
      </div>
    }
  </Navbar>
}

function MobileNav({navButtons}){
  const rootRef = useRef()

  useEffect(
    () => {
      const layoutFooter = global.document.body.querySelector('.Layout-Footer')
      const adjustFooterAndHelpIcon = () => {
        const rootNode = rootRef.current.base
        const height = global.getComputedStyle(rootNode).getPropertyValue('--height')
        if (layoutFooter) layoutFooter.style.marginBottom = height
      }
      adjustFooterAndHelpIcon()
      window.addEventListener('resize', adjustFooterAndHelpIcon)
      return () => {
        if (layoutFooter) layoutFooter.style.marginBottom = null
        window.removeEventListener('resize', adjustFooterAndHelpIcon)
      }
    },
    []
  )

  return <ContentBox ref={rootRef} className="BannerNav-MobileNav">
    {navButtons}
  </ContentBox>
}
