import { h, Component, Fragment } from 'preact'
import PropTypes from 'prop-types'
import { bindToAppState } from 'lib/appState'
import { invitePropType } from 'lib/invite'

import Link from 'components/Link'
import Form from 'components/Form'
import FormBuilder from 'components/FormBuilder'
import Alert from 'components/Alert'
import Header from 'components/Header'
import Spinner from 'components/Spinner'
import Checkbox from 'components/Checkbox'
import ErrorMessage from 'components/ErrorMessage'
import OrganizationIcon from 'components/OrganizationIcon'
import RequestPasswordResetForm from 'components/RequestPasswordResetForm'
import MobilePhoneVerificationForm from 'components/MobilePhoneVerificationForm'
import TruT from 'components/TruT'

import './index.sass'

class AliceLoginForm extends Component {

  static propTypes = {
    invite: invitePropType,
    value: PropTypes.shape({
      login: PropTypes.string,
      password: PropTypes.string,
      showPassword: PropTypes.bool,
      keepMeLoggedIn: PropTypes.bool,
    }),
    onChange: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,

    loginError: ErrorMessage.propTypes.error,
    loadingDataForInviteTokenError: ErrorMessage.propTypes.error,
    inviteInvalid: PropTypes.object,

    message: PropTypes.string,
    loginVerificationRequired: PropTypes.string,
    mobilePhoneVerificationRequired: PropTypes.bool,
    verifyExternalIdentity: PropTypes.func.isRequired,
    verifyingExternalIdentity: PropTypes.bool,
    errorVerifyingExternalIdentity: ErrorMessage.propTypes.error,
    requestPasswordReset: PropTypes.func.isRequired,
    passwordResetRequest: RequestPasswordResetForm.propTypes.passwordResetRequest,
    deletePasswordResetRequest: PropTypes.func.isRequired,
    loggingIn: PropTypes.bool,
    disabled: PropTypes.bool,
    signupLinkHref: PropTypes.string.isRequired,
  }

  componentDidMount(){
    setTimeout(() => { this.focusFirstEmptyInput() }, 20)
  }

  componentWillReceiveProps(nextProps){
    if (!this.props.loginError && nextProps.loginError){
      this.focusAfterError = true
    }
  }

  componentDidUpdate(){
    if (this.focusAfterError){
      delete this.focusAfterError
      const input = this.base.querySelector('input[type=text]')
      if (input) input.focus()
    }
  }

  state = {
    requestPasswordResetFormVisible: false,
    showPassword: false,
  }

  focusFirstEmptyInput(){
    if (!this.base) return
    const inputs = Array.from(this.base.querySelectorAll('input[type="text"]'))
    if (
      document.activeElement &&
      inputs.some(input => document.activeElement === input)
    ) return
    if (inputs[0]) inputs[0].focus()
  }

  setShowPassword = showPassword => {
    this.setState({ showPassword })
  }

  change(value){
    this.props.onChange({ ...this.props.value, ...value })
  }

  showResetPasswordForm = () => {
    this.setState({ requestPasswordResetFormVisible: true })
  }

  hideResetPasswordForm = () => {
    this.setState({ requestPasswordResetFormVisible: false })
  }

  render(){
    const {
      invite,
      value = {},
      onChange,
      onSubmit,

      loginError,
      loadingDataForInviteTokenError,
      inviteInvalid,

      message,
      loginVerificationRequired,
      mobilePhoneVerificationRequired,
      verifyExternalIdentity,
      verifyingExternalIdentity,
      errorVerifyingExternalIdentity,
      passwordResetRequest,
      deletePasswordResetRequest,
      requestPasswordReset,
      loggingIn,
      disabled,
      signupLinkHref,
    } = this.props

    const renderMobilePhoneVerificationForm = () =>
      <div>
        <div className="AliceLoginForm-logo">
          <TruT size="sm"/>
        </div>
        <MobilePhoneVerificationForm
          submitting={!!verifyingExternalIdentity}
          error={errorVerifyingExternalIdentity}
          onSubmit={verificationCode => {
            verifyExternalIdentity({ verificationCode })
          }}
        />
      </div>

    let content
    if (loginVerificationRequired)
      content = (
        loginVerificationRequired.includes('@')
          ? <div className="AliceLoginForm-emailVerificationRequired">
            <Header size="lg" centered>Email verification required</Header>
            <p>This email address has not been verified.</p>
            <p>
              We've sent you a message with a verification link.
              Please continue by clicking that link.
            </p>
            <p>
              <Link href="/" forcePageReload>Start over</Link>
            </p>
          </div>
          : renderMobilePhoneVerificationForm()
      )

    else if (
      mobilePhoneVerificationRequired ||
      passwordResetRequest &&
      passwordResetRequest.mobilePhoneVerificationRequired
    )
      content = renderMobilePhoneVerificationForm()

    else
      content = (
        this.state.requestPasswordResetFormVisible
          ? <RequestPasswordResetForm
            placeholder="Email or mobile"
            login={value.login}
            onInput={login => { this.change({ login }) }}
            goBack={this.hideResetPasswordForm}
            onSubmit={requestPasswordReset}
            passwordResetRequest={passwordResetRequest}
            reset={deletePasswordResetRequest}
          />
          : <LoginForm
            invite={invite}
            value={value}
            onChange={onChange}
            onSubmit={onSubmit}
            loginError={loginError}
            loadingDataForInviteTokenError={loadingDataForInviteTokenError}
            inviteInvalid={inviteInvalid}
            message={message}
            submitting={loggingIn}
            disabled={disabled}
            showResetPasswordForm={this.showResetPasswordForm}
            showPassword={this.state.showPassword}
            setShowPassword={this.setShowPassword}
            signupLinkHref={signupLinkHref}
          />
      )

    return <div className="AliceLoginForm">{content}</div>
  }
}

const LoginForm = ({
  invite,
  value,
  onChange,
  onSubmit,
  loginError,
  loadingDataForInviteTokenError,
  inviteInvalid,
  message,
  submitting,
  onReset,
  disabled,
  showResetPasswordForm,
  showPassword,
  setShowPassword,
  signupLinkHref,
}) => {

  const inviteMessage = invite && invite.organization && invite.isForExistingUser
    ? <div className="AliceLoginForm-organizationInvite">
      <Header size="lg">{invite.organization.name}</Header>
      { invite.organization.domain &&
        <Link type="text" newWindow href={`${invite.organization.domain}`}>{invite.organization.domain}</Link>
      }
      <OrganizationIcon organization={invite.organization} size="lg" />
      <p>has invited you to connect</p>
    </div>
    : null

  return <Fragment>
    <div>
      { inviteMessage
        ? inviteMessage
        : <div className="AliceLoginForm-logo"><TruT /></div>
      }
      {message && <Alert type="success">{message}</Alert>}
      <FormBuilder
        error={
          loginError ||
          loadingDataForInviteTokenError ||
          (inviteInvalid && inviteInvalid.error)
        }
        value={value}
        disabled={disabled}
        submitting={submitting}
        onChange={onChange}
        onReset={onReset}
        onSubmit={onSubmit}
        errorDismissable={!!(loginError || loadingDataForInviteTokenError)}
        render={form =>
          <div>
            {form.textItem({
              valueProp: 'login',
              name: 'username',
              autocomplete: 'username email tel',
              required: true,
              placeholder: 'Mobile',
              lpignore: false,
            })}
            {form.textItem({
              bindTo: 'onInput',
              type: showPassword ? 'text' : 'password',
              name: 'password',
              className:'AliceLoginForm-passwordFormItem',
              valueProp: 'password',
              autocomplete: 'current-password',
              required: true,
              label: false,
              placeholder: 'Password',
              lpignore: false,
            })}
            <Form.Item className="AliceLoginForm-showPassword">
              <Checkbox
                checked={showPassword}
                onChange={setShowPassword}
                label="Show password"
                disabled={disabled || submitting}
              />
            </Form.Item>
            <Form.Item>
              {form.bindInput({
                valueProp: 'keepMeLoggedIn',
                input: <Checkbox label="Keep me logged in" />,
              })}
            </Form.Item>
            <div className="AliceLoginForm-submitButton">
              {form.submitButton({
                value: 'Login',
                submittingValue: <span><Spinner />&nbsp;Logging in…</span>,
                block: true,
                fat: true,
              })}
            </div>
          </div>
        }
      />
    </div>
    <div className="AliceLoginForm-forgotPassword">
      <Link type="text" href={signupLinkHref}>sign up</Link>
      <Link type="text" onClick={showResetPasswordForm}>
        forgot password?
      </Link>
    </div>
  </Fragment>
}

export default bindToAppState(
  [
    'invite',
    'signupAndLoginValues',
    'loggingIn',
    'loginError',
    'loginMessage',
    'loginVerificationRequired',
    'mobilePhoneVerificationRequired',
    'passwordResetRequest',
    'loadingDataForInviteTokenError',
    'inviteInvalid',
    'verifyingExternalIdentity',
    'errorVerifyingExternalIdentity',
  ],
  ({takeAction, appAction, ...props}) =>
    // TODO rename the props AliceLoginForm uses to be the same
    // as the appState keys so we can pass AliceLoginForm directly
    // to bindToAppState
    <AliceLoginForm {...{
      ...props,
      value: props.signupAndLoginValues,
      onChange: value => {
        if (props.inviteInvalid) takeAction('session.clearInvite')
        return takeAction('session.setSignupAndLoginValues', value)
      },
      onSubmit: appAction('session.login', {}),
      message: props.loginMessage,
      loginVerificationRequired: props.loginVerificationRequired,
      mobilePhoneVerificationRequired: props.mobilePhoneVerificationRequired,
      verifyExternalIdentity: appAction('externalIdentities.verify'),
      verifyingExternalIdentity: props.verifyingExternalIdentity,
      errorVerifyingExternalIdentity: props.errorVerifyingExternalIdentity,
      disabled: props.loggingIn,
      requestPasswordReset: appAction('password.requestReset'),
      passwordResetRequest: props.passwordResetRequest,
      deletePasswordResetRequest: appAction('password.deleteResetRequest'),
    }}/>
)
