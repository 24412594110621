import { h } from 'preact'
import { useEffect, useCallback, useReducer } from 'preact/hooks'
import { useAppState } from 'lib/appState'
import useLoadedEntity from 'lib/useLoadedEntityHook'

export function useOrganizationFeedPostUids(organizationApikeys, componentName){
  const appState = useAppState(
    organizationApikeys.map(organizationApikey =>
      `organization:${organizationApikey}:feed:postUids`
    ),
    componentName,
  )

  let feedPostUids = new Set()
  organizationApikeys.map(organizationApikey => {
    const orgPostUids = appState[`organization:${organizationApikey}:feed:postUids`]
    if (!orgPostUids) return
    ;[...orgPostUids].forEach(postUid => { feedPostUids.add(postUid) })
  })

  feedPostUids = [...feedPostUids]

  return { feedPostUids }
}

export function useOrganizationFeedPosts(feedPostUids, componentName){
  const appState = useAppState(
    feedPostUids.map(postUid => `organizationFeedPost:${postUid}`),
    componentName
  )
  const posts = []
  feedPostUids.forEach(postUid => {
    const appStateKey = `organizationFeedPost:${postUid}`
    const post = appState[appStateKey]
    if (!post) {
      // TODO make this a general helper
      console.warn(`appState key "${appStateKey}" expected but not found`)
      return
    }
    posts.push(post)
  })
  return { posts }
}

export function useOrganizationFeedPost(feedPostUid, componentName){
  return useLoadedEntity({
    entityName: 'feedPost',
    entityKey: `organizationFeedPost:${feedPostUid}`,
    loadEntity: takeAction => {
      if (feedPostUid) takeAction('feedPosts.loadPost', feedPostUid)
    },
    componentName,
  })
}

export function useOrganizationFeedPostProvenance(feedPostUid, componentName){
  return useLoadedEntity({
    entityName: 'feedPostProvenance',
    entityKey: `organizationFeedPost:${feedPostUid}:provenanceChain`,
    loadEntity: takeAction => {
      if (feedPostUid) takeAction('feedPosts.loadProvenance', feedPostUid)
    },
    componentName,
  })
}

export function useOrganizationFeedPostComments(feedPostUid, componentName){
  const {
    takeAction,
    comments,
  } = useAppState(
    {[`organizationFeedPost:${feedPostUid}:comments`]: 'comments'},
    componentName,
  )

  const loadMoreFeedPostComments = before => {
    takeAction('feedPosts.loadComments', {feedPostUid, before})
  }

  useEffect(
    () => {
      if (!comments) loadMoreFeedPostComments()
    },
    [comments]
  )

  const {
    loading: feedPostCommentsLoading,
    loadingError: feedPostCommentsLoadingError,
    notFound: feedPostCommentsNotFound,
    fullyLoaded: feedPostCommentsFullyLoaded,
    ...feedPostCommentsByUid
  } = comments || {}

  const feedPostComments = Object.values(feedPostCommentsByUid)
    .filter(comment => typeof comment === 'object')

  return {
    feedPostComments,
    feedPostCommentsLoading,
    feedPostCommentsLoadingError,
    feedPostCommentsNotFound,
    feedPostCommentsFullyLoaded,
    loadMoreFeedPostComments,
  }
}

export function useFeed(feed, componentName){
  const {
    takeAction,
    postUids = [],
    loading,
    loadingError,
    fullyLoaded,
  } = useAppState(
    {
      [`${feed}:postUids`]: 'postUids',
      [`${feed}:loading`]: 'loading',
      [`${feed}:loading:error`]: 'loadingError',
      [`${feed}:fullyLoaded`]: 'fullyLoaded',
    },
    `${componentName}.useFeed`,
  )

  const { posts } = useOrganizationFeedPosts(postUids, componentName)

  const oldestPost = posts[posts.length - 1]
  const before = oldestPost && oldestPost.createdAt

  const loadMore = useCallback(
    () => {
      takeAction('feedPosts.loadPosts', { feed, before })
    },
    [feed, before]
  )

  const refresh = useCallback(
    () => {
      takeAction('feedPosts.loadPosts', { feed })
    },
    [feed]
  )

  useEffect(refresh, [feed])

  return {
    postUids,
    posts,
    loading,
    loadingError,
    fullyLoaded,
    loadMore,
    refresh,
  }
}

export function useCreateFeedPost({feedOrganizationApikey, feedUserDid}, componentName){
  const keyPrefix = (
    feedOrganizationApikey ? `organization:${feedOrganizationApikey}` :
    feedUserDid ? `publicProfile:${feedUserDid}` :
    false
  )

  if (!keyPrefix)
    console.error(`unable to determine app state key`, ...arguments)

  const {
    takeAction,
    creating,
    creatingError,
    createdFeedPostUid,
  } = useAppState(
    keyPrefix
      ? {
        [`${keyPrefix}:creatingFeedPost`]: 'creating',
        [`${keyPrefix}:creatingFeedPost:error`]: 'creatingError',
        [`${keyPrefix}:createdFeedPost`]: 'createdFeedPostUid',
      }
      : {}
    ,
    componentName,
  )

  const {
    feedPost: createdFeedPost,
  } = useOrganizationFeedPost(
    createdFeedPostUid,
    componentName,
  )

  const createPost = useCallback(
    keyPrefix
      ? post => {
        takeAction('feedPosts.createPost', {
          post: {...post, feedOrganizationApikey, feedUserDid}
        })
      }
      : () => {}
    ,
    [keyPrefix],
  )

  return {
    createPost,
    creating,
    creatingError,
    createdFeedPost,
  }
}

export function useUpdateFeedPost(post, componentName){
  const prefix = `organizationFeedPost:${post.uid}`
  const { takeAction, updating, updatingError } = useAppState(
    {
      [`${prefix}:updating`]: 'updating',
      [`${prefix}:updating:error`]: 'updatingError',
    },
    componentName,
  )
  const updatePost = useCallback(
    changes => takeAction('feedPosts.updatePost', { post, changes }),
    [post],
  )
  return { updatePost, updating, updatingError }
}

export function usePublishOrganizationForumFeedPost(post, componentName){
  const {
    takeAction,
    publishingOrganizationForumFeedPost,
    errorPublishingOrganizationForumFeedPost,
  } = useAppState(
    {
      [`organizationFeedPost:${post.uid}:publishing`]:
        'publishingOrganizationForumFeedPost',
      [`organizationFeedPost:${post.uid}:publishing:error`]:
         'errorPublishingOrganizationForumFeedPost',
    },
    componentName,
  )

  const publishOrganizationForumFeedPost = useCallback(
    ({ visibleTo }) =>
      takeAction('feedPosts.publishOrganizationForumFeedPost', {post, visibleTo}),
    [post],
  )

  return {
    publishOrganizationForumFeedPost,
    publishingOrganizationForumFeedPost,
    errorPublishingOrganizationForumFeedPost,
  }
}


export function useRepostFeedPost(post, componentName){
  const repostingKey = `feedPosts:reposting:${post.uid}`
  const errorKey = `${repostingKey}:error`
  const createdKey = `${repostingKey}:createdFeedPost`

  const {
    takeAction,
    repostingFeedPost,
    errorRepostingFeedPost,
    createdFeedPostUid,
  } = useAppState(
    {
      [repostingKey]: 'repostingFeedPost',
      [errorKey]: 'errorRepostingFeedPost',
      [createdKey]: 'createdFeedPostUid',
    },
    componentName,
  )

  const {
    feedPost: createdFeedPost,
  } = useOrganizationFeedPost(
    createdFeedPostUid,
    componentName,
  )

  const repostFeedPost = useCallback(
    async options =>
      takeAction('feedPosts.repost', {post, ...options})
    ,
    [post, takeAction],
  )

  return {
    repostFeedPost,
    repostingFeedPost,
    errorRepostingFeedPost,
    createdFeedPost,
  }
}

export function useCreateOrganizationFeedPostComment(feedPostUid, componentName){
  const {
    takeAction,
    creatingOrganizationFeedPostComment,
    errorCreatingOrganizationFeedPostComment,
  } = useAppState(
    {
      [`organizationFeedPostComment:${feedPostUid}:creating`]: 'creatingOrganizationFeedPostComment',
      [`organizationFeedPostComment:${feedPostUid}:creating:error`]: 'errorCreatingOrganizationFeedPostComment',
    },
    componentName,
  )

  const createOrganizationFeedPostComment = comment =>
    takeAction('feedPosts.createComment', { feedPostUid, comment })

  return {
    createOrganizationFeedPostComment,
    creatingOrganizationFeedPostComment,
    errorCreatingOrganizationFeedPostComment,
  }
}

function urlPreviewsReducer(state, { url, urlPreview }){
  return {...state, [url]: urlPreview}
}
export function useUrlPreviews(componentName){
  const [urlPreviews, addUrlPreview] = useReducer(urlPreviewsReducer, {})
  const { appAction } = useAppState(undefined, componentName)
  const getUrlPreview = appAction('feedPosts.getUrlPreview')
  const loadUrlPreview = useCallback(url => {
    if (url in urlPreviews) return
    getUrlPreview(url).then(urlPreview => {
      addUrlPreview({ url, urlPreview })
    })
  }, [])
  return { loadUrlPreview, urlPreviews }
}
