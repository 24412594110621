import api from '../api'

export default function({ organizationApikey }){
  if (!organizationApikey) throw new Error(`organizationApikey is required`)

  const { signingSisaWith, invite } = this.getState()
  if (signingSisaWith) {
    console.warn(`already signing a sisa with ${signingSisaWith}`)
    return
  }

  const inviteToken = (
    invite &&
    invite.organizationApikey === organizationApikey &&
    invite.inviteToken
  )

  this.setState({
    signingSisaWith: organizationApikey,
    errorSigningSisa: undefined,
  })

  return api.signSisa({ organizationApikey, inviteToken }).then(
    ({ sisa, organizationAccountData, organization }) => {
      const mySISAs = new Set(this.getState().mySISAs)
      mySISAs.add(organizationApikey)
      this.setState({
        mySISAs,
        signingSisaWith: undefined,
        [`sisa:${organizationApikey}`]: sisa,
        [`organizationAccountData:${organizationApikey}`]: organizationAccountData,
        [`organization:${organizationApikey}`]: organization,
      })

      if (this.getState()[`organizationAccountDataStagedChanges:${organizationApikey}`]){
        this.takeAction('commitOrganizationAccountDataStagedChanges', organizationApikey)
      }
      if (inviteToken) this.takeAction('session.clearInvite')
    },
    errorSigningSisa => {
      this.setState({
        signingSisaWith: undefined,
        errorSigningSisa,
      })
    }
  )
}
