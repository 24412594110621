import { useLocalStorage } from 'lib/storageHooks'

const localStorageKey = 'recentSpaces'

export function reportOrganizationVisit(organizationApikey){
  const organizationApikeys = getRecentOrganizations()
    .filter(member => member !== organizationApikey)

  organizationApikeys.unshift(organizationApikey)

  if (organizationApikeys.length > 10)
    organizationApikeys.length = 10

  global.localStorage[localStorageKey] = JSON.stringify(organizationApikeys)
}

export function useRecentOrganizations(){
  const [recentOrganizationApikeys = []]
    = useLocalStorage(localStorageKey)
  return {recentOrganizationApikeys}
}

export function getRecentOrganizations(){
  try{
    return JSON.parse(global.localStorage[localStorageKey] || '[]')
  }catch(error){
    console.error(error)
    return []
  }
}
