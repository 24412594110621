import { h, Component, Fragment } from 'preact'
import PropTypes from 'prop-types'

import { useAppState } from 'lib/appState'
import { useOrganization } from 'lib/membershipAppStateHooks'
import { preventFocusOut } from 'lib/DOMHelpers'
import useInvitedToOrganization from 'lib/useInvitedToOrganizationHook'

import FormBuilder from 'components/FormBuilder'
import TruT from 'components/TruT'
import Form from 'components/Form'
import Link from 'components/Link'
import Modal from 'components/Modal'
import Header from 'components/Header'
import Button from 'components/Button'
import Checkbox from 'components/Checkbox'
import PhoneAndCountryInput from 'components/PhoneAndCountryInput'
import HelpButton from 'components/HelpButton'
import OrganizationIcon from 'components/OrganizationIcon'
import MobilePhoneVerificationForm from 'components/MobilePhoneVerificationForm'
import LinkToBrandingPage from 'components/LinkToBrandingPage'
import JlincTerms from 'components/JlincTerms'

import { isInvalidPassword } from 'lib/validators'

import './index.sass'

export default function AliceSignupForm(props){
  const {
    joinOrganization,
    loginLinkHref,
    destinationPath,
  } = props

  const {
    appAction,
    takeAction,
    inviteInvalid,
    signupAndLoginValues,
    signingUp,
    signupError,
    signupSuccessful,
    verifyingExternalIdentity,
    errorVerifyingExternalIdentity,
    loginVerificationRequired,
  } = useAppState(
    [
      'inviteInvalid',
      'signupAndLoginValues',
      'signingUp',
      'signupError',
      'signupSuccessful',
      'verifyingExternalIdentity',
      'errorVerifyingExternalIdentity',
      'loginVerificationRequired',
    ],
    'AliceSignupForm',
  )

  const { organization } = useOrganization(joinOrganization, 'AliceSignupForm')

  return <div className="AliceSignupForm">
    { signupSuccessful || loginVerificationRequired
      ? <MobilePhoneVerificationForm
        submitting={!!verifyingExternalIdentity}
        error={errorVerifyingExternalIdentity}
        onSubmit={verificationCode => {
          takeAction('externalIdentities.verify', { verificationCode })
        }}
      />
      : <SignupForm {...{
        loginLinkHref,
        value: signupAndLoginValues,
        onChange: appAction('session.setSignupAndLoginValues'),
        onSubmit: () => {
          takeAction('session.signup', {destinationPath, joinOrganization})
        },
        error: (
          signupError ||
          (
            inviteInvalid &&
            inviteInvalid.organizationApikey === joinOrganization &&
            inviteInvalid.error
          )
        ),
        submitting: !!signingUp,
        signupSuccessful,
        organization,
      }}/>
    }
  </div>
}

AliceSignupForm.propTypes = {
  joinOrganization: PropTypes.string,
  loginLinkHref: PropTypes.string.isRequired,
  destinationPath: PropTypes.string.isRequired,
}

class SignupForm extends Component {
  componentDidMount() {
    this.focusFirstEmptyInput()
  }

  focusFirstEmptyInput(){
    setTimeout(() => {
      const inputs = Array.from(this.base.querySelectorAll(
        'input[name="tel"],' +
        'input[name="password"],' +
        'input[name="username"],' +
        'input[name="country"],' +
        'input[name="postal-code"]'
      ))
      if (
        document.activeElement &&
        inputs.some(input => document.activeElement === input)
      ) return
      const firstEmptyInput = inputs.find(input =>
        !input.value || input.value === '+1 '
      )
      if (firstEmptyInput) firstEmptyInput.focus()
    }, 20)
  }

  state = {
    confirmSignupModalVisible: false,
    countrySetByUser: false,
    showPassword: false,
  }

  onSubmit = () => {
    if (!this.state.confirmSignupModalVisible) {
      this.showConfirmSignupModal()
      return
    }
    this.hideConfirmSignupModal()
    this.props.onSubmit()
  }

  hideConfirmSignupModal = () => {
    this.setState({ confirmSignupModalVisible: false })
  }

  showConfirmSignupModal = () => {
    this.setState({ confirmSignupModalVisible: true })
  }

  setShowPassword = showPassword => {
    this.setState({ showPassword })
  }

  validate = value => {
    if (!value.mobile) return 'Mobile phone is required'
    if (!value.password) return 'Password is required'
    const passwordStrengthError = isInvalidPassword(value.password)
    if (passwordStrengthError) return `Password invalid: ${passwordStrengthError}`
  }

  onChange = value => {
    this.props.onChange(value)
  }

  render() {
    const {
      value = {},
      error,
      submitting,
      signupSuccessful,
      organization,
      loginLinkHref,
    } = this.props

    const {
      confirmSignupModalVisible,
      showPassword,
    } = this.state


    const disabled = submitting || signupSuccessful

    return <div>
      <IntroContent {...{organization, loginLinkHref}}/>
      { confirmSignupModalVisible &&
        <ConfirmSignupModal
          close={this.hideConfirmSignupModal}
          signup={this.onSubmit}
        />
      }
      <FormBuilder
        error={error}
        value={value}
        disabled={disabled}
        submitting={submitting}
        onChange={this.onChange}
        onReset={this.onReset}
        clearError={this.clearError}
        onSubmit={this.onSubmit}
        validator={this.validate}
        render={form =>
          <div>
            <Form.Item className="AliceSignupForm-phoneFormItem">
              {form.bindInput({
                valueProp: 'mobile',
                bindTo: 'onInput',
                input: <PhoneAndCountryInput
                  name="tel"
                  autocomplete="tel"
                  required
                  lpignore={false}
                />,
              })}
              <HelpButton>
                Your mobile number is your private login ID that is sent an SMS to verify that you are a
                human and not an evil bot. {APP_NAME} is absolutely committed to personal data control
                and will never sell, share or divuldge your number, or any other personal info.
              </HelpButton>
            </Form.Item>
            {form.textItem({
              bindTo: 'onInput',
              type: showPassword ? 'text' : 'password',
              name: 'password',
              valueProp: 'password',
              autocomplete: 'new-password',
              required: true,
              label: false,
              placeholder: 'Password',
              lpignore: false,
            })}
            <Form.Item>
              <Checkbox
                checked={showPassword}
                onChange={this.setShowPassword}
                label="Show password"
              />
            </Form.Item>
            <div className="AliceSignupForm-submitButton">
              {form.submitButton({
                value: signupSuccessful ? 'Signed Up!' : 'Sign Up',
                submittingValue: 'Signing Up…',
                fat: true,
              })}
            </div>
          </div>
        }
      />
      <div className="AliceSignupForm-bottomLinks">
        <Link type="text" href={loginLinkHref}>login</Link>
        {isDataYogi && <Link type="text" href="https://www.datayogi.me/termsofservice" newWindow>terms of service</Link>}
        <LinkToBrandingPage />
      </div>
    </div>
  }
}

function IntroContent({ organization, loginLinkHref }) {
  const { invitedToOrganization } = useInvitedToOrganization(organization && organization.apikey)

  const introText = invitedToOrganization
    ? <span>Create an account or <Link type="text" href={loginLinkHref}>login</Link> to proceed</span>
    : `Create a free account on ${APP_NAME}`

  return <div className="AliceSignupForm-IntroContent">
    {organization
      ? <OrganizationIcon size="lg" {...{organization}}/>
      : <TruT />
    }
    {organization && <Header size="xl">{organization.name}</Header>}
    <p className="AliceSignupForm-introText">{introText}</p>
  </div>
}

class ConfirmSignupModal extends Component {

  static propTypes = {
    close: PropTypes.func.isRequired,
    signup: PropTypes.func.isRequired,
  }

  componentDidMount() {
    Checkbox.focus(this.checkbox)
    this.removePreventFocusOut = preventFocusOut(this.base, { focusFirst: this.checkbox.base })
  }

  componentWillUnmount() {
    this.removePreventFocusOut()
  }

  state = { showingMoreText: false, agreeToTermsChecked: false }

  toggleMoreText = () => {
    this.setState({ showingMoreText: !this.state.showingMoreText })
  }

  onAgreeToTerms = agreeToTermsChecked => {
    this.setState({ agreeToTermsChecked })
  }

  render() {
    const { showingMoreText, agreeToTermsChecked } = this.state
    const { close, signup } = this.props

    return <Modal className="AliceSignupForm-ConfirmSignupModal" onClose={close}>
      <Header size="lg">Terms</Header>
      { isDataYogi ? <DataYogiTerms /> : <Terms /> }
      <Link type="text" onClick={this.toggleMoreText} className="AliceSignupForm-ConfirmSignupModal-moreLessButton">
        {showingMoreText ? 'LESS' : 'MORE'}
      </Link>
      { showingMoreText
        && <p className="AliceSignupForm-ConfirmSignupModal-termsText">
          {isDataYogi ? <DataYogiMoreTerms /> : <JlincTerms />}
        </p>
      }
      <Checkbox
        ref={checkbox => this.checkbox = checkbox}
        label="I am over 13 years of age and agree to the terms"
        onChange={this.onAgreeToTerms}
        value={agreeToTermsChecked}
      />
      <Button
        type="primary"
        onClick={signup}
        disabled={!agreeToTermsChecked}
      >
        Continue
      </Button>
    </Modal>
  }
}

const Terms = () => <Fragment>
  <p>
    {LEGAL_APP_NAME} provides a social media platform where you control your own data and attention.
    {APP_NAME} does not sell your data, participate in 'Adtech' targeted advertising, or use algorithms
    to manipulate your attention for advertising.
  </p>
  <p>
    I authorize {LEGAL_APP_NAME} to operate a data service for me, as my agent, where I control my own
    data, relationships, and attention.
  </p>
</Fragment>

const DataYogiTerms = () => <Fragment>
  <p>
    {APP_NAME} provides a personal data management service where you can build up your own data assets for your own
    purposes. You can then share them with organisations should you wish but retain control of that data sharing
    relationship.
  </p>
  <p>
    {APP_NAME} is an accredited MyData Operator, which is a new category of service provider emerging as the
    Internet matures. MyData Operators sign up to an accreditation process that renews and extends annually.
    This process dictates that a MyData Operators acts on your behalf around your data, and is at worst a
    neutral facilitator of data exchange.
  </p>
  <p>
    {APP_NAME} is structurally on your side, acting as your agent; the service will do nothing with your
    data without you taking a proactive step to make something happen.
  </p>
  <p>
    I authorise {APP_NAME} to operate a data service for me, as my agent, where I control my own data,
    relationships and intent
  </p>
</Fragment>

const DataYogiMoreTerms = () => <Fragment>
  <p>
    {APP_NAME} provides software under the terms below.
  </p>
  <p>
    When you sign up to create an account at app.datayogi.me, {APP_NAME} then serves you as a ‘personal data custodian
    and agent’ acting on your behalf. Your data within the system remains yours, and only yours. This includes
    the ‘meta data’, that is to say the data about your data.
  </p>
</Fragment>
