import { useEffect, useState } from 'preact/hooks'

export default function usePageHasFocus(){
  const [hasFocus, setHasFocus] = useState(global.document.hasFocus())
  useEffect(
    () => {
      function onFocus(){ setHasFocus(true) }
      function onBlur(){ setHasFocus(false) }
      window.addEventListener('focus', onFocus)
      window.addEventListener('blur', onBlur)
      return () => {
        window.removeEventListener('focus', onFocus)
        window.removeEventListener('blur', onBlur)
      }
    },
    [],
  )
  return hasFocus
}
