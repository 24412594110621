import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import TextInputWithPrefix from 'components/TextInputWithPrefix'
import './index.sass'

export default function UsernameInput({ className, onChange, ...props }){
  return <TextInputWithPrefix {...{
    ...props,
    className: classNames('UsernameInput', { className }),
    onChange: useCallback(
      (value, event) => {
        const legalValue = value.replace(/[^\w-_]/gi, '')
        event.target.value = legalValue
        if (onChange) onChange(legalValue)
      },
      [onChange]
    ),
    prefix: '@',
    pattern: '[A-Za-z0-9-_]{3,35}',
  }}/>
}

UsernameInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

UsernameInput.isInvalid = username => {

}
