import api from '../api'

export default function({ organizationApikeys, changes }){
  return api.updateOrganizationsAccountData({ organizationApikeys, changes })
    .then(({ updates, notifications }) => {
      const newState = {}
      for (const organizationApikey in updates){
        const {organizationAccountData, sisaEvents} = updates[organizationApikey]
        const currentSisaEventIds = this.getState()[`sisaEvents:${organizationApikey}`] || []
        const newSisaEventIds = [...currentSisaEventIds]
        for (const sisaEvent of sisaEvents){
          newSisaEventIds.push(sisaEvent.audit.eventId)
          newState[`sisaEvent:${sisaEvent.audit.eventId}`] = sisaEvent
        }
        newState[`organizationAccountData:${organizationApikey}`] = organizationAccountData
        newState[`sisaEvents:${organizationApikey}`] = newSisaEventIds
      }
      this.setState(newState)
      this.takeAction('notifications.addNotificationsToAppState', notifications)
    })
}
