import { h } from 'preact'

import classNames from 'lib/classNames'

import looking from './looking.svg'

export function JlinxLooking({ className, ...props }){
  return <img {...{
    ...props,
    className: 'Jlinx ' + classNames('Jlinx-Looking', { className }),
    src: looking,
  }}/>
}
