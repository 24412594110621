import { useRef, useCallback } from 'preact/hooks'

import useForceUpdate from 'lib/useForceUpdate'

export function useSet(initialValue = new Set(), args = []){
  const forceUpdate = useForceUpdate()
  const valueRef = useRef(new Set(initialValue))

  const set = new Set(valueRef.current)
  const add = useCallback(
    (...args) => {
      valueRef.current.add(...args)
      forceUpdate()
    },
    [...args]
  )
  const _delete = useCallback(
    (...args) => {
      valueRef.current.delete(...args)
      forceUpdate()
    },
    [...args]
  )
  return [set, add, _delete]
}
