import { h, Fragment } from 'preact'

import OrganizationFeedPosts from 'components/OrganizationFeedPosts'
import OrganizationAdminsList from 'components/OrganizationAdminsList'
import OrganizationFeedSubscriberList from 'components/OrganizationFeedSubscriberList'
import OrganizationFeedSubscriptionsButton from 'components/OrganizationFeedSubscriptionsButton'
import OrganizationFeedPageLayout from 'components/OrganizationFeedPageLayout'

import './index.sass'

export default function OrganizationFeedPage(props){
  const {
    organizationApikey,
    organization,
    currentUser,
    isMember,
    isAdmin,
    isCurator,
  } = props

  const subscriberList = <OrganizationFeedSubscriberList {...{
    organization,
    isAdmin,
    isCurator,
    collapsedByDefault: true,
  }}/>

  return <OrganizationFeedPageLayout {...{
    className: 'OrganizationFeedPage',
    currentUser,
    organization,
    isMember,
    icon: 'public-feed',
    name: 'Published Feed Posts',
    belowNav: subscriberList,
    navChildren: <OrganizationFeedSubscriptionsButton {...{ organization }}/>,
  }}>
    <OrganizationFeedPosts
      feed={`organization:${organizationApikey}:feed:new`}
      emptyMessage={`${organization.name} has not published any posts yet`}
      fullyLoadedMessage="There are no more published posts"
      loggedOut={!currentUser}
    />
    <Fragment>
      <OrganizationAdminsList {...{
        organization,
        isMember,
        membershipPills: false,
      }}/>
      {subscriberList}
    </Fragment>
  </OrganizationFeedPageLayout>
}
