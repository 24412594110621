import { h, Fragment } from 'preact'
import { useCallback, useMemo } from 'preact/hooks'

import { setLocation } from 'resources/location'
import { useLocalStorage } from 'lib/storageHooks'
import { useFeed } from 'lib/feedPostHooks'

import PageTab from 'components/PageTab'
import Icon from 'components/Icon'
import OrganizationFeedPosts from 'components/OrganizationFeedPosts'
import FeedPostForm from 'components/FeedPostForm'
import OrganizationAdminsList from 'components/OrganizationAdminsList'
import OrganizationFeedSubscribeeList from 'components/OrganizationFeedSubscribeeList'
import OrganizationFeedPageLayout from 'components/OrganizationFeedPageLayout'
import ContentBox from 'components/ContentBox'
import Navbar from 'components/Navbar'
import Loading from 'components/Loading'

import './index.sass'

export default function OrganizationChannelPage({
  location,
  organization,
  organizationApikey,
  currentUser,
  isMember,
  isAdmin,
  isCurator,
}){
  const order = location.pathname.match(/\/forum(?:\/(hot|best|top))?/)[1] || 'new'
  const feed = `organization:${organizationApikey}:channel:${order}`
  const { refresh, postUids } = useFeed(feed, 'OrganizationChannelPage')

  const waitingForNewPost = postUids.length > 0 && location.query.p && !postUids.includes(location.query.p)

  const onSuccess = useCallback(
    post => {
      // leave /new or /top and trigger feed reload
      setLocation(`/${organizationApikey}/forum?p=${post.uid}`)
      refresh()
    },
    [setLocation, refresh]
  )

  const subscribeeListProps = {
    organization,
    isAdmin,
    isCurator,
  }

  return <OrganizationFeedPageLayout {...{
    className: 'OrganizationChannelPage',
    organization,
    currentUser,
    isMember,
    icon: 'channel',
    name: 'Forum',
    belowNav: <OrganizationFeedSubscribeeList {...{ collapsedByDefault: true, ...subscribeeListProps }}/>,
  }}>
    <Fragment>
      {isMember &&
        <CreateForm {...{
          organizationApikey, organization, isAdmin,
          isCurator, onSuccess,
        }}/>
      }
      <FeedControls {...{ organizationApikey }}/>
      {waitingForNewPost && <Loading />}
      <OrganizationFeedPosts {...{ feed, loggedOut: !currentUser }}/>
    </Fragment>
    <Fragment>
      <OrganizationAdminsList {...{
        organization,
        isMember,
        membershipPills: false,
      }}/>
      <OrganizationFeedSubscribeeList {...subscribeeListProps}/>
    </Fragment>
  </OrganizationFeedPageLayout>
}

function CreateForm({ organizationApikey, organization, isAdmin, isCurator, onSuccess }){

  const defaultValue = useMemo(
    () => ({
      feedOrganizationApikey: organization.apikey,
      visibleTo: 0,
      maxVisibleTo: organization.is_private ? 0 : 3,
    }),
    [organization]
  )

  const [post, setPost] = useLocalStorage(
    `OrganizationChannelPostCreatePage:${organizationApikey}`,
    defaultValue
  )

  return <FeedPostForm {...{
    value: post,
    onChange: setPost,
    onSuccess,
    minMaxVisibleTo: 0,
    maxMaxVisibleTo: 3, // todo limited by organization setting?
    canPostAsOrganization: (isAdmin || isCurator) && organizationApikey,
    forForum: true,
  }}/>
}

const CONTROLS = [
  ['top',       'top',      '/top',  'post with to most total upvotes first'],
  ['active',    'active',   '/hot',  'post with the most recent activity first'],
  ['recent_1',  'new',      '',      'latest posts first'],
]

function FeedControls({ organizationApikey }){
  const pagePathname = `/${organizationApikey}/forum`

  return <ContentBox className="OrganizationChannelPage-FeedControls">
    <Navbar thin>
      <Navbar.Grow/>
      {CONTROLS.map(([icon, name, path, tooltip]) =>
        <PageTab
          selectedIfExact
          href={`${pagePathname}${path}`}
          className="OrganizationChannelPage-feedControl"
          tooltip={tooltip}
        >
          <Icon type={icon} />{name}
        </PageTab>
      )}
    </Navbar>
  </ContentBox>
}
