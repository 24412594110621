import { h } from 'preact'
import PropTypes from 'prop-types'

import AppError from 'components/AppError'
import Loading from 'components/Loading'
import LazyLoad from 'components/LazyLoad'
import './index.sass'

export default function PageLoadingPage({ loader, ...props }){
  return <LazyLoad {...{
    loader,
    props,
    whenLoading: () => (
      <div className="PageLoadingPage PageLoadingPage-loading">
        <Loading type="fullPage" />
      </div>
    ),
    whenError: loadError => (
      <div className="PageLoadingPage PageLoadingPage-error">
        <AppError error={loadError}/>
      </div>
    ),
  }}/>
}

PageLoadingPage.propTypes = {
  loader: PropTypes.func.isRequired,
}

PageLoadingPage.forImport = loader =>
  props => <PageLoadingPage {...{...props, loader}}/>
