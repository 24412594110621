import { h, Fragment } from 'preact'
import { memo } from 'preact/compat'
import PropTypes from 'prop-types'

import { isVimeoUrl, normalizeVimeoUrl } from 'lib/vimeo'
import Link from 'components/Link'

const RawHTML = memo(({source, ignoreNodes = []}) => {
  if (!source) return
  const parser = new global.DOMParser()
  const doc = parser.parseFromString(source, "text/html")
  const options = {
    ignoreNodes: [...DEFAULT_INGNORED_NODES, ...ignoreNodes],
  }
  return h(Fragment, {}, ...renderChildren(doc.body, options))
})

RawHTML.propTypes = {
  source: PropTypes.string.isRequired,
  ignoreNodes: PropTypes.arrayOf(PropTypes.string),
}

export default RawHTML

const DEFAULT_INGNORED_NODES = (
  '#comment style script body html meta title'
).split(' ')

function renderChildren(node, options){
  const children = []
  Array.from(node.childNodes).forEach(childNode => {
    if (options.ignoreNodes.includes(childNode.nodeName.toLowerCase()))
      return
    if (childNode.nodeName === "#text"){
      children.push(childNode.nodeValue)
      return
    }

    let component = childNode.localName
    const props = attributesToProps(childNode)
    if (component === 'a' || 'href' in props){
      component = Link
      props.type = 'link'
    }

    // <oembed url="https://vimeo.com/388446129"></oembed>
    if (component === 'oembed' || isVimeoUrl(props.url)){
      component = 'iframe'
      props.src = normalizeVimeoUrl(props.url)
      delete props.url
      props.frameBorder = 0
      props.allowFullScreen = true
      props.loading = 'lazy'
    }

    children.push(
      h(component, props, ...renderChildren(childNode, options))
    )
  })
  return children
}

function attributesToProps(node){
  const props = {}
  Array.from(node.attributes).forEach(attribute => {
    let key = attribute.name
    let value = attribute.nodeValue
    if (value === '' && typeof node[key] === 'boolean')
      value = node[key]
    props[key] = value
  })
  return props
}
