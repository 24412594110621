import api from '../api'
import { loadEntity, loadCollection } from 'lib/actionHelpers'

export function loadMy() {
  const { myProductsAndServicesUids } = this.getState()
  if (myProductsAndServicesUids) return Promise.resolve()
  return this.takeAction('productsAndServices.reloadMy')
}

export async function reloadMy() {
  return loadCollection.call(this, {
    idProp: 'uid',
    reload: true,
    request: () => api.getProductsAndServices()
      .then(({ productsAndServices }) => productsAndServices),
    entityKey: 'myProductsAndServicesUids',
    memberKey: uid => `productOrService:${uid}`,
  })
}

export async function load(uid) {
  if (this.getState()[`productOrService:${uid}`]) return
  this.takeAction('productsAndServices.reload', uid)
}

export async function reload(uid) {
  return loadEntity.call(this, {
    reload: false,
    entityKey: `productOrService:${uid}`,
    request: () =>
      api.getProductOrService({ uid })
        .then(({ productOrService }) => productOrService)
    ,
  })
}

function addProductsAndServices(productsAndServices){
  const newState = {}
  productsAndServices.forEach(productOrService => {
    newState[`productOrService:${productOrService.uid}`] = productOrService
  })
  this.setState(newState)
}

export async function create(productOrService) {
  if (this.getState().creatingProductOrService) return

  this.setState({
    creatingProductOrService: true,
    creatingProductOrServiceError: undefined,
  })

  try{
    const response = await api.createProductOrService({ productOrService })
    this.addToSet('myProductsAndServicesUids', [response.productOrService.uid])
    addProductsAndServices.call(this, [response.productOrService])
  }catch(error){
    this.setState({ creatingProductOrServiceError: error })
  }finally{
    this.setState({ creatingProductOrService: undefined })
  }
}

async function _delete(uid) {
  if (this.getState().deletingProductOrService) return

  this.setState({
    deletingProductOrService: true,
    deletingProductOrServiceError: undefined,
  })

  try{
    await api.deleteProductOrService({ uid })
    this.removeFromSet('myProductsAndServicesUids', [uid])
    this.setState({
      [`productOrService:${uid}`]: undefined,
    })
  }catch(error){
    this.setState({ deletingProductOrServiceError: error })
  }finally{
    this.setState({ deletingProductOrService: undefined })
  }
}

export { _delete as delete }
