import { h } from 'preact'

import { useRef } from 'preact/hooks'

import PropTypes from 'prop-types'
import CopyTextInput from 'components/CopyTextInput'
import CopyButton from 'components/CopyButton'
import './index.sass'

export default function CopyBox({
  className = '',
  value,
  buttonValue,
  buttonCopedValue,
  buttonType = 'primary',
}){
  const inputRef = useRef()
  return <div className={`CopyBox ${className}`}>
    <CopyTextInput {...{value}} ref={inputRef} />
    <CopyButton {...{
      copyValue: value,
      value: buttonValue,
      copiedValue: buttonCopedValue,
      type: buttonType,
      inputRef,
    }}/>
  </div>
}

CopyBox.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  buttonValue: PropTypes.string,
  buttonCopedValue: PropTypes.string,
  buttonType: PropTypes.string,
}
