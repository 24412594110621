import { h } from 'preact'
import { useCallback } from 'preact/hooks'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import {
  humanizePartialPhoneNumber,
  normalizePartialPhoneNumber,
} from 'lib/phone'


import TextInput from 'components/TextInput'
import './index.sass'

export default function PhoneInput({
  className,
  value,
  onChange,
  onInput,
  onBlur,
  onFocus,
  callingCode = 1,
  placeholder = `+${callingCode} 555 555 5555`,
  ...props
}){
  const noValue = !value || value.trim() === ''
  props.className = classNames('PhoneInput', { className })
  props.placeholder = placeholder
  props.value = noValue ? '' : humanizeValue(value, callingCode)
  props.onChange = useCallback(
    value => {
      console.log('ON CHANGE', { value, callingCode, noValue })
      if (!onChange) return
      if (noValue && value.trim() === `+${callingCode}`)
        onChange('')
      else
        onChange(normalizePartialPhoneNumber(value))
    },
    [onChange, callingCode, noValue]
  )
  props.onChange = useCallback(
    (value, event) => {
      if (!onInput && !onChange)  return
      let normalizedValue = normalizePartialPhoneNumber(value)
      if (value.slice(-1) === ' ') normalizedValue += ' '
      if (normalizedValue && normalizedValue[0] !== '+') normalizedValue = `+${normalizedValue}`
      !(onInput || onChange)(normalizedValue, event)
      event.target.value = humanizeValue(normalizedValue, callingCode)
    },
    [onChange, onInput, callingCode]
  )
  props.onFocus = useCallback(
    event => {
      if (noValue){
        const value = event.target.value = `+${callingCode} `
        event.target.setSelectionRange(value.length, value.length + 1)
      }
      if (onFocus) onFocus(event)
    },
    [onFocus, callingCode, noValue]
  )
  props.onBlur = useCallback(
    event => {
      if (noValue){
        event.target.value = ``
      }
      if (onBlur) onBlur(event)
    },
    [onBlur, callingCode, noValue]
  )
  return <TextInput {...props} />
}

PhoneInput.propTypes = {
  ...TextInput.propTypes,
  callingCode: PropTypes.number,
}

function humanizeValue(value, callingCode){
  let humanized = humanizePartialPhoneNumber(value, callingCode)
  if (value.slice(-1) === ' ') humanized += ' '
  return humanized
}
