import PNFO from 'jlinc-shared/PNFO'
import { h, Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { memo } from 'preact/compat'

import useToggle from 'lib/useToggleHook'
import { useConfirmationBefore } from 'lib/confirmationHooks'
import { useLocationQueryState } from 'resources/location'
import {
  byWeightDescCreatedAtDesc,
  useMyOrganizationMemberships,
  useOrganization,
} from 'lib/membershipAppStateHooks'
import {
  useOrganizationNetworkMembershipsWithOrganizations,
  useOrganizationNetworkMembership,
  useSortOrganizationNetworkMemberships,
} from 'lib/organizationNetworkMembershipHooks'
import { useAlertErrorIf } from 'lib/alertHooks'

import Button from 'components/Button'
import Header from 'components/Header'
import HeaderWithButtons from 'components/HeaderWithButtons'
import PageTabs from 'components/PageTabs'
import ErrorMessage from 'components/ErrorMessage'
import ButtonRow from 'components/ButtonRow'
import IconButton from 'components/IconButton'
import Loading from 'components/Loading'
import { hrefToOrganization } from 'components/LinkToOrganization'
import OrganizationEditForm from 'components/OrganizationEditForm'
import OrganizationSearch from 'components/OrganizationSearch'
import OrganizationList from 'components/OrganizationList'
import MyOrganizationsList from 'components/MyOrganizationsList'
import SortableOrganizationList from 'components/SortableOrganizationList'

export default function MyNetworkPage(props){
  const { organization } = props
  return <div className="OrganizationAdminPage-MyNetworkPage">
    {h(organization.is_network ? AdminNetwork : EnableNetwork, props)}
  </div>
}

function EnableNetwork({ organization, canBeANetwork }){
  return <div className="OrganizationAdminPage-padded">
    <p>
      <ToggleNetworkButton {...{ organization, canBeANetwork }}/>
    </p>
    <p>
      {`A Network is a list of other ${PNFO.plural} curated by your ${PNFO.singular}`}
    </p>
    <p>
      {`
      In the future; members of ${PNFO.plural} in a network will be
      able to create feed posts visible only to members of ${PNFO.plural}
      in that network.
      `}
    </p>
  </div>
}

function ToggleNetworkButton({ organization, canBeANetwork, hideError }){
  const canBeAndIsANetwork = canBeANetwork && organization.is_network
  const [value = {}, onChange] = useState({})
  const render = ({ form }) => canBeAndIsANetwork
    ? <Button
      className=""
      submit
      type="link"
      value="disable network"
      onClick={event => {
        const disableConfirmation = `Are you sure you want to disable your network?`
        if (!window.confirm(disableConfirmation)) { // eslint-disable-line
          event.preventDefault()
          return false
        }
        form.change({ is_network: false })
      }}
    />
    : <Button
      submit
      type="primary"
      value={`Create The ${organization.name} Network`}
      disabled={!canBeANetwork}
      onClick={() => {
        if (canBeANetwork) form.change({ is_network: true })
      }}
    />
  return <OrganizationEditForm {...{
    organization, value, onChange, render, displayError: !hideError
  }}/>
}

function AdminNetwork({ organization, canBeANetwork, location }){
  const organizationApikey = organization.apikey

  const { myOrganizationMemberships } = useMyOrganizationMemberships('OrganizationSearch')
  const {
    organizationNetworkMemberships,
    organizationNetworkMembershipsLoading,
    organizationNetworkMembershipsLoadingError,
  } = useOrganizationNetworkMembershipsWithOrganizations(
    organization.apikey, 'OrganizationAdminPage-MyNetworkPage'
  )

  if (organizationNetworkMembershipsLoading)
    return <Loading />
  const props = {
    location,
    organization,
    pathPrefix: `${hrefToOrganization(organization.apikey)}/admin/network`,
    organizationNetworkMemberships,
    organizationNetworkMembershipsLoadingError,
    organizationListProps: {
      animateChanges: false,
      showMembershipPills: false,
      filter: o => o.apikey !== organizationApikey,
      map: organization => {
        const userOrgMembership = myOrganizationMemberships.find(m =>
          m.organizationApikey === organization.apikey
        )
        const memberOrganizationApikey = (
          organization.apikey || // sometimes missing when loading org
          organization.membership.memberOrganizationApikey
        )
        return {
          ...organization,
          buttons: [
            <Controls {...{
              organizationApikey,
              memberOrganizationApikey,
              adminOfMemberOrg: userOrgMembership && userOrgMembership.admin,
            }}/>
          ],
        }
      }
    },
  }

  return <Fragment>
    <PageTabs {...{
      size: 'lg',
      padded: true,
      tabs: [
        {
          title: 'Listed',
          href: props.pathPrefix,
          render: () => <Listed {...props}/>,
        },
        {
          title: 'Invite',
          href: props.pathPrefix + '/invite',
          render: () => <Invite {...props}/>,
        },
      ],
    }}/>
    <div className="OrganizationAdminPage-padded">
      <ButtonRow>
        <ToggleNetworkButton {...{ organization, canBeANetwork }} hideError/>
      </ButtonRow>
    </div>
  </Fragment>
}

function Listed({
  organization, pathPrefix, organizationListProps,
  organizationNetworkMemberships,
  organizationNetworkMembershipsLoadingError,
}){
  const [sorting, enableSorting, disabledSorting] = useToggle(false)
  const organizations = organizationNetworkMemberships
    .filter(m =>
      m.accepted && m.organizationApikey === organization.apikey
    )
    .map(membership =>
      ({
        ...membership.memberOrganization,
        membership,
        href: `/${membership.organizationApikey}/network/${membership.memberOrganizationApikey}`,
      })
    )
    .filter(organizationListProps.filter)
    .sort(byWeightDescCreatedAtDesc)
    .map(organizationListProps.map)

  const error = (organizationNetworkMembershipsLoadingError)
  return <div className="OrganizationAdminPage-MyNetworkPage-Listed">
    <ErrorMessage {...{error}}/>
    <HeaderWithButtons {...{
      subtle: true,
      size: 'sm',
      className: 'OrganizationAdminPage-padded',
      buttons: [
        (!organizations || organizations.length === 0) ? null :
        sorting
          ? <IconButton type="ok-circled" onClick={disabledSorting} blue/>
          : <IconButton type="edit" onClick={enableSorting}/>
      ]
    }} >
      {PNFO.plural} listed in The {organization.name} Network:
    </HeaderWithButtons>
    {/*
      // List own Hub at the top
      <OrganizationList.Member {...{
        ...organizationListProps,
        ...organization,
        href: `${hrefToOrganization(organization.apikey)}/network`,
      }}/> */
    }
    {sorting
      ? <Sorting {...{
        organization,
        organizations,
      }}/>
      : <OrganizationList {...{
        ...organizationListProps,
        organizations,
        filter: null,
        map: null,
        onEmpty: null,
      }}/>
    }
    <Header size="lg" centered>
      <Button type="primary" href={`${pathPrefix}/invite`}>
        List other {PNFO.plural} in your Network.
      </Button>
    </Header>
  </div>
}


function Sorting({ organization, organizations }){
  const {
    sortOrganizationNetworkMemberships,
    sortingOrganizationNetworkMemberships,
    sortingOrganizationNetworkMembershipsError,
  } = useSortOrganizationNetworkMemberships(
    organization.apikey, 'OrganizationAdminPage-MyNetworkPage'
  )
  return <SortableOrganizationList {...{
    organizations,
    editing: true,
    value: organizations.map(o => o.apikey),
    onChange: sortOrganizationNetworkMemberships,
    disabled: !!sortingOrganizationNetworkMemberships,
    error: sortingOrganizationNetworkMembershipsError,
  }}/>
}

function Invite({ organization, organizationListProps }){
  const [query, setQuery] = useLocationQueryState(
    {query: 'q', defaultValue: '', replace: true},
    'OrganizationAdminPage/MyNetworkPage'
  )
  const searching = !!(query && query.trim())
  return <div className="OrganizationAdminPage-MyNetworkPage-Invited">
    <p className="OrganizationAdminPage-padded">
      <Header subtle size="sm">
        Invite {PNFO.plural} in The {organization.name} Network
      </Header>
    </p>
    <OrganizationSearch {...{
      query,
      onChange: setQuery,
      autoFocus: true,
      placeholder: `Search for ${PNFO.plural} to invite to The ${organization.name} Network`,
      ...organizationListProps,
    }}/>
    {searching || <MyOrganizationsList {...organizationListProps} />}
  </div>
}

const Controls = memo(
  ({ organizationApikey, memberOrganizationApikey, adminOfMemberOrg }) => {
    const isNetworkHub = organizationApikey === memberOrganizationApikey

    const {
      organizationNetworkMembership: membership,
      createOrganizationNetworkMembership: create,
      creatingOrganizationNetworkMembership: creating,
      creatingOrganizationNetworkMembershipError: creatingError,
      removeOrganizationNetworkMembership: remove,
      updatingOrganizationNetworkMembership: updating,
      updatingOrganizationNetworkMembershipError: updatingError,
    } = useOrganizationNetworkMembership(
      organizationApikey, memberOrganizationApikey, 'OrganizationAdminPage-MyNetworkPage'
    )

    useAlertErrorIf(
      creatingError,
      `Failed to join network. Please try again later.`,
      'OrganizationAdminPage-MyNetworkPage',
    )
    useAlertErrorIf(
      updatingError,
      `Failed to leave network. Please try again later.`,
      'OrganizationAdminPage-MyNetworkPage',
    )

    const { organization: memberOrganization }
      = useOrganization(memberOrganizationApikey, 'OrganizationAdminPage-MyNetworkPage')

    const confirmRemove = useConfirmationBefore(remove,
      `Are you sure you want to ` +
      ((membership && membership.accepted) ? 'unlist' : 'uninvite') +
      ` ${memberOrganization ? memberOrganization.name : memberOrganizationApikey}?`
    )
    return <Button {...{
      type: membership ? 'normal' : 'primary',
      value: (
        updating ? (membership.accepted ? 'unlisting…' : 'uninviting…') :
        creating ? (adminOfMemberOrg ? 'listing…' : 'inviting…') :
        membership ? (membership.accepted ? 'unlist' : 'uninvite') :
        /* else */ (adminOfMemberOrg ? 'list' : 'invite')
      ),
      disabled: !!(creating || updating || isNetworkHub),
      onClick: (
        (creating || updating) ? undefined :
        (membership ? confirmRemove : create)
      ),
    }}/>
  }
)
