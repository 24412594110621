import { h } from 'preact'
import { memo } from 'preact/compat'
import PropTypes from 'prop-types'
import moment from 'moment'
import PNFO from 'jlinc-shared/PNFO'

import Link from 'components/Link'
import Card from 'components/Card'
import IconButton from 'components/IconButton'
import Header from 'components/Header'
import Timestamp from 'components/Timestamp'
import CopyLink from 'components/CopyLink'
import Icon from 'components/Icon'
import Pill from 'components/Pill'
import Pills from 'components/Pills'

import './index.sass'

const ProductsAndServicesList = memo(props => {
  const { productsAndServices, onDelete, onEdit } = props

  return <div className="ProductsAndServicesList">
    {productsAndServices
      .sort(newestFirst)
      .map(productOrService =>
        <ProductOrService {...{
          key: productOrService.uid,
          productOrService,
          onEdit,
          onDelete,
        }}/>
      )
    }
  </div>
})

ProductsAndServicesList.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  productsAndServices: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      serial_number: PropTypes.string,
      purchase_date: PropTypes.string,
      renewal_date: PropTypes.string,
      related_companies: PropTypes.array,
    })
  )
}

export default ProductsAndServicesList

function ProductOrService({ onDelete, onEdit, productOrService }){
  const {
    uid,
    name,
    description,
    serial_number,
    purchase_date,
    renewal_date,
    related_companies,
    createdAt,
  } = productOrService

  const pathToProductOrService = `/marketplace/stuff/${uid}`

  return <Card className="ProductsAndServicesList-ProductOrService">
    <Card.Body>
      <div className="ProductsAndServicesList-ProductOrService-buttons">
        <CopyLink
          className="OrganizationFeedPost-Share"
          href={pathToProductOrService}
          value={<Icon type="share" />}
          copiedValue={<Icon type="ok-circled" />}
        />
        <IconButton
          className="ProductsAndServicesList-ProductOrService-editIcon"
          type="edit"
          title="edit"
          onClick={() => { onEdit(uid) }}
        />
        <IconButton
          className="ProductsAndServicesList-ProductOrService-deleteIcon"
          type="cancel-circled"
          title="delete"
          onClick={() => { onDelete(uid) }}
        />
      </div>
      <Link href={pathToProductOrService}>
        <Header className="ProductsAndServicesList-ProductOrService-row-name" size="md">
          {name}
        </Header>
      </Link>
      <ProductOrServiceRow
        description="Description"
        value={description}
      />
      { serial_number &&
        <ProductOrServiceRow
          description="Serial Number"
          value={serial_number}
        />
      }
      { purchase_date &&
        <ProductOrServiceRow
          description="Purchase Date"
          value={formatAsDate(purchase_date)}
        />
      }
      { renewal_date &&
        <ProductOrServiceRow
          description="Renewal Date"
          value={formatAsDate(renewal_date)}
        />
      }
      { related_companies && related_companies.length > 0 &&
        <ProductOrServiceRow
          description={`Related ${PNFO.plural}`}
          value={toTags(related_companies)}
        />
      }
      <ProductOrServiceRow
        description="Created"
        value={<Timestamp time={createdAt} />}
      />
    </Card.Body>
  </Card>
}

const toTags = tags =>
  <Pills>
    {tags.map(tag => <Pill type="info">{tag}</Pill>)}
  </Pills>


function ProductOrServiceRow({ description, value, type }) {
  let className = 'ProductsAndServicesList-ProductOrService-row'
  if (type) className += `ProductsAndServicesList-ProductOrService-row-${type}`

  return <div className={className}>
    <Header className="ProductsAndServicesList-ProductOrService-row-label" size="sm">
      {description}:
    </Header>
    <span className="ProductsAndServicesList-ProductOrService-row-value">
      {value}
    </span>
  </div>
}

const formatAsDate = date => date ? moment(date).format('L') : ''

function newestFirst(a, b){
  a = a.createdAt
  b = b.createdAt
  return a < b ? 1 : a > b ? -1 : 0
}
