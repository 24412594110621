import { useEffect } from 'preact/hooks'
import aServer from './aServer'
import { tx, createInMemoryStore } from 'lib/stores'
import { setPageError } from './pageAlerts'

const state = createInMemoryStore()


// ACTIONS

async function loadInviteToOrganization(token){
  if (state.get().loading) return
  state.set({ loading: true })
  try{
    const { organizationMembershipInvite: invite } =
      await aServer.getJSON(`/organizations/membership_invites/${token}`)
    invite.token = token
    state.set({ invite })
  }catch(error){
    state.set({ error })
    setPageError('Invalid organization invite link', error)
  }
  state.set({ loading: undefined })
}

// HOOKS

export function useInviteToOrganization(token){
  const {
    loading,
    invite,
    error,
  } = state.useStore()
  useEffect(
    () => {
      if (!loading && !invite && !error)
        loadInviteToOrganization(token)
    },
    [loading, invite, error]
  )
  return {
    loadingOrganizationInite: loading,
    organizationInvite: invite,
    organizationInviteInvalid: error,
  }
}


DEBUG.inviteToOrganization = state
