import { h, Fragment } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

import {
  useUserOrganizationMemberships,
  useOrganizations,
} from 'lib/membershipAppStateHooks'

import OrganizationList from 'components/OrganizationList'
import ChatButton from 'components/ChatButton'

export default function UserMembershipsList({ publicProfileDid, className = '' }){
  const {
    loadingUserMemberships,
    errorLoadingUserMemberships,
    userMemberships,
  } = useUserOrganizationMemberships(publicProfileDid, 'UserMembershipsList')
  const organizationApikeys = new Set(
    userMemberships
      .filter(membership => membership.organizationApikey)
      .map(membership => membership.organizationApikey),
  )

  let {
    organizations,
    organizationsLoading,
    organizationsLoadingError,
  } = useOrganizations(organizationApikeys, 'UserMembershipsList')

  organizations = (
    loadingUserMemberships ||
    organizationsLoading
  )
    ? undefined
    : organizations.map(organization => {
      const membership = userMemberships.find(m => m.organizationApikey === organization.apikey)
      return {
        ...organization,
        membership,
        buttons: <Fragment>
          <ChatButton
            organizationApikey={membership.organizationApikey}
            publicProfileDid={membership.memberUserDid}
          />
        </Fragment>
      }
    })

  return <OrganizationList {...{
    className: `UserMembershipsLists ${className}`,
    error: errorLoadingUserMemberships || organizationsLoadingError,
    organizations,
    filter: o => o.membership,
    onEmpty: `This user is not a member of any ${PNFO.plural} yet.`,
  }}/>
}
