import Clipboard from 'clipboard'

export function copyToClipboard(value){
  const input = window.document.createElement('input')
  input.value = value
  const clipboard = new Clipboard(
    input,
    {
      action: () => {},
      target: () => {},
      text: () => value,
    }
  )
  clipboard.onClick({ delegateTarget: input })
  input.select()
  document.execCommand('copy')
  clipboard.destroy()
}
