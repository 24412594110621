import { h, Fragment } from 'preact'
import { useState } from 'preact/hooks'

import classNames from 'lib/classNames'

import IconRowAccordion from 'components/IconRowAccordion'
import OrganizationListMember from 'components/OrganizationListMember'
import Button from 'components/Button'
import Link from 'components/Link'
import OrganizationBanner from 'components/OrganizationBanner'
import ButtonRow from 'components/ButtonRow'
import UserSubscribeToFeedButton from 'components/UserSubscribeToFeedButton'
import OrganizationMembershipButton from 'components/OrganizationMembershipButton'
import OrganizationInfo from 'components/OrganizationInfo'
import { hrefToOrganization } from 'components/LinkToOrganization'

import './index.sass'

export default function OrganizationConnectionsList({
  organizations,
  onEmpty,
  className,
}){
  const [selectedOrganizationApikey, setSelectedOrganizationApikey] = useState()
  const expandedIndex = (
    selectedOrganizationApikey &&
    organizations.findIndex(organization =>
      organization.apikey === selectedOrganizationApikey
    )
  )

  return <IconRowAccordion
    {...{expandedIndex, onEmpty, className: classNames('OrganizationConnectionsList', { className })}}
  >
    {organizations.map((organization) => {
      const goThereHref = hrefToOrganization(organization.apikey)
      const onClick = () => {
        setSelectedOrganizationApikey(
          organization.apikey === selectedOrganizationApikey ? undefined : organization.apikey
        )
      }
      return <div>
        <OrganizationListMember {...{
          organization,
          onClick,
          buttons: <Fragment>
            <Button
              type="normal"
              value="Go there"
              href={goThereHref}
            />
          </Fragment>,
        }}/>
        <OrganizationPreview {...{organization, href: goThereHref}}/>
      </div>
    })}
  </IconRowAccordion>
}

function OrganizationPreview({ organization, href }){
  return <div className="OrganizationConnectionsList-OrganizationPreview">
    <Link href={href} type="none" {...{organization}}>
      <OrganizationBanner {...{organization}}/>
    </Link>
    <div>
      <OrganizationInfo {...{organization}}/>
      <ButtonRow>
        <UserSubscribeToFeedButton {...{organizationApikey: organization.apikey}}/>
        <OrganizationMembershipButton {...{organization}}/>
      </ButtonRow>
    </div>
  </div>
}
