import { h } from 'preact'
import PropTypes from 'prop-types'

import {
  useMyPublicProfile,
  useMyOrganizationMemberships,
  useOrganization,
} from 'lib/membershipAppStateHooks'

import DropdownAsHeader from 'components/DropdownAsHeader'
import './index.sass'

export default function PostAsOrganizationDropdown({
  className = '',
  organizationApikey,
  value,
  onChange,
  disabled,
}){
  const { myPublicProfile } = useMyPublicProfile('PostAsOrganizationDropdown')
  const { myOrganizationMemberships } = useMyOrganizationMemberships('PostAsOrganizationDropdown')
  const { organization } = useOrganization(organizationApikey, 'PostAsOrganizationDropdown')

  const canPostAsOrganization = !!myOrganizationMemberships.find(membership =>
    membership.organizationApikey === organizationApikey && (membership.admin || membership.curator)
  )

  if (!canPostAsOrganization) return null

  const options = {
    [`@${myPublicProfile.username}`]: undefined,
  }
  if (canPostAsOrganization)
    options[organization.name] = organizationApikey

  return <div className={`PostAsOrganizationDropdown ${className}`}>
    <DropdownAsHeader {...{
      value,
      options,
      onChange,
      disabled,
    }}/>
  </div>
}

PostAsOrganizationDropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  organizationApikey: PropTypes.string.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}
