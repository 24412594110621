import { h, Fragment } from 'preact'
import { memo } from 'preact/compat'
import { useRef, useEffect, useCallback, useMemo, useState } from 'preact/hooks'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import moment from 'moment'

import classNames from 'lib/classNames'
import { setPageTitle } from 'lib/Page'
import isMobile from 'lib/isMobile'
import useFirstRender from 'lib/useFirstRender'
import useToggle from 'lib/useToggleHook'
import usePageHasFocus from 'lib/usePageHasFocusHook'
import useOnResize from 'lib/useOnResizeHook'
import { useLocalStorage } from 'lib/storageHooks'
import {
  usePublicProfiles,
  useMyPublicProfile,
} from 'lib/membershipAppStateHooks'
import {
  countUnreadMessages,
  useOrganizationChatSynopsis,
  useCreateChatChannel,
  useChatChannel,
  useFasterChatPolling,
} from 'lib/chatHooks'
import useUploadAssets from 'lib/uploadAssetsHook'
import useOnDOMChange from 'lib/useOnDOMChangeHook'
import useForceUpdate from 'lib/useForceUpdate'
import { publicProfileToDisplayName } from 'lib/publicProfiles'
import useOnlineStatus from 'lib/useOnlineStatusHook'
import { publicProfileToPathname } from 'lib/publicProfiles'
import { GENERAL_CHANNEL_NAME } from 'lib/chat'
import { useScrollIntoView } from 'lib/scrollHooks'

import { replaceLocation } from 'resources/location'

import ContentBox from 'components/ContentBox'
import Link from 'components/Link'
import Icon from 'components/Icon'
import Button from 'components/Button'
import Subtext from 'components/Subtext'
import IconButton from 'components/IconButton'
import Header from 'components/Header'
import Spinner from 'components/Spinner'
import FileSize from 'components/FileSize'
import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import Form from 'components/Form'
import EndUserAvatar from 'components/EndUserAvatar'
import EndUserAvatarStack from 'components/EndUserAvatarStack'
import TextArea from 'components/TextArea'
import WYSIWYGContent from 'components/WYSIWYGContent'
import PlainText from 'components/PlainText'
import FilesUploadDroparea from 'components/FilesUploadDroparea'
import Letterbox from 'components/Letterbox'
import FileButton from 'components/FileButton'
import NewChatModal from 'components/NewChatModal'
import EndUserDisplayNameList from 'components/EndUserDisplayNameList'
import ChatChannelAvatar from 'components/ChatChannelAvatar'
import ChatChannelListMember from 'components/ChatChannelListMember'
import Timestamp from 'components/Timestamp'
import LinkToPublicProfile from 'components/LinkToPublicProfile'
import './index.sass'

export default function OrganizationChatPage({
  params: { channel },
  organization,
  organizationApikey,
}){
  const rootRef = useRef()
  useScrollIntoView({ref: rootRef}, [channel])

  return <div className="OrganizationChatPage" ref={rootRef}>
    <ChatContentBox {...{
      organization,
      organizationApikey,
      channelParam: channel,
    }} />
  </div>
}

function ChatContentBox({organization, channelParam}){
  const chatHref = channel => `/${organization.apikey}/chat` + (channel ? `/${channel}` : '')

  let currentChat = channelParam && (
    (channelParam.match(/^[0-9a-f]{32}$/i)) ? { chatChannelUid: channelParam } :
    (channelParam.startsWith('did:')) ? { dm: channelParam } : undefined
  )

  const { myPublicProfile } = useMyPublicProfile('OrganizationChatPage')

  const [
    isNewChatModalOpen,
    openNewChatModal,
    closeNewChatModal,
  ] = useToggle(false)

  const {
    organizationChatSynopsis: synopsis,
  } = useOrganizationChatSynopsis(organization.apikey, 'OrganizationChatPage')
  useFasterChatPolling('OrganizationChatPage')

  const matchingChatChannel = (
    synopsis &&
    currentChat &&
    currentChat.dm &&
    currentChat.dm !== myPublicProfile.did &&
    findDMChatChannel(synopsis, currentChat.dm)
  )

  if (matchingChatChannel)
    currentChat = { chatChannelUid: matchingChatChannel.uid }

  const loading = (!synopsis || matchingChatChannel)

  useEffect(
    () => {
      if (currentChat && currentChat.dm === myPublicProfile.did)
        replaceLocation(chatHref())
      else if (matchingChatChannel)
        replaceLocation(chatHref(matchingChatChannel.uid))
    },
    [channelParam]
  )

  const totalUnread = useMemo(
    () => synopsis ? countUnreadMessages(synopsis) : 0,
    [synopsis]
  )
  setPageTitle(`${totalUnread > 0 ? `❗${totalUnread} ` : ''}DMs - ${APP_NAME}`)

  return <Fragment>
    <NewChatModal {...{
      open: isNewChatModalOpen,
      onClose: closeNewChatModal,
      organizationApikey: organization.apikey,
    }}/>
    <ContentBox
      className="OrganizationChatPage-ChatContentBox"
      x-open={!!currentChat}
    >
      <div className="OrganizationChatPage-left">
        <div className="OrganizationChatPage-header">
          <Icon type="messages" size="lg"/>
          <Header size="lg">Chat</Header>
          <IconButton
            className="OrganizationChatPage-openNewChat"
            onClick={openNewChatModal}
            type="create"
          />
        </div>
        <div className="OrganizationChatPage-Chats">
          <ChatsList {...{
            chatHref,
            myPublicProfile,
            organization,
            synopsis,
            currentChat,
          }}/>
        </div>
      </div>
      <div className="OrganizationChatPage-right">
        <CurrentChat {...{
          key: `${channelParam}`,
          loading,
          organization,
          myPublicProfile,
          synopsis,
          currentChat,
          openNewChatModal,
        }}/>
      </div>
    </ContentBox>
  </Fragment>
}

function ChatsList({
  chatHref,
  myPublicProfile,
  organization,
  synopsis = [],
  currentChat,
}){
  const firstRender = useFirstRender()

  const chats = synopsis
    .map(chatChannel => ({
      ...chatChannel,
      key: chatChannel.uid,
      href: chatHref(chatChannel.uid),
      selected: currentChat && currentChat.chatChannelUid === chatChannel.uid,
    }))
    .sort((a, b) => {
      a = (a.latestAt || a.createdAt)
      b = (b.latestAt || b.createdAt)
      return a < b ? 1 : a > b ? -1 : 0
    })

  if (currentChat && currentChat.dm) {
    chats.unshift({
      key: 'newDMChatChannel',
      type: 'dm',
      unread: 0,
      createdAt: new Date(),
      selected: true,
      href: `${global.location}`,
      memberUserDids: [currentChat.dm],
    })
  }

  const selectedIndex = chats.findIndex(chat => chat.selected)
  const rootRef = useRef()
  useEffect(
    () => {
      const node = rootRef.current.base
      const scrollTo = top =>
        node.scrollTo({top, behavior: firstRender ? undefined : 'smooth'})
      const selected = node.querySelector('.OrganizationChatPage-ChatListMember-selected')
      if (selected){
        const { offsetTop, offsetHeight } = selected
        const { clientHeight, scrollTop } = node
        if (offsetTop < scrollTop || offsetTop > (scrollTop + clientHeight)){
          scrollTo(offsetTop - (clientHeight / 3) + (offsetHeight / 2))
        }
      }else{
        scrollTo(0)
      }
    },
    [selectedIndex]
  )

  return <TransitionGroup ref={rootRef} className="OrganizationChatPage-ChatsList">
    {chats.map(chatChannel =>
      <CSSTransition
        key={chatChannel.key}
        timeout={200}
        classNames="OrganizationChatPage-ChatsList-transition"
      >
        {h(
          chatChannel.type === 'general' ? GeneralChatListMember : DMChatListMember,
          {...chatChannel, myPublicProfile, organization},
        )}
      </CSSTransition>
    )}
  </TransitionGroup>
}

function GeneralChatListMember({organization, ...props}){
  return <ChatListMember {...{
    ...props,
    organization,
    name: GENERAL_CHANNEL_NAME,
    avatar: <ChatChannelAvatar size="sm" value="#"/>
  }}/>
}

function DMChatListMember({
  organization,
  memberUserDids,
  myPublicProfile,
  ...props
}){
  const otherUserDids = memberUserDids.filter(did => did !== myPublicProfile.did)
  const { publicProfiles } = usePublicProfiles(otherUserDids, 'OrganizationChatPage')
  return <ChatListMember {...{
    ...props,
    organization,
    myPublicProfile,
    name: <EndUserDisplayNameList noLinks {...{publicProfiles}}/>,
    avatar: <EndUserAvatarStack {...{publicProfiles, size: 'sm'}}/>,
  }}/>
}

function ChatListMember({
  unread = 0,
  latestAt,
  preview,
  selected,
  href,
  avatar,
  name,
}){
  return <ChatChannelListMember {...{
    className: classNames('OrganizationChatPage-ChatListMember', { selected, unread }),
    date: latestAt,
    preview,
    avatar,
    name,
    href,
    badge: <Icon
      blue
      type="circle"
      size="sm"
      className="OrganizationChatPage-ChatListMember-unreadBadge"
    />,
  }}/>
}


function CurrentChat({synopsis, currentChat, ...props}){
  if (!synopsis)
    return <Loading type="block"/>
  if (currentChat && currentChat.dm)
    return <NewDMChatChannel {...{
      ...props,
      synopsis,
      memberUserDids: [currentChat.dm],
    }}/>
  if (currentChat && currentChat.chatChannelUid)
    return <ChatChannel {...{
      ...props,
      synopsis,
      chatChannelSynopsis: synopsis.find(s => s.uid === currentChat.chatChannelUid),
      chatChannelUid: currentChat.chatChannelUid,
    }}/>
  return <NoCoversationSelected {...props}/>
}

function NoCoversationSelected({ openNewChatModal }){
  return <div className="OrganizationChatPage-NoCoversationSelected">
    <div>
      <Icon type="chat" size="lg"/>
      <Header size="lg">Your Messages</Header>
      <Header size="md">Send private photos and messages to a friend or group.</Header>
      <Button type="primary" value="SEND MESSAGE" onClick={openNewChatModal}/>
    </div>
  </div>
}

function NewDMChatChannel({organization, myPublicProfile, memberUserDids}) {
  memberUserDids = memberUserDids.filter(did => did !== myPublicProfile.did)

  const organizationApikey = organization.apikey
  const createChatChannel = useCreateChatChannel('OrganizationChatPage')
  const [error, setError] = useState()

  useEffect(
    () => {
      createChatChannel({
        type: 'dm',
        organizationApikey,
        memberUserDids,
      }).catch(setError)
    },
    []
  )

  return <div className="OrganizationChatPage-NewDMChatChannel">
    {error ? <ErrorMessage {...{error, dismissable: false}} /> : <Loading type="fullPage" />}
  </div>
}

function ChatChannel({
  organization,
  myPublicProfile,
  chatChannelUid,
  chatChannelSynopsis,
}){
  const {
    chatChannel,
    chatMessages,
    chatMessagesLoadingError,
    pendingChatMessages,
    createChatMessage,
    loadMessages,
    markChatMessagesAsRead,
  } = useChatChannel(chatChannelUid, 'OrganizationChatPage')

  useEffect(
    () => { if (chatChannelSynopsis) loadMessages() },
    chatChannelSynopsis ? [chatChannelSynopsis.latestAt, chatChannelSynopsis.unread] : []
  )

  if (chatMessagesLoadingError) return <ErrorMessage error={chatMessagesLoadingError} />
  if (!chatChannel) return <Loading type="block" />

  const isGeneral = chatChannel.type === 'general'

  return <ChatWindow {...{
    organization,
    myPublicProfile,
    uid: chatChannel.uid,
    loading: false,
    disabled: false,
    header: (
      isGeneral ? (
        <Fragment>
          <ChatChannelAvatar size="sm" value="#"/>
          <Header size="lg">{GENERAL_CHANNEL_NAME}</Header>
        </Fragment>
      ) : <DMChatChannelHeader {...{
        organization,
        myPublicProfile,
        memberUserDids: chatChannel.memberUserDids,
      }}/>
    ),
    chatMessages,
    pendingChatMessages,
    markChatMessagesAsRead,
    createChatMessage,
    createdAt: isGeneral ? null : chatChannel.createdAt,
    creatorUserDid: isGeneral ? null : chatChannel.creatorUserDid,
  }}/>
}

function DMChatChannelHeader({organization, myPublicProfile, memberUserDids}){
  const otherMemberDids = new Set(memberUserDids)
  otherMemberDids.delete(myPublicProfile.did)
  const { publicProfiles } = usePublicProfiles(
    [...otherMemberDids],
    'OrganizationChatPage'
  )

  return <Fragment>
    <EndUserAvatarStack {...{size: 'sm', publicProfiles}}/>
    <EndUserDisplayNameList {...{
      publicProfiles, asMembersOf: organization.apikey
    }}/>
  </Fragment>
}

function ChatWindow({
  organization,
  myPublicProfile,
  uid,
  loading,
  disabled,
  header,
  chatMessages,
  pendingChatMessages,
  markChatMessagesAsRead,
  createChatMessage,
  createdAt,
  creatorUserDid,
}){
  const chatMessageFormRef = useRef()

  const focusInput = useCallback(
    () => {
      if (isMobile) return
      const start = Date.now()
      const stop = () => { clearInterval(intervalId) }
      const focus = () => {
        if (Date.now() - start > 1000) return stop()
        if (!chatMessageFormRef.current) return
        const input = chatMessageFormRef.current.base.querySelector('textarea')
        if (!input) return
        const { activeElement } = global.document
        if (activeElement === input) return stop()
        input.focus()
      }
      const intervalId = setInterval(focus, 100)
    },
    []
  )

  const uploadFiles = useCallback(
    files => {
      files.forEach(file => {
        createChatMessage({file})
      })
      focusInput()
    },
    [createChatMessage],
  )

  useEffect(focusInput, [!!chatMessages])

  if (loading) return <Loading type="block" />

  return <FilesUploadDroparea
    className="OrganizationChatPage-ChatWindow"
    onFiles={uploadFiles}
    disabled={disabled}
  >
    <div className="OrganizationChatPage-header">
      <IconButton
        type="back"
        href={`/${organization.apikey}/chat`}
        className="OrganizationChatPage-backButton"
      />
      {header}
    </div>
    {chatMessages
      ? <Fragment>
        <div className="OrganizationChatPage-ChatWindow-messages">
          <ChatMessages {...{
            chatMessages,
            pendingChatMessages,
            myPublicProfile,
            focusInput,
            markChatMessagesAsRead,
            createdAt,
            creatorUserDid,
          }}/>
        </div>
        <ChatMessageForm {...{
          ref: chatMessageFormRef,
          uid,
          createChatMessage,
          uploadFiles,
          disabled: disabled || !chatMessages,
        }}/>
      </Fragment>
      : <Loading type="block" delayed/>
    }
  </FilesUploadDroparea>
}

function ChatMessages({
  myPublicProfile,
  chatMessages = [],
  pendingChatMessages = [],
  focusInput,
  error,
  markChatMessagesAsRead,
  createdAt,
  creatorUserDid,
}){
  const publicProfileDids = new Set(chatMessages.map(cm => cm.creatorUserDid))
  if (creatorUserDid) publicProfileDids.add(creatorUserDid)
  const { publicProfiles } = usePublicProfiles(publicProfileDids, 'OrganizationChatPage')
  const { uploadingAssets } = useUploadAssets('OrganizationChatPage')

  const creatorPublicProfile = publicProfiles.find(pp => pp.did === creatorUserDid)

  const children = useMemo(
    () => {
      const allChatMessages = [
        ...chatMessages,
        ...pendingChatMessages.map(pcm => ({
          ...pcm,
          creatorUserDid: myPublicProfile.did,
          pending: true,
        })),
      ].map(chatMessage => {
        const fromMe = chatMessage.creatorUserDid === myPublicProfile.did
        const createdAt = moment(chatMessage.createdAt)
        return {
          ...chatMessage,
          fromMe,
          unread: !fromMe && !chatMessage.readAt,
          publicProfile: (
            fromMe ? myPublicProfile :
            publicProfiles.find(p => p.did === chatMessage.creatorUserDid)
          ),
          upload: chatMessage.uploadId && uploadingAssets[chatMessage.uploadId],
          timestamp: createdAt.format(Timestamp.formats.full),
          day: createdAt.format('LL'),
        }
      })
      const children = []

      if (creatorPublicProfile && createdAt) children.push(
        <Header subtle italic centered className="OrganizationChatPage-createdBy">
          <span>created by </span>
          <span>{publicProfileToDisplayName(creatorPublicProfile)} </span>
          <span>on <Timestamp time={createdAt} format="date"/> </span>
          <span>at <Timestamp time={createdAt} format="time"/></span>
        </Header>
      )

      allChatMessages.forEach((chatMessage, index, messages) => {
        const prevMessage = messages[index - 1]
        const nextMessage = messages[index + 1]
        if (prevMessage && prevMessage.day !== chatMessage.day) children.push(
          <DayBreak {...{key: chatMessage.day, day: chatMessage.day}}/>
        )
        const props = {...chatMessage, key: chatMessage.uid}
        const isOther = m => !m || m.creatorUserDid !== chatMessage.creatorUserDid
        props.firstInGroup = isOther(prevMessage)
        props.lastInGroup = isOther(nextMessage)
        children.push(<ChatMessage {...props}/>)
      })

      return children
    },
    [chatMessages, pendingChatMessages, uploadingAssets]
  )

  const pageHasFocus = usePageHasFocus()
  const listRef = useRef()
  const forceUpdate = useForceUpdate()
  const isStuckToBottomRef = useRef(true)

  const onScroll = useCallback(
    () => {
      const isStuckToBottom = isScrolledToBottom(listRef.current)
      if (isStuckToBottomRef.current === isStuckToBottom) return
      isStuckToBottomRef.current = isStuckToBottom
      forceUpdate()
    },
    []
  )

  const scrollToBottom = useCallback(
    smooth => {
      const list = listRef.current
      if (!list) return
      const top = list.scrollHeight - list.offsetHeight
      if (smooth){
        list.scrollTo({top, behavior: 'smooth'})
      }else{
        list.scrollTop = top
      }
      isStuckToBottomRef.current = true
    },
    []
  )

  // start scrolled at the bottom
  useEffect(
    () => {
      const list = listRef.current
      if (!list) return
      const hasMedia = list.querySelectorAll('.OrganizationChatPage-FileAttachment').length > 0
      const scrollToBottomAndMakeVisible = () => {
        scrollToBottom()
        list.style.visibility = 'visible'
      }
      scrollToBottomAndMakeVisible()
      if (hasMedia) callAFewMoreTimes(scrollToBottomAndMakeVisible)
    },
    []
  )

  useOnResize(
    listRef,
    () => { if (isStuckToBottomRef.current) scrollToBottom() },
    []
  )

  useOnDOMChange({
    ref: listRef,
    onDOMChange(){
      if (isStuckToBottomRef.current) scrollToBottom()
    },
  })

  useEffect(
    () => {
      const list = listRef.current
      if (!list) return
      const latestMessage = chatMessages[chatMessages.length - 1]
      const latestMessageIsFromMe = latestMessage && latestMessage.pending
      if (!latestMessageIsFromMe) return
      focusInput()
      scrollToBottom(isStuckToBottomRef.current)
      callAFewMoreTimes(scrollToBottom)
    },
    [chatMessages.length]
  )

  useEffect(
    () => {
      if (pageHasFocus && isStuckToBottomRef.current && chatMessages.length > 0)
        markChatMessagesAsRead()
    },
    [pageHasFocus, chatMessages.length]
  )

  const onDownButtonClick = useCallback(
    () => {
      scrollToBottom(true)
      focusInput()
    },
    [scrollToBottom, focusInput]
  )

  return <div className="OrganizationChatPage-ChatMessages">
    <IconButton
      key="scrollDownButton"
      className="OrganizationChatPage-scrollDownButton"
      onClick={onDownButtonClick}
      type="angle-circled-down"
      size="lg"
      data-hidden={isStuckToBottomRef.current}
    />
    <div
      ref={listRef}
      className="OrganizationChatPage-ChatMessages-list"
      onScroll={onScroll}
      style={{visibility: 'hidden'}}
    >
      <TransitionGroup>
        {children.map((child, index) =>
          <CSSTransition
            key={index}
            timeout={200}
            classNames="OrganizationChatPage-ChatMessages-transition"
          >{child}</CSSTransition>
        )}
        {error && <ErrorMessage error={error}/>}
      </TransitionGroup>
    </div>
  </div>
}

const FILE_ATTACHMENT_PREFIX = 'jlinc:dm:attachment:'

const DayBreak = memo(({ day }) =>
  <Header size="sm" className="OrganizationChatPage-DayBreak">{day}</Header>
)

const ChatMessage = memo(({
  pending,
  error,
  message,
  timestamp,
  fromMe,
  unread,
  upload,
  publicProfile,
  lastInGroup,
  firstInGroup,
  createdAt,
}) => {
  const className = classNames(
    'OrganizationChatPage-ChatMessage',
    {fromMe, pending, failed: !!error, unread}
  )
  const fileAttachment = (
    message &&
    message.startsWith(FILE_ATTACHMENT_PREFIX) &&
    JSON.parse(message.split(FILE_ATTACHMENT_PREFIX)[1])
  )
  const messageIsWYSIWYG = /^<(h\d|p|ul|figure)/.test(message)
  return <div {...{className}}>
    {fromMe
      ? <div className="OrganizationChatPage-ChatMessage-status">
        {pending
          ? error
            ? <ChatMessageFailed {...{error, message, upload}}/>
            : <Spinner />
          : <Icon size="sm" type="checkmark" />
        }
      </div>
      : <Link href={publicProfile && publicProfileToPathname(publicProfile)}>
        <EndUserAvatar {...{
          publicProfile,
          size: 'sm',
          style: {visibility: lastInGroup ? null : 'hidden'},
        }}
        />
      </Link>
    }
    {
      upload ? <UploadingFile {...upload}/> :
      fileAttachment ? <FileAttachment {...fileAttachment}/> :
      <div className="OrganizationChatPage-Bubble" title={timestamp}>
        { !fromMe && firstInGroup && publicProfile && <LinkToPublicProfile {...{
          type: 'text',
          className: 'OrganizationChatPage-ChatMessage-displayName',
          publicProfile,
        }}/> }
        {
          messageIsWYSIWYG ? <WYSIWYGContent source={message}/> :
          <PlainText text={message}/>
        }
        <Subtext className="OrganizationChatPage-Bubble-time">
          <Timestamp time={createdAt} format="time"/>
        </Subtext>
      </div>
    }
  </div>
})

function ChatMessageFailed({error, message, upload}){
  return <IconButton
    className="OrganizationChatPage-ChatMessageFailed"
    onClick={() => {
      console.error(error)
      console.log({ message, upload })
    }}
  >!</IconButton>
}

function UploadingFile({loading, progress, error, preview}){
  const failed = !!error
  const className = classNames('OrganizationChatPage-UploadingFile', {
    failed,
  })
  return <div {...{className}}>
    <FileAttachment {...preview}/>
    { loading &&
      <div
        className="OrganizationChatPage-UploadingFile-progress"
        style={{right: `${100 - (progress || 95)}%`}}
      />
    }
  </div>
}

function FileAttachment({type, url, name, size, height, width}){
  const isMedia = type && type.match(/(image|video|audio)/i)
  const className = classNames('OrganizationChatPage-FileAttachment', {
    media: isMedia,
    file: !isMedia,
  })
  return <Link {...{href: url, className}} newWindow>
    {isMedia
      ? <MediaAttachment {...{
        type: isMedia[0].toLowerCase(),
        url, height, width
      }}/>
      : <Fragment>
        <Icon size="lg" type="brandposts"/>
        <div className="OrganizationChatPage-fileInfo">
          <Header size="lg">{name}</Header>
          <Header size="md"><FileSize {...{size}} /></Header>
        </div>
      </Fragment>
    }
  </Link>
  return
}

function MediaAttachment({type, url, height, width}){
  const props = {src: url}
  return <Letterbox {...{height, width}}>
    {
      type === 'image' ? <img {...props}/> :
      type === 'video' ? <video controls muted loading="lazy" {...props}/> :
      type === 'audio' ? <audio controls muted loading="lazy" {...props}/> :
      null
    }
  </Letterbox>
}


function ChatMessageForm({
  uid,
  createChatMessage,
  uploadFiles,
  disabled,
}){
  const textAreaRef = useRef()
  const online = useOnlineStatus()
  if (!online) disabled = true

  const [draft, setDraft] = useLocalStorage(
    `chatMessages:${uid}:draft`,
    '',
  )

  const submittable = !disabled && draft.length > 0

  const onSubmit = () => {
    if (!submittable) return
    createChatMessage({message: draft})
    setDraft('')
  }

  const submitOnEnter = (event) => {
    if (!disabled && !isMobile && !event.metaKey && !event.shiftKey && event.key === 'Enter'){
      event.preventDefault()
      onSubmit()
    }
  }

  return <Form {...{
    className: 'OrganizationChatPage-ChatMessageForm',
    onSubmit,
    disabled,
  }}>
    <TextArea
      disabled={disabled}
      ref={textAreaRef}
      autoResizeVertically
      minRows={1}
      maxRows={4}
      resize="none"
      placeholder={!online ? 'OFFLINE' : disabled ? '' : 'Message…'}
      value={draft}
      onInput={setDraft}
      onKeyDown={submitOnEnter}
      tabIndex={1}
    />
    { disabled || submittable
      ? <Button
        disabled={disabled}
        submit
        type="primary"
        value="send"
      />
      : <FileButton
        disabled={disabled}
        type="subtle"
        onFiles={uploadFiles}
        tabIndex={2}
      >
        <Icon type="upload"/>
      </FileButton>
    }
  </Form>
}


const isScrolledToBottom = node => {
  if (!node) return false
  const { scrollHeight, scrollTop, offsetHeight } = node
  const distanceFromBottom = (scrollHeight - offsetHeight) - scrollTop
  return distanceFromBottom < 5
}

// this delay is to allow images to load and resize
function callAFewMoreTimes(func){
  setTimeout(func, 100)
  setTimeout(func, 150)
  setTimeout(func, 200)
}

function findDMChatChannel(synopsis, did){
  return synopsis.find(s =>
    s.type === 'dm' &&
    s.memberUserDids.length === 2 &&
    (
      s.memberUserDids[0] === did ||
      s.memberUserDids[1] === did
    )
  )
}
