import PNFO from 'jlinc-shared/PNFO'
import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { useSearchOrganizations } from 'lib/searchHooks'

import Header from 'components/Header'
import Loading from 'components/Loading'
import ErrorMessage from 'components/ErrorMessage'
import SearchInput from 'components/SearchInput'
import OrganizationList from 'components/OrganizationList'
import { JlinxLooking } from 'components/Jlinx'
import './index.sass'

export default function OrganizationSearch({
  className,

  query,
  onChange,
  autoFocus,
  placeholder,

  filter,
  textFilter,
  map,
  sortBy,
  activeOrganizationApikey,
  onEmpty,
  onEmptyAfterTextFilter,
  showMembershipIcon,
  showMembershipPills,
  animateChanges,
  organizationsLoading,
  ...props
}){
  const search = useSearchOrganizations(query, 'OrganizationSearch')
  const organizations = search.validQuery && search.organizations || []
  const loading = search.validQuery && !search.organizations
  return <div
    {...props}
    className={classNames('OrganizationSearch', { className })}
  >
    <SearchInput {...{
      value:query,
      onChange,
      autoFocus,
      placeholder,
    }}/>
    {
      search.error ? <ErrorMessage error={search.error} /> :
      loading ? <Loading type="block" size="lg" /> :
      <OrganizationList {...{
        organizations,
        filter,
        textFilter,
        map,
        sortBy,
        activeOrganizationApikey,
        onEmpty: onEmpty || (search.validQuery ? <NoResults /> : null),
        onEmptyAfterTextFilter,
        showMembershipIcon,
        showMembershipPills,
        animateChanges,
        organizationsLoading,
      }}/>
    }
  </div>
}

OrganizationSearch.propTypes = {
  className: PropTypes.string,
  query: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  autoFocus: SearchInput.propTypes.autoFocus,
  placeholder: SearchInput.propTypes.placeholder,
  filter: OrganizationList.propTypes.filter,
  textFilter: OrganizationList.propTypes.textFilter,
  map: OrganizationList.propTypes.map,
  sortBy: OrganizationList.propTypes.sortBy,
  activeOrganizationApikey: OrganizationList.propTypes.activeOrganizationApikey,
  onEmpty: OrganizationList.propTypes.onEmpty,
  onEmptyAfterTextFilter: OrganizationList.propTypes.onEmptyAfterTextFilter,
  showMembershipIcon: OrganizationList.propTypes.showMembershipIcon,
  showMembershipPills: OrganizationList.propTypes.showMembershipPills,
  animateChanges: OrganizationList.propTypes.animateChanges,
  organizationsLoading: OrganizationList.propTypes.organizationsLoading,
}

function NoResults(){
  return <div className="OrganizationSearch-NoResults">
    <Header size="lg" centered>No {PNFO.plural} found</Header>
    <JlinxLooking/>
  </div>
}
