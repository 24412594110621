import PNFO from 'jlinc-shared/PNFO'
import { h, Fragment } from 'preact'

import Form from 'components/Form'
import Switch from 'components/Switch'
import OrganizationEditForm from 'components/OrganizationEditForm'

import OrganizationPublicPrivateFormSection from 'components/OrganizationPublicPrivateFormSection'
import OrganizationMembershipSettingsFormSection from 'components/OrganizationMembershipSettingsFormSection'

export default function VisibilityMembershipPage({
  organization, changes, setChanges
}){
  return <OrganizationEditForm {...{
    className: 'OrganizationAdminPage-padded',
    organization,
    value: changes,
    onChange: setChanges,
    render: props =>
      <Fragment>
        <OrganizationPublicPrivateFormSection {...{disabled: true, ...props}}/>
        <OrganizationMembershipSettingsFormSection {...props}/>
        <PublicSection {...{...props, organization}}/>
        <ForumVisibilitySection {...{...props}}/>
        {props.formControls()}
      </Fragment>
  }}/>
}
function PublicSection({ form, organization }){
  return <Form.Row>
    <Form.Item>
      <Form.Label>LIST PUBLICLY</Form.Label>
      <p>Make your {PNFO.singular} discoverable and visible publically.</p>
      {
        form.bindInput({
          valueProp: 'publicly_listed',
          validateOnChange: true,
          disabled: organization.is_network && organization.publicly_listed,
          input: <Switch />
        })
      }
    </Form.Item>
  </Form.Row>
}
function ForumVisibilitySection({ form }){
  return <Form.Row>
    <Form.Item>
      <Form.Label>FORUM VISIBILITY</Form.Label>
      <p>Only allow members to see Forum.</p>
      {
        form.bindInput({
          valueProp: 'forum_only_visible_to_members',
          validateOnChange: true,
          input: <Switch />
        })
      }
    </Form.Item>
  </Form.Row>
}
