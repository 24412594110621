import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import './index.sass'

export default function ContentBox({ className, padded, children, ...props }){
  return <div
    className={classNames('ContentBox', {padded, className})}
    {...props}
  >{children}</div>
}

ContentBox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  padded: PropTypes.bool,
}

ContentBox.defaultProps = {
  padded: false,
}
