import { useEffect, useMemo } from 'preact/hooks'
import { useAppState, useAppActions } from 'lib/appState'
import useLoadedEntity from 'lib/useLoadedEntityHook'

const appStateKey = (organizationApikey, channel) =>
  `chat:${organizationApikey}:${channel}`

export function countUnreadMessages(synopsis){
  return Array.from(synopsis)
    .map(s => s.unread || 0)
    .reduce((a, b) => a + b, 0)
}

export function useChatSynopsis(componentName){
  return useLoadedEntity({
    entityKey: 'chatSynopsis',
    loadAction: 'chat.loadSynopses',
    // loadEntity: takeAction => takeAction('chat.loadSynopses'),
    componentName,
  })
}

export function useOrganizationChatSynopsis(organizationApikey, componentName){
  const {
    chatSynopsisLoading: organizationChatSynopsisLoading,
    chatSynopsisLoadingError: organizationChatSynopsisLoadingError,
    chatSynopsis,
  } = useChatSynopsis(componentName)
  const organizationChatSynopsis = useMemo(
    () => chatSynopsis && chatSynopsis
      .filter(c => c.organizationApikey === organizationApikey),
    [chatSynopsis],
  )
  return {
    organizationChatSynopsisLoading,
    organizationChatSynopsisLoadingError,
    organizationChatSynopsis,
  }
}

export function useCreateChatChannel(componentName){
  return useAppActions(componentName).appAction('chat.createChatChannel')
}

export function usePendingChatMessages(chatChannelUid, componentName){
  const {
    pendingChatMessages
  } = useAppState(
    {
      [`chat:channel:${chatChannelUid}:messages:pending`]: 'pendingChatMessages',
    },
    componentName,
  )
  return { pendingChatMessages }
}

export function useChatMessages(chatChannelUid, componentName){
  return useLoadedEntity({
    entityName: 'chatMessages',
    // entityKey: `${organizationApikey}:chatMessages:${channel}`,
    entityKey: `chat:channel:${chatChannelUid}:messages`,
    loadEntity: takeAction => {
      if (chatChannelUid) takeAction('chat.loadMessages', chatChannelUid)
    },
    componentName,
  })
}

export function useFasterChatPolling(componentName){
  const { appAction } = useAppState([], componentName)
  const set = appAction('chat.setFasterChatPolling')
  useEffect(() => { set(true); return () => { set(false) } }, [])
}

export function useCreateChatMessage(organizationApikey, channel, componentName){
  const {
    appAction,
    creatingChatMessage,
    creatingChatMessageError,
  } = useAppState(
    {
      [`${appStateKey(organizationApikey, channel)}:creating`]: 'creatingChatMessage',
      [`${appStateKey(organizationApikey, channel)}:creating:error`]: 'creatingChatMessageError',
    },
    componentName,
  )

  const createChatMessage = appAction('chat.createMessage', organizationApikey, channel)

  return {
    createChatMessage,
    creatingChatMessage,
    creatingChatMessageError,
  }
}

export function useMarkChatMessagesAsRead(organizationApikey, channel, componentName){
  const { appAction } = useAppActions(componentName)
  return {
    markChatMessagesAsRead:
      appAction('chat.markAsRead', organizationApikey, channel),
  }
}

export function useChatChannel(chatChannelUid, componentName){
  const channelKey = `chat:channel:${chatChannelUid}`

  const { appAction } = useAppActions(componentName)
  const { chatSynopsis = [] } = useChatSynopsis(componentName)
  const chatChannel = chatSynopsis.find(c => c.uid === chatChannelUid)

  const {
    chatMessages,
    pendingChatMessages = new Set(),
    creatingChatMessage,
    creatingChatMessageError,
  } = useAppState(
    {
      [`${channelKey}:messages`]: 'chatMessages',
      [`${channelKey}:messages:pending`]: 'pendingChatMessages',
      [`${channelKey}:creating`]: 'creatingChatMessage',
      [`${channelKey}:creating:error`]: 'creatingChatMessageError',

    },
    componentName,
  )
  return {
    ...useChatMessages(chatChannelUid, componentName),
    chatChannel,
    chatMessages,
    pendingChatMessages: Array.from(pendingChatMessages)
      .sort(sortByCreatedAtDescending),
    creatingChatMessage,
    creatingChatMessageError,
    createChatMessage: appAction('chat.createMessage', chatChannelUid),
    loadMessages: appAction('chat.loadMessages', chatChannelUid),
    markChatMessagesAsRead: appAction('chat.markAsRead', chatChannelUid),
  }
}

const sortByCreatedAtDescending = (a, b) =>
  new Date(a.createdAt) - new Date(b.createdAt)
