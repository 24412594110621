import { h } from 'preact'
import PropTypes from 'prop-types'

import sortBy from 'lib/sortBy'
import classNames from 'lib/classNames'
import {
  useMySISAs,
  useMyOrganizationMemberships,
  useMyAcceptedOrganizationMemberships,
  useMyPendingOrganizationMemberships,
  useOrganizations,
} from 'lib/membershipAppStateHooks'
import { useMyOrganizationFeedSubscriptions } from 'lib/feedSubscriptionHooks'
import { useLoggedIn } from 'resources/auth'

import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import OrganizationList from 'components/OrganizationList'

export default function MyOrganizationsList(props){
  const loggedIn = useLoggedIn()
  return h(loggedIn ? LoggedIn : LoggedOut, props)
}

MyOrganizationsList.propTypes = {
  className: PropTypes.string,
  organizations: OrganizationList.propTypes.organizations,
}

MyOrganizationsList.defaultProps = {
  showMembershipIcon: true,
  showMembershipPills: true,
}

function LoggedOut(props) {
  return <GivenOrganizations {...{
    ...props,
    mySISAs: new Set(),
    myAcceptedOrganizationMemberships: [],
    myPendingOrganizationMemberships: [],
    myOrganizationFeedSubscriptions: [],
  }}/>
}

function LoggedIn(props){
  const { mySISAs = new Set() } = useMySISAs('MyOrganizationsList')
  const {
    myOrganizationMembershipsLoaded,
    myOrganizationMembershipsLoadingError,
  } = useMyOrganizationMemberships('MyOrganizationsList')
  const { myAcceptedOrganizationMemberships = [] } = useMyAcceptedOrganizationMemberships('MyOrganizationsList')
  const { myPendingOrganizationMemberships = [] } = useMyPendingOrganizationMemberships('MyOrganizationsList')
  const {
    myOrganizationFeedSubscriptions = [],
    myOrganizationFeedSubscriptionLoaded,
    myOrganizationFeedSubscriptionLoadingError,
  } = useMyOrganizationFeedSubscriptions('MyOrganizationsList')

  const error = myOrganizationMembershipsLoadingError || myOrganizationFeedSubscriptionLoadingError
  if (error) return <ErrorMessage {...{error}}/>
  if (!myOrganizationMembershipsLoaded || !myOrganizationFeedSubscriptionLoaded)
    return <Loading className="MyOrganizationsList" type="block"/>

  props = {
    ...props,
    mySISAs,
    myAcceptedOrganizationMemberships,
    myPendingOrganizationMemberships,
    myOrganizationFeedSubscriptions,
  }

  return 'organizations' in props
    ? <GivenOrganizations {...props}/>
    : <MyOrganizations {...props}/>
}

function MyOrganizations({
  mySISAs,
  myAcceptedOrganizationMemberships,
  myOrganizationFeedSubscriptions,
  ...props
}){
  const getApikeys = a => a.map(m => m.organizationApikey)
  const myOrganizationApikeys = [
    ...mySISAs,
    ...getApikeys(myAcceptedOrganizationMemberships),
    ...getApikeys(myOrganizationFeedSubscriptions),
  ]
  const {
    organizations,
    organizationsLoadingError,
    organizationsLoading,
  } = useOrganizations(
    myOrganizationApikeys,
    'MyOrganizationsList',
  )

  return <GivenOrganizations {...{
    ...props,
    error: organizationsLoadingError || props.error,
    mySISAs,
    myAcceptedOrganizationMemberships,
    myOrganizationFeedSubscriptions,
    organizations,
    organizationsLoading,
  }}/>
}

function GivenOrganizations({
  className,
  sort = true,
  organizations,
  mySISAs,
  myAcceptedOrganizationMemberships,
  myPendingOrganizationMemberships,
  myOrganizationFeedSubscriptions,
  organizationsLoading,
  filter,
  ...props
}){

  if (organizations) {
    organizations = organizations.map(organization => {
      const byApikey = x => x.organizationApikey === organization.apikey
      const subscription = myOrganizationFeedSubscriptions.find(byApikey)
      const membership = myAcceptedOrganizationMemberships.find(byApikey)
      const haveSISA = mySISAs.has(organization.apikey)
      const hasPendingOrganizationMemberships = (
        membership &&
        (membership.admin || membership.curator) &&
        myPendingOrganizationMemberships &&
        myPendingOrganizationMemberships.some(membershipRequest =>
          membershipRequest.organizationApikey === organization.apikey &&
          membershipRequest.requester !== organization.apikey
        )
      )
      return {
        ...organization,
        subscription,
        membership,
        haveSISA,
        hasPendingOrganizationMemberships,
      }
    })
    if (filter) organizations = organizations.filter(filter)
    if (sort) organizations = sortMyOrganizations(organizations)
  }
  return <OrganizationList {...{
    ...props,
    sortBy: null,
    className: classNames('MyOrganizationsList', {className}),
    organizations,
    organizationsLoading,
  }}/>
}

function sortMyOrganizations(organizations){
  return sortBy(organizations, [
    o => !o.hasPendingOrganizationMemberships,
    o => !o.membership,
    o => !(o.membership && o.membership.admin),
    o => !(o.membership && o.membership.curator),
    o => !(o.membership && (o.membership.admin || o.membership.curator)),
    o => o.name ? o.name : o.apikey,
  ])
}
