import { h } from 'preact'
import { useMemo, useRef, useErrorBoundary } from 'preact/hooks'

import { setPageTitle } from 'lib/Page'
import {
  useOrganization,
  useCanonicalOrganizationApikeyRedirector,
  useMyOrganizationMembership,
  useAcceptedMembershipsForOrganization,
  useOrganizationMembershipRequests,
} from 'lib/membershipAppStateHooks'
import { useOrganizationFeedSubscriptions } from 'lib/feedSubscriptionHooks'
import { reportOrganizationVisit } from 'lib/recentOrganizations'
import useScrollUpOnNaturalPageChange from 'lib/useScrollUpOnNaturalPageChange'
import { useSisa } from 'lib/membershipAppStateHooks'

import NotFoundPage from '../NotFoundPage'
import Loading from 'components/Loading'
import Unauthorized from 'components/Unauthorized'
import Link from 'components/Link'
import IconButton from 'components/IconButton'
import Header from 'components/Header'
import ErrorMessage from 'components/ErrorMessage'
import OrganizationIcon from 'components/OrganizationIcon'
import OrganizationBanner from 'components/OrganizationBanner'
import EditableOrganizationBanner from 'components/EditableOrganizationBanner'
import BannerNav from 'components/BannerNav'
import Subtext from 'components/Subtext'
import SingleLine from 'components/SingleLine'
import OrganizationSISAButton from 'components/OrganizationSISAButton'
import OrganizationJoinModal from 'components/OrganizationJoinModal'
import OrganizationMembershipButton from 'components/OrganizationMembershipButton'
import { useAPortalInterfaceWalkthroughInitiator } from 'components/APortalInterfaceWalkthrough'

import { useOrganizationPagePages } from './pages'
import './index.sass'

export default function OrganizationPage(props){
  const { organizationApikey } = props.params
  const {
    organization,
    organizationLoadingError,
    organizationNotFound,
  } = useOrganization(organizationApikey, 'OrganizationPage')

  useCanonicalOrganizationApikeyRedirector(organizationApikey, 'OrganizationPage')

  if (organizationLoadingError)
    return <div className="OrganizationPage">
      <ErrorMessage error={organizationLoadingError} />
    </div>

  if (organizationNotFound)
    return <NotFoundPage {...props}/>

  if (!organization)
    return <div className="OrganizationPage"><Loading type="fullPage"/></div>

  return <ActualOrganizationPage {...{
    ...props,
    key: organizationApikey,
    organizationApikey,
    organization,
  }}/>
}

function ActualOrganizationPage(props){
  const ref = useRef()
  const {
    currentUser,
    location,
    organizationApikey,
    organization,
  } = props

  reportOrganizationVisit(organizationApikey)
  useAPortalInterfaceWalkthroughInitiator(organizationApikey, 'OrganizationPage')

  const {
    isMember, isAdmin, isCurator
  } = useMyOrganizationMembership(organizationApikey, 'OrganizationPage')

  const { pages, findPage } = useOrganizationPagePages({
    orgIsClosed: !!organization.is_closed,
    orgIsPrivate: !!organization.is_private,
    userIsLoggedIn: !!currentUser,
    userIsMember: isMember,
    userIsCurator: isCurator,
    userIsAdmin: isAdmin,
    forumMembersOnly: organization.forum_only_visible_to_members,
  })

  props = {
    ...props,
    pages,
    findPage,
    isMember,
    isAdmin,
    isCurator,
  }

  return <div ref={ref} className="OrganizationPage" key={organization.apikey}>
    <MainNav {...{
      ...props,
      key: `MainNav-${organization.apikey}`,
    }}/>
    <PageContent {...{
      ...props,
      key: `PageContent-${organization.apikey}`,
    }}/>
    <OrganizationJoinModal {...{organization, location, currentUser, isMember}}/>
  </div>
}

function MainNav(props){
  const {
    location,
    organization,
    isAdmin,
    isCurator,
    findPage,
    isMember,
  } = props
  const { sisa } = useSisa(organization.apikey, 'OrganizationPage.MainNav')

  const {
    organizationMembershipRequests,
  } = useOrganizationMembershipRequests(organization.apikey, 'OrganizationPage')

  const hasPendingMembershipRequests = (
    (isAdmin || isCurator) &&
    organizationMembershipRequests.length > 0
  )

  const pathnamePrefix = `/${organization.apikey}`

  const navButtons = useMemo(
    () => {
      const navButtons = []
      const navButton = (subpath, name, icon) => {
        const {page} = findPage(subpath)
        const root = subpath === '/'
        navButtons.push({
          disabled: !page || page.unauthorized,
          href: pathnamePrefix + (root ? '' : subpath),
          className: `OrganizationPage-navButton-${name}`,
          value: name,
          icon,
          selectedIfExact: root,
        })
      }
      navButton('/', 'Home', 'home')
      if (isDataYogi){
        navButton('/marketplace', 'Marketplace', 'market')
        navButton('/my-stuff', 'My Stuff', 'brandposts')
        if (sisa) navButton('/my-data', 'My Data', 'data-tr')
      }else{
        navButton('/published', 'Published', 'public-feed')
        navButton('/forum', 'Forum', 'channel')
        navButton('/chat', 'Chat', 'messages')
        const membersBang = hasPendingMembershipRequests ? '❗' : ''
        navButton('/members', `Members${membersBang}`, 'people')
        if (organization.is_network)
          navButton('/network', 'Network', 'network')
      }
      return navButtons
    },
    [isDataYogi, organization, findPage, hasPendingMembershipRequests, sisa]
  )

  const extraButtons = [
    isDataYogi
      ? <OrganizationSISAButton {...{organizationApikey: organization.apikey}} />
      : <OrganizationMembershipButton {...{ organization }}/>
  ]
  if (isAdmin || isCurator) {
    let href = `${pathnamePrefix}/admin`
    if (location.pathname.startsWith(href)) href = `${location}`
    extraButtons.push(
      <IconButton type="cog" size="sm" bordered padded href={href}/>
    )
  }

  const banner = ((isAdmin || isCurator)
    ? <EditableOrganizationBanner {...{organization}}/>
    : <OrganizationBanner {...{organization}}/>
  )

  const icon = <Link
    className="OrganizationPage-MainNav-icon"
    href={`/${organization.apikey}`}
  >
    <OrganizationIcon organization={organization} size="md" bordered />
  </Link>

  const name = <NameAndStats {...{organization, isMember}}/>

  return <BannerNav {...{
    banner, icon, name, navButtons, extraButtons
  }}/>
}

function NameAndStats({ organization, isMember }){
  const organizationApikey = organization.apikey
  const {
    acceptedOrganizationMemberships = []
  } = useAcceptedMembershipsForOrganization(
    organizationApikey,
    'organizationPage'
  )
  const {
    organizationFeedSubscriptions = [],
  } = useOrganizationFeedSubscriptions(
    organizationApikey,
    'OrganizationPage',
  )

  const followerCount = organizationFeedSubscriptions.filter(s => s.organizationApikey === organizationApikey).length

  const membersLink = isDataYogi ? `/${organizationApikey}` : `/${organizationApikey}/members`

  return <div className="OrganizationPage-NameAndStats">
    <Link href={`/${organization.apikey}`} className="OrganizationPage-NameAndStats-name">
      <Header size="xl">
        <SingleLine>
          <SingleLine.Shrink>{organization.name}</SingleLine.Shrink>
        </SingleLine>
      </Header>
    </Link>
    {
      !isDataYogi && (!organization.is_closed || isMember) &&
        <Subtext>
          <Link type="text" href={membersLink}>
            {acceptedOrganizationMemberships.length}&nbsp;
            Member{acceptedOrganizationMemberships.length > 1 ? 's' : ''}
          </Link>
          &nbsp;&nbsp;&nbsp;
          <Link type="text" href={membersLink}>
            {followerCount}&nbsp;
            Subscriber{followerCount > 1 ? 's' : ''}
          </Link>
        </Subtext>
    }
  </div>
}

function PageContent(props){
  const { organization, findPage } = props
  const path = `/${props.params.path || ''}`

  const [error, onDismiss] = useErrorBoundary(error =>
    console.error('OrganizationPage render error', error)
  )

  const {page, params} = findPage(path)
  useScrollUpOnNaturalPageChange([page])
  if (!page) return <NotFoundPage {...props}/>

  const Component = page.comp
  props.params = {...props.params, ...params}

  setPageTitle(
    (page.title ? `${page.title} | ` : '') +
    `${organization.name || organization.apikey}`
  )

  if (
    page.unauthorized === 'notAdmin' ||
    page.unauthorized === 'notCurator'
  ) return <Unauthorized />

  if (
    page.unauthorized === 'notLoggedIn'
  ) return <Unauthorized {...{
    header: `${APP_NAME} Only Area!`,
    subtext: (
      `You must be logged in to ${APP_NAME} to visit this page.`
    ),
  }}/>

  if (
    page.unauthorized === 'notMember'
  ) return <Unauthorized {...{
    header: 'Members Only Area!',
    subtext: (
      `You must be a member of ${organization.name}` +
      ` to visit this page.`
    ),
    destinationOrganizationApikey: organization.apikey,
    recommendation: props.loggedIn
      ? <OrganizationMembershipButton {...{
        organization,
        hideIfMember: false,
      }}/>
      : undefined
  }}/>

  return error
    ? <ErrorMessage key="error" {...{error, onDismiss}}/>
    : <Component {...props} />
}
