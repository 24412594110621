import PNFO from 'jlinc-shared/PNFO'
import logger from 'lib/logger'
import api from '../api'
import { loadEntity, tx } from 'lib/actionHelpers'
import { setLocation } from 'resources/location'

export function load(organizationApikey) {
  const {
    [`organization:${organizationApikey}:loaded`]: loaded,
    [`organization:${organizationApikey}:canonical`]: canonicalOrganizationApikey,
  } = this.getState()
  if (canonicalOrganizationApikey) return load.call(this, canonicalOrganizationApikey)
  if (!loaded) this.takeAction('organization.batchLoad', organizationApikey)
}

export function batchLoad(organizationApikey) {
  this.addToSet('organizationsToLoad', [organizationApikey])
  if (!batchLoad.timeout) batchLoad.timeout = setTimeout(() => {
    const { organizationsToLoad } = this.getState()
    delete batchLoad.timeout
    if (!organizationsToLoad || organizationsToLoad.size === 0) return
    this.setState({ organizationsToLoad: undefined })
    this.takeAction('organizations.load', organizationsToLoad)
  }, 10)
}

export function reload(organizationApikey) {
  if (!organizationApikey) throw new Error('organizationApikey is required')
  if (typeof organizationApikey !== 'string') throw new Error('organizationApikey must be string')

  const organizationKey = `organization:${organizationApikey}`
  const loadingKey = `${organizationKey}:loading`
  const loadingErrorKey = `${loadingKey}:error`

  if (this.getState()[loadingKey]) return

  this.setState({
    [loadingKey]: true,
    [loadingErrorKey]: undefined,
  })

  api.getOrganization({ organizationApikey }).then(
    ({organizations, memberships, publicProfiles}) => {
      const lowerCaseOrganizationApikey = organizationApikey.toLowerCase()
      const organization = organizations
        .find(organization => organization.apikey.toLowerCase() === lowerCaseOrganizationApikey)
      const canonicalOrganizationApikey = organization && organization.apikey

      this.setState({
        [`organization:${organizationApikey}:loaded`]: true,
        [loadingKey]: undefined,
      })
      this.takeAction('publicProfiles.addPublicProfilesToAppState', publicProfiles)
      this.takeAction('organization.addOrganizationsToAppState', organizations)
      this.takeAction('organizationMemberships.addMembershipsToAppState', memberships)

      if (canonicalOrganizationApikey && canonicalOrganizationApikey !== organizationApikey){
        this.setState({
          [`organization:${organizationApikey}:canonical`]: canonicalOrganizationApikey,
        })
      }
    },
    error => {
      if (error.message.includes('organization not found')){
        this.setState({
          [loadingKey]: undefined,
          [`organization:${organizationApikey}:notFound`]: true,
        })
      }else{
        logger.error(`error loading organization ${organizationApikey}: ${error}`)
        this.setState({
          [loadingKey]: undefined,
          [loadingErrorKey]: error,
        })
      }
    }
  )
}

export function addOrganizationsToAppState(organizations){
  const newState = {}
  organizations.forEach(organization => {
    newState[`organization:${organization.apikey}`] = organization
  })
  this.setState(newState)
}

export function loadSisaEvents(organizationApikey){
  const sisaEvents = this.getState()[`sisaEvents:${organizationApikey}`]
  if (sisaEvents) return
  this.takeAction('organization.reloadSisaEvents', organizationApikey)
}

export async function reloadSisaEvents(organizationApikey){
  return loadEntity.call(this, {
    reload: true,
    entityKey: `sisaEvents:${organizationApikey}`,
    request: async () => {
      const sisaEvents = await api.getSisaEventsForOrganization({organizationApikey})
      const newState = {}
      sisaEvents.forEach(sisaEvent => {
        newState[`sisaEvent:${sisaEvent.audit.eventId}`] = sisaEvent
      })
      this.setState(newState)
      return sisaEvents.map(sisaEvent => sisaEvent.audit.eventId)
    },
  })
}

export function loadApikeyAvailability(organizationApikey){
  const stateKey = `organization:${organizationApikey}:availability`
  if (this.getState()[stateKey]) return
  this.setState({[stateKey]: { loading: true }})
  api.getOrganizationApikeyAvailability(organizationApikey).then(
    ({ available }) => {
      this.setState({ [stateKey]: {available} })
    },
    error => {
      this.setState({ [stateKey]: {error} })
    }
  )
}


export function create({organization, inviteToken}){
  if (this.getState().creatingOrganization) return
  this.setState({
    creatingOrganization: true,
    errorCreatingNewOrganization: undefined,
    [`organization:${organization.apikey}:creating`]: true,
    [`organization:${organization.apikey}:creating:error`]: undefined,
  })
  return api.createOrganization({
    organization, organizationMembershipInviteToken: inviteToken
  }).then(
    ({ organization, memberships, organizationFeedSubscription }) => {
      this.takeAction('organization.addOrganizationsToAppState', [organization])
      this.takeAction('organizationMemberships.addMembershipsToAppState', memberships)
      this.takeAction('feedSubscriptions.addOrganizationFeedSubscriptionsToAppState', [organizationFeedSubscription])
      this.setState({
        creatingOrganization: undefined,
        [`organizationMembershipInvite:${inviteToken}`]: undefined,
        [`organization:${organization.apikey}:creating`]: undefined,
        [`organization:${organization.apikey}:creating:error`]: undefined,
        [`organization:${organization.apikey}:feed:fullyLoaded`]: true,
        [`organization:${organization.apikey}:notFound`]: undefined,
        [`organization:${organization.apikey}:canonical`]: organization.apikey,
      })
    },
    error => {
      if (`${error}`.includes('apikey is not available')){
        error = new Error(`${PNFO.singular} URL is already taken. Please choose another.`)
        setLocation(`/${PNFO.singular}/new/branding`)
      }
      this.setState({
        creatingOrganization: undefined,
        errorCreatingNewOrganization: error,
        [`organization:${organization.apikey}:creating`]: undefined,
        [`organization:${organization.apikey}:creating:error`]: error,
      })
    },
  )
}

export async function update(organizationApikey, changes){
  const loadingKey = `organization:${organizationApikey}:updating`
  const errorKey = `organization:${organizationApikey}:updatingError`
  return await tx.call(this, loadingKey, errorKey, async () => {
    const { organization } = await api.updateOrganization({ organizationApikey, changes })
    this.takeAction('organization.addOrganizationsToAppState', [organization])
  })
}

export function clearUpdateError(organizationApikey) {
  this.setState({ [`organization:${organizationApikey}:updatingError`]: undefined })
}
