import { h } from 'preact'
import PropTypes from 'prop-types'

import classNames from 'lib/classNames'

import { useVerifyExternalIdentity } from 'resources/externalIdentities'

import Header from 'components/Header'
import VerificationForm from 'components/VerificationForm'

export default function ExternalIdentityVerificationForm({
  className,
  type,
  value,
  action = `verify your ${type}`,
}){
  const {
    verifyExternalIdentity,
    verifyingExternalIdentity,
    // verifyingExternalIdentityError,
  } = useVerifyExternalIdentity()

  className = classNames('ExternalIdentityVerificationForm', { className })
  return <div {...{className}}>
    <Header size="lg" centered underlined>Verification Required</Header>
    <p>{
      `We've sent a verification code to ${value}. ` +
      `Please enter it here to ${action}.`
    }</p>
    <VerificationForm {...{
      onSubmit(verificationCode){
        verifyExternalIdentity({ type, value, verificationCode })
      },
      submitting: verifyingExternalIdentity,
    }}/>
  </div>
}

ExternalIdentityVerificationForm.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  action: PropTypes.string,
}
