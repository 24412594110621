import { h } from 'preact'
import { useState, useCallback, useRef, useEffect } from 'preact/hooks'
import PNFO from 'jlinc-shared/PNFO'

import { setPageTitle } from 'lib/Page'
import { replaceLocation } from 'resources/location'

import ContentBox from 'components/ContentBox'
import HeaderedContentBox from 'components/HeaderedContentBox'
import SearchInput from 'components/SearchInput'
import Icon from 'components/Icon'
import Header from 'components/Header'
import Navbar from 'components/Navbar'
import Button from 'components/Button'
import PageTab from 'components/PageTab'
import OrganizationsIAmMemberOfList from 'components/OrganizationsIAmMemberOfList'
import PublicOrganizationsList from 'components/PublicOrganizationsList'
import './index.sass'

export default function HubsPage({ location, currentUser }){
  const organizationListRef = useRef()
  const [textFilter, setTextFilter] = useState('')

  const pages = [
    {
      name: 'Member',
      href: `/${PNFO.plural}`,
      selectedIfExact: true,
      component: MemberContentBox,
      onlyWhenLoggedIn: true,
    },
    {
      name: 'All',
      href: `/${PNFO.plural}/all`,
      component: AllContentBox,
    },
  ]

  const currentPathname = location.pathname.toLowerCase()
  const page = (
    pages.find(page => currentPathname === page.href.toLowerCase()) ||
    pages[1]
  )

  setPageTitle(`${PNFO.plural}` + (page ? ` - ${page.name}` : ''))

  const onSearchInputKeyDown = useCallback(
    event => {
      if (event.key === 'Tab') {
        const listNode = organizationListRef.current.base
        const first = listNode && listNode.querySelector('.OrganizationList-Member .Link')
        if (!first) {
          event.preventDefault()
          first.focus()
        }
      }
    },
    []
  )

  const pageNotVisible = page.onlyWhenLoggedIn && !currentUser
  useEffect(
    () => {
      if (pageNotVisible) replaceLocation(`/${PNFO.plural}/all`)
    },
    [pageNotVisible]
  )

  const pageClassName = `HubsPage-${page.name.replace(/\W/, '-')}`
  const buttons = []
  if (currentUser) buttons.push(
    <Button href={`/${PNFO.singular}/new`} className="HubsPage-createButton">
      <span>Create Hub </span>
      <Icon type="create" />
    </Button>
  )
  return <div className={`HubsPage ${pageClassName}`}>
    <Nav {...{
      pages,
      currentUser,
      textFilter,
      setTextFilter,
      onSearchInputKeyDown,
    }}/>
    { page &&
      !pageNotVisible &&
      page.component &&
      h(page.component, {
        key: page.name,
        ref: organizationListRef,
        textFilter,
        buttons,
      })
    }
  </div>
}

function MemberContentBox({ textFilter, buttons }){
  return <HeaderedContentBox
    header={`${PNFO.plural} you are a member of`}
    icon="public-feed"
    buttons={buttons}
  >
    <OrganizationsIAmMemberOfList
      textFilter={textFilter}
      showMembershipPills
    />
  </HeaderedContentBox>
}

function AllContentBox({ textFilter, buttons }){
  return <HeaderedContentBox
    header={`All ${PNFO.plural}`}
    icon="circle-thin"
    buttons={buttons}
  >
    <PublicOrganizationsList
      textFilter={textFilter}
      showMembershipPills
    />
  </HeaderedContentBox>
}

function Nav({
  pages,
  currentUser,
  textFilter,
  setTextFilter,
  onSearchInputKeyDown,
}){
  return <ContentBox className="HubsPage-Nav">
    <Navbar>
      <Header size="lg">
        <Icon type="spaces" size="lg" blue/>&nbsp;
        <span>{PNFO.plural}</span>
      </Header>
      <SearchInput
        value={textFilter}
        onInput={setTextFilter}
        placeholder="Filter…"
        className="HubsPage-search"
        onKeyDown={onSearchInputKeyDown}
      />
      {pages
        .filter(page => currentUser || !page.onlyWhenLoggedIn)
        .map(page =>
          <PageTab {...{
            key: page.name,
            className: `HubsPage-Nav-${page.name.replace(/\W/, '-')}`,
            value: page.name,
            href: page.href,
            selectedIfExact: page.selectedIfExact,
          }}/>
        )
      }
    </Navbar>
  </ContentBox>
}
