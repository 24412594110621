import { h, Fragment } from 'preact'
import PropTypes from 'prop-types'

import useToggle from 'lib/useToggleHook'
import { useOrganizationsIAdmin } from 'lib/membershipAppStateHooks'
import classNames from 'lib/classNames'

import Button from 'components/Button'
import ManageOrganizationFeedSubscriptionsModal from 'components/ManageOrganizationFeedSubscriptionsModal'
import UserSubscribeToFeedButton from 'components/UserSubscribeToFeedButton'
import './index.sass'

export default function OrganizationFeedSubscriptionsButton({
  className = '',
  organization,
  children,
  value = <span>Manage Subscriptions</span>,
  type = 'normal',
  ...props
}){

  let { organizationsIAdmin } = useOrganizationsIAdmin('ManageOrganizationFeedSubscriptionsModal')
  organizationsIAdmin = organizationsIAdmin.filter(organizationIAdmin =>
    organizationIAdmin.apikey !== organization.apikey
  )

  return organizationsIAdmin.length === 0
    ? <UserSubscribeToFeedButton {...{ organizationApikey: organization.apikey, type, ...props }} />
    : <OrganizationFeedSubscriptionsModalButton {...{ className, value, children, organization, type, ...props }} />
}

function OrganizationFeedSubscriptionsModalButton({ className, value, children, organization, ...props }) {
  const [isOpen, open, close] = useToggle(false)

  return <Fragment>
    <Button {...{
      ...props,
      className: classNames('OrganizationFeedSubscriptionsButton', { className }),
      value,
      onClick: open
    }}>{children}</Button>
    <ManageOrganizationFeedSubscriptionsModal {...{
      open: isOpen,
      onClose: close,
      organization
    }}/>
  </Fragment>
}

OrganizationFeedSubscriptionsButton.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
  value: Button.propTypes.value,
  organization: PropTypes.object.isRequired,
}
