import { h } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

import { useOrganizationFeedSubscriptions } from 'lib/feedSubscriptionHooks'
import { useOrganizations } from 'lib/membershipAppStateHooks'
import classNames from 'lib/classNames'

import HeaderedContentBox from 'components/HeaderedContentBox'
import OrganizationConnectionsList from 'components/OrganizationConnectionsList'
import ErrorMessage from 'components/ErrorMessage'
import Loading from 'components/Loading'
import IconButton from 'components/IconButton'
import { sortOrganizationsBySubscribedToOrder } from 'components/SortableOrganizationList'

export default function OrganizationFeedSubscribeeList({
  className,
  organization,
  isAdmin,
  isCurator,
  setSelectedOrganizationApikey,
  selectedOrganizationApikey,
  collapsedByDefault,
}){
  const {
    organizationFeedSubscriptions,
    errorLoadingOrganizationFeedSubscriptions,
    loadingOrganizationFeedSubscriptions,
  } = useOrganizationFeedSubscriptions(
    organization.apikey,
    'OrganizationFeedSubscribeeList',
  )

  const { organizations, organizationsLoading, organizationsLoadingError } = useOrganizations(
    organizationFeedSubscriptions
      .filter(s => s.subscriberOrganizationApikey === organization.apikey)
      .map(s => s.organizationApikey),
    'OrganizationFeedSubscribeeList'
  )
  const buttons = []

  if (isAdmin || isCurator) buttons.push(
    <IconButton type="edit" href={`/${organization.apikey}/admin/subscriptions`}/>
  )

  const loading = loadingOrganizationFeedSubscriptions || organizationsLoading
  return <HeaderedContentBox {...{
    className: classNames('OrganizationFeedSubscribeeList', { className }),
    icon: 'public-feed',
    header: `Pulls published posts from`,
    buttons,
    collapsable: true,
    collapsedByDefault,
  }}>
    <ErrorMessage error={errorLoadingOrganizationFeedSubscriptions || organizationsLoadingError}/>
    {loading
      ? <Loading />
      : <OrganizationConnectionsList {...{
        organization,
        organizations: sortOrganizationsBySubscribedToOrder(
          organization.orgs_subscribed_to_order,
          organizations,
        ),
        isAdmin,
        isCurator,
        setSelectedOrganizationApikey,
        selectedOrganizationApikey,
        onEmpty: `${organization.name} has not subscribed to any ${PNFO.plural}`,
      }}/>
    }
  </HeaderedContentBox>
}
