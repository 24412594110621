import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import Form from 'components/Form'
import TagsInput from 'components/TagsInput'
import DatePicker from 'components/DatePicker'
import FormBuilder from 'components/FormBuilder'
import ErrorMessage from 'components/ErrorMessage'

import './index.sass'

export default class ProductOrServiceForm extends Component {

  static propTypes = {
    disabled: PropTypes.bool,
    value: PropTypes.shape({
      name: PropTypes.string,
      serial_number: PropTypes.string,
      description: PropTypes.string,
      purchase_date: PropTypes.string,
      renewal_date: PropTypes.string,
      related_companies: PropTypes.array,
    }),
    onChange: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onSuccess: PropTypes.func,
    creating: PropTypes.bool,
    error: ErrorMessage.propTypes.error,
  }

  static defaultProps = {
    value: {},
  }

  render() {
    const {
      disabled,
      onCreate,
      onReset,
      creating,
      value,
      onChange,
      onSuccess,
      error,
    } = this.props

    return (
      <FormBuilder
        className="ProductOrServiceForm"
        value={value}
        disabled={disabled || creating}
        submitting={creating}
        onChange={onChange}
        onReset={onReset}
        onSubmit={onCreate}
        onSuccess={onSuccess}
        error={error}
        render={form =>
          <div>
            {form.textItem({
              valueProp: 'name',
              name: 'name',
              label: 'NAME',
              required: true,
            })}
            {form.textItem({
              valueProp: 'serial_number',
              name: 'serial-number',
              label: 'SERIAL / REFERENCE NUMBER',
            })}
            {form.textAreaItem({
              valueProp: 'description',
              name: 'description',
              label: 'DESCRIPTION',
              required: true,
            })}
            <Form.Row>
              {form.item({
                valueProp: 'purchase_date',
                label: 'PURCHASE DATE',
                input: <DatePicker />
              })}
              {form.item({
                valueProp: 'renewal_date',
                label: 'RENEWAL DATE',
                input: <DatePicker />
              })}
            </Form.Row>
            {form.item({
              valueProp: 'related_companies',
              label: 'RELATED ORGANISATIONS',
              input: <TagsInput placeholder="Enter Organisation Names"/>
            })}
            <Form.ButtonRow reverse>
              {form.submitButton({
                value: 'Save',
                submittingValue: 'Saving…',
              })}
              {form.resetButton({})}
            </Form.ButtonRow>
          </div>
        }
      />
    )
  }
}
