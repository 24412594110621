import api from '../api'

export async function create(buyingInterest){
  if (this.getState().creatingBuyingInterest) return
  this.setState({
    creatingBuyingInterest: buyingInterest,
    creatingBuyingInterestError: undefined,
  })
  try{
    ({ buyingInterest } = await api.createBuyingInterest({ buyingInterest }))
    this.extendObject('buyingInterests', { [buyingInterest.uid]: buyingInterest })
  }catch(error){
    this.setState({creatingBuyingInterestError: error})
  }finally{
    this.setState({ creatingBuyingInterest: undefined })
  }
}

export async function load() {
  const { buyingInterests } = this.getState()
  if (!buyingInterests) return await this.takeAction('buyingInterests.reload')
}

export async function reload() {
  if (this.getState().loadingBuyingInterests) return
  this.setState({loadingBuyingInterests: true})
  try{
    const { buyingInterests } = await api.getBuyingInterests()
    const buyingInterestsByUid = {}
    buyingInterests.forEach(buyingInterest => {
      buyingInterestsByUid[buyingInterest.uid] = buyingInterest
    })
    this.setState({buyingInterests: buyingInterestsByUid})
  }catch(error){
    this.setState({loadingBuyingInterestsError: error})
  }finally{
    this.setState({loadingBuyingInterests: undefined})
  }
}

async function _delete({ buyingInterestUid }) {
  if (this.getState().deletingBuyingInterest) return
  this.setState({deletingBuyingInterest: true})
  try{
    await api.deleteBuyingInterest({ buyingInterestUid })
    const buyingInterests = {...this.getState().buyingInterests}
    delete buyingInterests[buyingInterestUid]
    this.setState({buyingInterests})
  }catch(error){
    this.setState({deletingBuyingInterestError: error})
  }finally{
    this.setState({deletingBuyingInterest: undefined})
  }
}

export { _delete as delete }

