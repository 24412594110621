import { h, Component } from 'preact'
import PropTypes from 'prop-types'
import PNFO from 'jlinc-shared/PNFO'
import diffAccountData from 'jlinc-shared/diffAccountData'
import COMMUNICATION_CHANNEL_TITLES from 'jlinc-shared/communication_channel_titles'

import { useAppState } from 'lib/appState'
import {
  isCustomPersonalDataField,
  getLabelForPersonalDataField,
  COMMUNICATION_CHANNELS_HELP,
  areThereStagedConsentChanges,
} from 'lib/accountDataSpec'

import Loading from 'components/Loading'
import Spinner from 'components/Spinner'
import ErrorMessage from 'components/ErrorMessage'
import Button from 'components/Button'
import ButtonWithDropdown from 'components/ButtonWithDropdown'
import Dropdown from 'components/Dropdown'
import Prompt from 'components/Prompt'
import Header from 'components/Header'
import HeaderedContentWithUnsaved from 'components/HeaderedContentWithUnsaved'
import HeaderedContentWithUnsavedAndHelpButton from 'components/HeaderedContentWithUnsavedAndHelpButton'
import AliceConsentsForm from 'components/AliceConsentsForm'
import AliceDefaultPersonalDataForm from 'components/AliceDefaultPersonalDataForm'
import AliceDefaultCommunicationChannelsForm from 'components/AliceDefaultCommunicationChannelsForm'
import HeaderedContentBox from 'components/HeaderedContentBox'

import './index.sass'

class MyDataDefaultsForm extends Component {

  static propTypes = {
    takeAction: PropTypes.func.isRequired,
    loadingDefaultAccountData: PropTypes.bool,
    errorLoadingDefaultAccountData: ErrorMessage.propTypes.error,
    defaultAccountData: PropTypes.object,
    confirmingApplicationOfDefaultAccountDataToAllOrgs: PropTypes.bool,
    myOrganizations: PropTypes.object,
    updatingDefaultAccountData: PropTypes.bool,
    applyingDefaultAccountDataToAllOrgs: PropTypes.bool,
    committingDefaultAccountDataStagedChangesToAllOrgs: PropTypes.bool,
    stagingOrganizationAccountDataForDefaultAccountData: PropTypes.bool,
    promptingToApplyChangesToAllOrgs: PropTypes.bool,
    defaultAccountDataStagedChanges: PropTypes.object,
    updatingDefaultAccountDataError: ErrorMessage.propTypes.error,
    errorApplyingDefaultAccountDataToAllOrgs: ErrorMessage.propTypes.error,
    errorCommittingDefaultAccountDataStagedChangesToAllOrgs: ErrorMessage.propTypes.error,
    'accountDataSectionCollapsed:communicationChannels': PropTypes.bool,
    'accountDataSectionCollapsed:personalData': PropTypes.bool,
    'accountDataSectionCollapsed:GDPRConsents': PropTypes.bool,
    'accountDataSectionCollapsed:CCPAConsents': PropTypes.bool,
    explanation: PropTypes.object,
    haveStagedChanges: PropTypes.bool,
  }

  componentDidMount() {
    this.props.takeAction('reloadDefaultAccountData')
  }

  state = {
    promptingToApplyChangesToAllOrgs: false,
    confirmingApplicationOfDefaultAccountDataToAllOrgs: false,
  }

  stageOrganizationAccountDataForDefaultAccountData(organizationApikey){
    this.props.takeAction('stageOrganizationAccountDataForDefaultAccountData', {organizationApikey})
  }

  clearDefaultAccountDataStagedChanges = () => {
    this.props.takeAction('clearDefaultAccountDataStagedChanges')
  }

  stageDefaultAccountDataChanges(changes){
    this.props.takeAction('stageDefaultAccountDataChanges', changes)
  }

  applyChangesToDefaultAccountData = () => {
    this.props.takeAction('commitDefaultAccountDataStagedChanges')
    this.cancelPrompt()
  }

  applyChangesToAllOrgs(){
    this.props.takeAction('commitDefaultAccountDataStagedChangesToAllOrgs')
    this.cancelPrompt()
  }

  applyDefaultAccountDataToAllOrgs(){
    this.props.takeAction('applyDefaultAccountDataToAllOrgs')
    this.cancelPrompt()
  }

  promptToApplyChangesToAllOrgs = () => {
    this.setState({ promptingToApplyChangesToAllOrgs: true })
  }

  applyChangesToDefaultAccountDataAndToAllOrgs = () => {
    this.cancelPrompt()
    this.applyChangesToDefaultAccountData()
    this.applyChangesToAllOrgs()
  }

  confirmApplicationOfDefaultAccountDataToAllOrgs = () => {
    this.setState({ confirmingApplicationOfDefaultAccountDataToAllOrgs: true })
  }

  applyDefaultAccountDataToAllOrgs = () => {
    this.setState({ confirmingApplicationOfDefaultAccountDataToAllOrgs: false })
    this.props.takeAction('applyDefaultAccountDataToAllOrgs')
  }

  cancelPrompt = () => {
    this.setState({
      promptingToApplyChangesToAllOrgs: false,
      confirmingApplicationOfDefaultAccountDataToAllOrgs: false,
    })
  }


  render(){
    const {
      loadingDefaultAccountData,
      errorLoadingDefaultAccountData,
      defaultAccountData,
      myOrganizations,
      updatingDefaultAccountData,
      applyingDefaultAccountDataToAllOrgs,
      committingDefaultAccountDataStagedChangesToAllOrgs,
      stagingOrganizationAccountDataForDefaultAccountData,
      defaultAccountDataStagedChanges,
      updatingDefaultAccountDataError,
      errorApplyingDefaultAccountDataToAllOrgs,
      errorCommittingDefaultAccountDataStagedChangesToAllOrgs,
      explanation,
    } = this.props

    if (!defaultAccountData) return <Loading size="md"/>

    const {
      promptingToApplyChangesToAllOrgs,
      confirmingApplicationOfDefaultAccountDataToAllOrgs,
    } = this.state

    const haveStagedChanges = !!defaultAccountDataStagedChanges

    const customPersonalDataFields = extractCustomPersonalDataFields({
      myOrganizations, defaultAccountData,
    })

    const numberOfOrgs = Object.keys(myOrganizations).length
    const haveOrganizations = numberOfOrgs > 0

    const myOrganizationNames = Object.keys(myOrganizations).map(organizationApikey =>
      myOrganizations[organizationApikey].name || organizationApikey
    )

    const saving = (
      updatingDefaultAccountData ||
      applyingDefaultAccountDataToAllOrgs ||
      committingDefaultAccountDataStagedChangesToAllOrgs
    )
    const disabled = (
      loadingDefaultAccountData ||
      saving ||
      stagingOrganizationAccountDataForDefaultAccountData
    )
    const controls = []

    if (!haveStagedChanges && !saving && myOrganizationNames.length > 0){
      controls.push(
        <Dropdown
          key="import"
          disabled={disabled}
          value={null}
          options={myOrganizationNames}
          onChange={organizationName => {
            const organizationApikey = Object.keys(myOrganizations).find(key =>
              myOrganizations[key].name === organizationName
            )
            this.stageOrganizationAccountDataForDefaultAccountData(organizationApikey)
          }}
          placeholder="Import"
        />
      )
      if (haveOrganizations) {
        controls.push(
          <Button
            key="applyToAll"
            disabled={disabled}
            type="primary"
            value="Apply To All"
            title={`Apply My Defaults To All My ${PNFO.plural}`}
            onClick={this.confirmApplicationOfDefaultAccountDataToAllOrgs}
          />
        )
      }
    }

    if (haveStagedChanges || saving){
      controls.push(
        <Button
          key="reset"
          type="normal"
          disabled={!haveStagedChanges || disabled}
          value="reset"
          onClick={this.clearDefaultAccountDataStagedChanges}
        />
      )

      const saveButtonProps = {
        type: 'success',
        disabled: !haveStagedChanges || disabled,
        value: (
          updatingDefaultAccountData
            ? 'Saving Changes…'
            : applyingDefaultAccountDataToAllOrgs
              ? 'Applying To All…'
              : 'Save Changes'
        ),
        onClick: this.promptToApplyChangesToAllOrgs,
      }
      if (haveOrganizations){
        controls.push(
          <ButtonWithDropdown
            key="saveButtonWithDropdown"
            buttonValue={saveButtonProps.value}
            {...saveButtonProps}
            dropdownOptions={[
              {
                title: `Save & Apply to all of my ${PNFO.plural}`,
                onClick: this.applyChangesToDefaultAccountDataAndToAllOrgs,
              },
            ]}
          />
        )
      }else{
        controls.push(
          <Button
            key="saveButton"
            {...saveButtonProps}
            onClick={this.applyChangesToDefaultAccountData}
          />
        )
      }
    }

    return <HeaderedContentBox
      className="MyDataDefaultsForm"
      header="SISA Account Data Defaults"
      padded
    >
      { loadingDefaultAccountData && <Spinner /> }
      { confirmingApplicationOfDefaultAccountDataToAllOrgs &&
        <Prompt
          query={`Are you sure you want to apply your defaults to all ${numberOfOrgs} of your ${PNFO.plural}?`}
          onConfirmation={this.applyDefaultAccountDataToAllOrgs}
          onRejection={this.cancelPrompt}
        />
      }
      { promptingToApplyChangesToAllOrgs &&
        <Prompt
          query={
            <div className="MyDataDefaultsForm-applyAllPrompt">
              <Header underlined size="md">
                Apply these changes to all {numberOfOrgs} of your {PNFO.plural}?
              </Header>
              {changesSummary({defaultAccountData, defaultAccountDataStagedChanges})}
            </div>
          }
          rejectionValue="only apply to defaults"
          confirmationValue={`Apply to all ${PNFO.plural}`}
          onConfirmation={this.applyChangesToDefaultAccountDataAndToAllOrgs}
          onRejection={this.applyChangesToDefaultAccountData}
        />
      }
      <div className="MyDataDefaultsForm-controls">
        {controls}
      </div>
      <div className="MyDataDefaultsForm-main">
        <ErrorMessage
          className="MyDataDefaultsForm-error"
          error={
            errorLoadingDefaultAccountData ||
            updatingDefaultAccountDataError ||
            errorApplyingDefaultAccountDataToAllOrgs ||
            errorCommittingDefaultAccountDataStagedChangesToAllOrgs
          }
        />
        {explanation}
        <HeaderedContentWithUnsaved
          header="My Standard Info"
          unsaved={
            defaultAccountDataStagedChanges && (
              defaultAccountDataStagedChanges.personal_data ||
              defaultAccountDataStagedChanges.shared_personal_data
            )
          }
        >
          <AliceDefaultPersonalDataForm
            disabled={disabled}
            customPersonalDataFields={customPersonalDataFields}
            personalData={defaultAccountData.personal_data}
            personalDataStagedChanges={
              defaultAccountDataStagedChanges &&
              defaultAccountDataStagedChanges.personal_data
            }
            onPersonalDataChange={personal_data => {
              this.stageDefaultAccountDataChanges({ personal_data })
            }}
            sharedPersonalData={defaultAccountData.shared_personal_data}
            sharedPersonalDataStagedChanges={
              defaultAccountDataStagedChanges &&
              defaultAccountDataStagedChanges.shared_personal_data
            }
            onSharedPersonalDataChange={shared_personal_data => {
              this.stageDefaultAccountDataChanges({ shared_personal_data })
            }}
          />
        </HeaderedContentWithUnsaved>
        <HeaderedContentWithUnsavedAndHelpButton
          header="Communication Preferences"
          unsaved={
            defaultAccountDataStagedChanges &&
            defaultAccountDataStagedChanges.communication_channels
          }
          helpText={COMMUNICATION_CHANNELS_HELP}
        >
          <AliceDefaultCommunicationChannelsForm
            disabled={disabled}
            defaultCommunicationChannels={defaultAccountData.communication_channels}
            defaultCommunicationChannelsStagedChanges={
              defaultAccountDataStagedChanges &&
              defaultAccountDataStagedChanges.communication_channels
            }
            onChange={communication_channels => {
              this.stageDefaultAccountDataChanges({ communication_channels })
            }}
          />
        </HeaderedContentWithUnsavedAndHelpButton>
        <HeaderedContentWithUnsaved
          header="My Standard GDPR Permissions"
          unsaved={areThereStagedConsentChanges({
            type: 'GDPR',
            changes: (
              defaultAccountDataStagedChanges &&
              defaultAccountDataStagedChanges.consents
            ),
          })}
        >
          <AliceConsentsForm
            disabled={disabled}
            consents={defaultAccountData.consents}
            stagedChanges={
              defaultAccountDataStagedChanges &&
              defaultAccountDataStagedChanges.consents
            }
            onChange={consents => {
              this.stageDefaultAccountDataChanges({ consents })
            }}
            type="GDPR"
          />
        </HeaderedContentWithUnsaved>
        {/*<HeaderedContentWithUnsaved
          header="My Standard CCPA Permissions"
          unsaved={areThereStagedConsentChanges({
            type: 'CCPA',
            changes: (
              defaultAccountDataStagedChanges &&
              defaultAccountDataStagedChanges.consents
            ),
          })}
        >
          <AliceConsentsForm
            disabled={disabled}
            consents={defaultAccountData.consents}
            stagedChanges={
              defaultAccountDataStagedChanges &&
              defaultAccountDataStagedChanges.consents
            }
            onChange={consents => {
              this.stageDefaultAccountDataChanges({ consents })
            }}
            type="CCPA"
          />
        </HeaderedContentWithUnsaved>*/}
      </div>
    </HeaderedContentBox>
  }
}

const changesSummary = function({ defaultAccountData, defaultAccountDataStagedChanges }){
  const diff = diffAccountData(defaultAccountData, defaultAccountDataStagedChanges)
  const changes = []
  for(const section in diff){
    for(const field in diff[section]){
      const value = diff[section][field]
      changes.push(changeSummary({ section, field, value }))
    }
  }
  return <div className="MyDataDefaultsForm-changeSummary">{changes}</div>
}

const changeSummary = function({ section, field, value }){
  const key = `${section}-${field}`
  if (section === 'shared_personal_data'){
    const label = getLabelForPersonalDataField(field)
    return <div key={key}>
      <Header size="sm">{label}:</Header> {value ? 'shared' : 'unshared'}
    </div>
  }
  if (section === 'personal_data'){
    const label = getLabelForPersonalDataField(field)
    return <div key={key}>
      <Header size="sm">{label}:</Header> changed to "{value}"
    </div>
  }
  if (section === 'consents'){
    return <div key={key}>
      <Header size="sm">{field} consent:</Header> {value ? 'enabled' : 'disabled'}
    </div>
  }
  if (section === 'communication_channels'){
    return <div key={key}>
      <Header size="sm">
        {COMMUNICATION_CHANNEL_TITLES[field]} communication channel:
      </Header>
      {value ? 'enabled' : 'disabled'}
    </div>
  }
}


function extractCustomPersonalDataFields({ myOrganizations, defaultAccountData }){
  const customPersonalDataFields = new Set()
  Object.values(myOrganizations).forEach(organization => {
    Object.keys(organization.requested_data).forEach(key => {
      customPersonalDataFields.add(key)
    })
  })
  Object.keys(defaultAccountData.personal_data).forEach(key => {
    customPersonalDataFields.add(key)
  })
  Object.keys(defaultAccountData.shared_personal_data).forEach(key => {
    customPersonalDataFields.add(key)
  })
  customPersonalDataFields.forEach(key => {
    if (!isCustomPersonalDataField(key)) customPersonalDataFields.delete(key)
  })
  return Array.from(customPersonalDataFields)
}

/*
 * This is a temporary hack to bind to mySISAs
 * and then also bind to each organization in that array
 * without having to touch the complicated
 * MyDataDefaultsForm class
 */
export default function(){
  const appState = useAppState(
    [
      'accountDataSectionCollapsed:communicationChannels', // communicationChannelsCollapsed,
      'accountDataSectionCollapsed:personalData', // personalDataCollapsed,
      'accountDataSectionCollapsed:GDPRConsents', // GDPRConsentsCollapsed,
      'accountDataSectionCollapsed:CCPAConsents', // CCPAConsentsCollapsed,
      'loadingDefaultAccountData',
      'errorLoadingDefaultAccountData',
      'defaultAccountData',
      'defaultAccountDataStagedChanges',
      'updatingDefaultAccountDataError',
      'updatingDefaultAccountData',
      'mySISAs',
      'applyingDefaultAccountDataToAllOrgs',
      'errorApplyingDefaultAccountDataToAllOrgs',
      'committingDefaultAccountDataStagedChangesToAllOrgs',
      'errorCommittingDefaultAccountDataStagedChangesToAllOrgs',
      'stagingOrganizationAccountDataForDefaultAccountData',
    ],
    'MyDataDefaultsForm',
  )
  return <OrganizationsBinder {...appState} />
}

function OrganizationsBinder({mySISAs, ...props}){
  mySISAs = [...(mySISAs || [])]
  const appState = useAppState(
    mySISAs.map(organizationApikey => `organization:${organizationApikey}`),
    'MyDataDefaultsForm',
  )
  const myOrganizations = {}
  mySISAs.map(organizationApikey => {
    const organization = appState[`organization:${organizationApikey}`]
    if (organization) myOrganizations[organizationApikey] = organization
  })
  return <MyDataDefaultsForm {...props} myOrganizations={myOrganizations} />
}
