import { useCallback, useEffect } from 'preact/hooks'

export default function useOnResize(ref, onResize, args = []){
  const _onResize = useCallback(onResize, args)
  useEffect(
    () => {
      const node = ref.current
      const observer = new window.ResizeObserver(_onResize)
      observer.observe(node)
      return () => {
        observer.unobserve(node)
      }
    },
    [_onResize]
  )
}
