import { h } from 'preact'
import { useRef, useErrorBoundary } from 'preact/hooks'
import PNFO from 'jlinc-shared/PNFO'

import classNames from 'lib/classNames'
import sortBy from 'lib/sortBy'
import { useAppState } from 'lib/appState'
import { useNotifications } from 'lib/notificationsHooks'
import { useOrganization, usePublicProfile } from 'lib/membershipAppStateHooks'
import useOnScrolledIntoView from 'lib/useOnScrolledIntoViewHook'
import { publicProfileToDisplayName } from 'lib/publicProfiles'

import Link from 'components/Link'
import TimeAgo from 'components/TimeAgo'
import _OrganizationIcon from 'components/OrganizationIcon'
import InfiniteScrollDown from 'components/InfiniteScrollDown'
import ErrorMessage from 'components/ErrorMessage'
import './index.sass'


export default function NotificationsList({ onNotificationClick }) {
  const {
    notifications,
    loadingNotifications,
    loadingNotificationsError,
    lastPageOfNotifications,
    loadNotifications,
  } = useNotifications('NotificationsList')

  return <InfiniteScrollDown
    className="NotificationsList"
    name="notifications"
    loadMore={loadNotifications}
    loading={loadingNotifications}
    loadingError={loadingNotificationsError}
    fullyLoaded={lastPageOfNotifications}
    loaderSize="md"
    emptyMessage={`You have no notifications yet`}
    fullyLoadedMessage={`You have no more notifications`}
  >
    {sortBy(notifications, [n => -(new Date(n.createdAt)).getTime()])
      .map(notification =>
        <NotificationWithErrorCatching {...{
          key: notification.uid,
          notification,
          onClick: onNotificationClick,
        }}/>
      )
    }
  </InfiniteScrollDown>
}

const TYPES = {
  nodeMembershipInviteAccepted: ({ organizationApikey, inviteePublicProfileDid }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}/members`,
    message: <span>
      <PublicProfileName did={inviteePublicProfileDid}/>
      &nbsp;has accepted your invite to join&nbsp;
      <OrganizationName {...{organizationApikey}}/>
    </span>,
  }),
  nodeMembershipRequestResolved: ({ organizationApikey, accept}) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}`,
    message: <span>
      <OrganizationName {...{organizationApikey}}/> has {accept ? 'accepted' : 'declined'} your request to join
    </span>,
  }),
  nodeMembershipRoleChange: ({ organizationApikey, granted, role }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}/members`,
    message: <span>
      You are {granted ? 'now a' : 'no longer a'}&nbsp;
      {role} of <OrganizationName {...{organizationApikey}}/>
    </span>,
  }),
  requestedNodeMembership: ({ organizationApikey, requesterPublicProfileDid }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}/members`,
    message: <span>
      <PublicProfileName did={requesterPublicProfileDid}/>
      &nbsp;requested to join&nbsp;
      <OrganizationName {...{organizationApikey}}/>
    </span>,
  }),
  sisaPermissionChanged: ({ organizationApikey, sisaEventId }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}/sisa-events/${sisaEventId}`,
    message: <span>
      You updated permissions under your SISA with&nbsp;
      <OrganizationName {...{organizationApikey}}/>
    </span>,
  }),
  sisaPersonalDataChanged: ({ organizationApikey, sisaEventId }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}/sisa-events/${sisaEventId}`,
    message: <span>
      You updated your personal data under your SISA with&nbsp;
      <OrganizationName {...{organizationApikey}}/>
    </span>,
  }),
  inviteToJoinOrganization: ({ organizationApikey }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}/join`,
    message: <span>
      <OrganizationName {...{organizationApikey}}/>
      &nbsp;has invited you to join their {PNFO.singular}
    </span>,
  }),
  inviteToJoinOrganizationNetwork: ({ organizationApikey, networkOrganizationApikey }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${organizationApikey}/admin/networks/invites`,
    message: <span>
      <OrganizationName {...{organizationApikey}}/>
      &nbsp;has been invited to be listed on&nbsp;
      <OrganizationName organizationApikey={networkOrganizationApikey}/>'s Network
    </span>,
  }),
  inviteToJoinOrganizationNetworkResolved: ({ organizationApikey, memberOrganizationApikey, accepted }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: (accepted
      ? `/${organizationApikey}/network/${memberOrganizationApikey}`
      : `/${organizationApikey}/admin/network/invite?q=` +
        encodeURIComponent(memberOrganizationApikey)
    ),
    message: <span>
      <OrganizationName organizationApikey={memberOrganizationApikey}/>
      &nbsp;has {accepted ? 'accepted' : 'declined'} your invitation to be listed on&nbsp;
      <OrganizationName {...{organizationApikey}}/>'s Network
    </span>,
  }),
  removedFromOrganizationNetwork: ({ organizationApikey, networkOrganizationApikey }) => ({
    icon: <OrganizationIcon {...{organizationApikey}} />,
    href: `/${networkOrganizationApikey}/network`,
    message: <span>
      <OrganizationName {...{organizationApikey}}/>
      &nbsp;has been unlisted from&nbsp;
      <OrganizationName organizationApikey={networkOrganizationApikey}/>'s Network
    </span>,
  }),
}

function NotificationWithErrorCatching({ notification, ...props }){
  const [error, onDismiss] = useErrorBoundary()
  if (error) {
    console.error(error)
    return <ErrorMessage {...{error, onDismiss}} />
  }
  if (notification.type in TYPES){
    const {icon, href, message} = TYPES[notification.type](notification)
    return <Notification {...{...props, notification, icon, href, message}}/>
  }
  return <Notification {...{
    notification,
    message: `ERROR: unknown notification type=${notification.type}`,
  }}/>
}

function Notification({
  notification: { type, uid, seenAt, createdAt },
  icon, href, message, onClick,
}) {
  const unread = !seenAt
  const ref = useRef()
  const { takeAction } = useAppState(undefined, 'NotificationsList')

  useOnScrolledIntoView(
    ref,
    () => { if (unread) takeAction('notifications.markAsRead', uid) },
    [uid, unread]
  )

  return <Link {...{
    className: classNames('NotificationsList-Notification', { unread, [type]: 1 }),
    ref, href, onClick,
  }}>
    <div>{icon}</div>
    <div>
      <div>{message}</div>
      <TimeAgo time={createdAt} />
    </div>
  </Link>
}

function OrganizationName({ organizationApikey }){
  const { organization } = useOrganization(organizationApikey, 'NotificationsList')
  return <span>{(organization && organization.name) || organizationApikey}</span>
}

function PublicProfileName({ did }){
  const { publicProfile = {} } = usePublicProfile(did, 'NotificationsList')
  return <span>{publicProfileToDisplayName(publicProfile)}</span>
}

function OrganizationIcon({ organizationApikey }){
  const { organization } = useOrganization(organizationApikey, 'NotificationsList')
  return <_OrganizationIcon {...{organization}}/>
}
