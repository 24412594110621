import { h, Fragment } from 'preact'

import Link from 'components/Link'

export default function JlincTerms() {
  return <Fragment>
    <p>
      {LEGAL_APP_NAME} provides web software under the Terms below.
    </p>
    <p>
      Upon signing up to create an account at {location.host}, {LEGAL_APP_NAME} shall serve as a personal
      "Data Custodian" acting on your behalf.
    </p>
    <p>
      {APP_NAME} provides you, the account holder, with an automated interface to control and manage digitally
      signed data sharing agreements with Data Custodians representing businesses and other entities using
      the <Link href="https://www.jlinc.com/" type="link">JLINC protocol</Link>.
    </p>
    <p>
      {APP_NAME} shall take all reasonable precautions to keep your data secure and to only transfer your data,
      permissions, consents and preferences, to other parties as directly instructed by you.
    </p>
    <p>
      Signing up on {APP_NAME} represents your affirmative agreement to these Terms. The exact language of these Terms
      may be modified. However, the substance of the intent shall not be changed without obtaining affirmative
      permission from you in a clear, simple and understandable manner.
    </p>
    <p>
      {LEGAL_APP_NAME} is a California benefit corporation with both data integrity and environmental protection
      written into its founding articles.
    </p>
  </Fragment>
}
