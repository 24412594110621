import { h } from 'preact'
import { useCallback, useEffect } from 'preact/hooks'

import { useAppState } from 'lib/appState'
import { useSisa } from 'lib/membershipAppStateHooks'
import { useLocation, setLocation, replaceLocation } from 'resources/location'

import ErrorMessage from 'components/ErrorMessage'
import Link from 'components/Link'
import Button from 'components/Button'
import ButtonRow from 'components/ButtonRow'
import StyleishModal from 'components/StyleishModal'
import AliceOrganizationPersonalDataForm from 'components/AliceOrganizationPersonalDataForm'
import OrganizationDescription from 'components/OrganizationDescription'
import OrganizationInfoContentBox from 'components/OrganizationInfoContentBox'
import OrganizationAdminsList from 'components/OrganizationAdminsList'
import ParallelContentBoxes from 'components/ParallelContentBoxes'
import OrganizationSISAButton from 'components/OrganizationSISAButton'
import './index.sass'

export default function OrganizationHomePage(props){
  const {
    currentUser,
    organizationApikey,
    organization,
    isMember,
    isAdmin,
    isCurator,
  } = props

  const location = useLocation()
  const { iss } = location.query

  let {
    takeAction,
    appAction,
    sisaInvite,
    sisaInviteInvalid,
  } = useAppState(
    {
      invite: 'sisaInvite',
      inviteInvalid: 'sisaInviteInvalid',
    },
    'OrganizationHomePage'
  )

  const {
    errorCreatingMembership,
    errorRemovingMembership,
  } = useAppState(
    ['errorCreatingMembership', 'errorRemovingMembership'],
    'OrganizationDetailsBox',
  )

  const { sisa } = useSisa(organizationApikey, 'OrganizationHomePage')

  if (sisa || sisaInvite && sisaInvite.organizationApikey !== organization.apikey)
    sisaInvite = undefined
  if (sisa || sisaInviteInvalid && sisaInviteInvalid.organizationApikey !== organization.apikey)
    sisaInviteInvalid = undefined

  const SISAInviteModalOpen = !!(sisaInvite && iss === '1')
  const closeSISAInviteModal = useCallback(
    () => {
      setLocation(location.withQuery({iss:null}))
    },
    [],
  )
  const openSISAInviteModal = useCallback(
    () => {
      setLocation(location.withQuery({iss:1}))
    },
    [],
  )
  const declineSISAInvite = useCallback(
    () => {
      closeSISAInviteModal()
      takeAction('session.clearInvite')
    },
    [],
  )

  useEffect(
    () => {
      if (sisaInvite && iss !== '1')
        replaceLocation(location.withQuery({iss:1}))
      else if (!sisaInvite && iss === '1')
        replaceLocation(location.withQuery({iss:null}))
    },
    [] //only on mount
  )

  const sisaInviteInvalidError = sisaInviteInvalid && sisaInviteInvalid.error
  const dismissError = sisaInviteInvalid && appAction('session.clearInvite')

  const sisaButton = sisaInvite
    ? <Link type="text" onClick={openSISAInviteModal}>Share Data</Link>
    : !isDataYogi
      ? <OrganizationSISAButton {...{ organizationApikey: organization.apikey }} />
      : null

  const descriptionEditable = !!(isAdmin || isCurator)

  return <div className="OrganizationHomePage">
    {sisaInvite && <SISAInviteModal {...{
      organization,
      sisaInvite,
      open: SISAInviteModalOpen,
      onClose: closeSISAInviteModal,
      onDecline: declineSISAInvite,
    }}/> }
    <ErrorMessage
      error={
        sisaInviteInvalidError ||
        errorCreatingMembership ||
        errorRemovingMembership
      }
      onDismiss={dismissError}
    />
    {(descriptionEditable || organization.consumer_description) &&
      <OrganizationDescription {...{ organization, editable: descriptionEditable }}/>
    }
    <ParallelContentBoxes>
      <OrganizationInfoContentBox
        {...{organization, isAdmin}}
        buttons={!!currentUser && sisaButton}
        collapsable
      />
      {isDataYogi || <OrganizationAdminsList {...{
        organization,
        isMember,
      }}/>}
    </ParallelContentBoxes>
  </div>
}

function SISAInviteModal({ organization, sisaInvite, open, onClose, onDecline }){
  const organizationApikey = organization.apikey
  let {
    takeAction,
    signingSisaWith,
    errorSigningSisa,
  } = useAppState(
    [
      'signingSisaWith',
      'errorSigningSisa',
    ],
    'OrganizationHomePage.SISAInviteModal',
  )

  const signingSisa = signingSisaWith === organizationApikey
  const disabled = signingSisa

  const signSisa = () => {
    takeAction('signSisa', { organizationApikey })
  }

  return <StyleishModal {...{
    open,
    onClose,
    title: `${organization.name} invites you to sign a SISA`,
  }}>
    <p>{organization.name} has invited you to sign a SISA with them.</p>
    <PersonalDataInInvite {...{invite: sisaInvite, organization}}/>
    <ErrorMessage error={errorSigningSisa} />
    <ButtonRow>
      <Button
        disabled={disabled}
        type="normal"
        value="decline"
        onClick={onDecline}
      />
      <Button
        disabled={disabled}
        type="success"
        value={signingSisa ? 'Signing SISA…' : 'Sign SISA'}
        onClick={signSisa}
      />
    </ButtonRow>
  </StyleishModal>
}

function PersonalDataInInvite({ invite }){
  const { personalData } = invite
  if (!personalData || Object.keys(personalData).length === 0) return
  const sharedPersonalData = {}
  const requestedPersonalData = {}
  Object.keys(personalData).forEach(key => {
    sharedPersonalData[key] = true
    requestedPersonalData[key] = true
  })
  return <div>
    <p>Here is the personal data they have on you:</p>
    <AliceOrganizationPersonalDataForm {...{
      personalData: invite.personalData,
      sharedPersonalData, // ={arrayToObjectWithValue(SHARED_PERSONAL_DATA_KEYS, true)}
      requestedPersonalData, // ={requestedPersonalData}
      disabled: true,
    }}/>
  </div>
}

