import { h, Fragment } from 'preact'

import { useOrganizationFeedPost } from 'lib/feedPostHooks'

import Loading from 'components/Loading'
import PageNotFound from 'components/PageNotFound'
import ContentBox from 'components/ContentBox'
import ErrorMessage from 'components/ErrorMessage'
import OrganizationPageSubnav from 'components/OrganizationPageSubnav'
import OrganizationFeedPost from 'components/OrganizationFeedPost'
import OrganizationFeedPostCommentCreateForm from 'components/OrganizationFeedPostCommentCreateForm'
import OrganizationFeedPostComments from 'components/OrganizationFeedPostComments'

import './index.sass'

export default function OrganizationChannelPostPage(props){
  const { params: { feedPostUid } } = props
  return <div className="OrganizationChannelPostPage">
    <PageContent {...{key: feedPostUid, feedPostUid, ...props}}/>
  </div>
}
OrganizationChannelPostPage.pageTitle = 'Internal Post'
OrganizationChannelPostPage.onAuthorization = ({ organization, isMember, loggedIn }) =>
  organization.is_private ? isMember : loggedIn

function PageContent(props){
  const {
    currentUser,
    organization,
    organizationApikey,
    feedPostUid,
    isMember,
  } = props

  const {
    feedPost,
    feedPostLoading,
    feedPostLoadingError,
    feedPostNotFound,
  } = useOrganizationFeedPost(
    feedPostUid,
    'OrganizationChannelPostPage',
  )

  if (feedPostLoadingError) return <ContentBox padded>
    <ErrorMessage error={feedPostLoadingError} />
  </ContentBox>

  if (feedPostNotFound) return <PageNotFound {...props}/>

  if (feedPostLoading || !feedPost) return <Loading />

  return <Fragment>
    <OrganizationPageSubnav {...{
      currentUser,
      isMember,
      icon: 'channel',
      name: 'Forum',
    }}/>
    <OrganizationFeedPost {...{post: feedPost}}/>
    <OrganizationFeedPostCommentCreateForm {...{
      disabled: !!feedPost.deletedAt,
      organization,
      feedPostUid,
      isMember,
    }}/>
    <OrganizationFeedPostComments {...{
      organizationApikey,
      feedPostUid,
    }}/>
  </Fragment>
}
