import { useEffect } from 'preact/hooks'
import { refToDOMNode } from 'lib/preactHelpers'
import { focusWithin } from 'lib/DOMHelpers'

export default function useAutoFocus(ref, args = []){
  useEffect(
    () => { if (ref && ref.current) useAutoFocus.focusRef(ref) },
    [ref, ...args]
  )
}
useAutoFocus.focusRef = ref => {
  const node = refToDOMNode(ref)
  if (node) focusWithin(node)
}
