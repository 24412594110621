import { h, Fragment } from 'preact'

import { useMyPublicProfileDid } from 'resources/auth'
import {
  useMyOrganizationMembership,
  useAcceptedMembershipsForOrganization,
  usePublicProfiles,
  useRemoveOrganizationMembership,
} from 'lib/membershipAppStateHooks'
import useToggle from 'lib/useToggleHook'
import {
  publicProfileToDisplayName,
  publicProfileToPathname,
} from 'lib/publicProfiles'
import classNames from 'lib/classNames'

import IconButton from 'components/IconButton'
import OrganizationMembershipEditForm from 'components/OrganizationMembershipEditForm'
import OrganizationMembershipPills from 'components/OrganizationMembershipPills'
import IconRowAccordion from 'components/IconRowAccordion'
import PeopleListMember from 'components/PeopleListMember'
import EndUserBanner from 'components/EndUserBanner'
import PublicProfileInfo from 'components/PublicProfileInfo'
import Timestamp from 'components/Timestamp'
import StyleishModal from 'components/StyleishModal'
import EndUserAvatar from 'components/EndUserAvatar'
import IconRow from 'components/IconRow'
import LinkToPublicProfile from 'components/LinkToPublicProfile'
import Button from 'components/Button'
import Header from 'components/Header'
import HeaderedContent from 'components/HeaderedContent'
import HeaderedContentBox from 'components/HeaderedContentBox'
import ChatButton from 'components/ChatButton'

import './index.sass'

export default function OrganizationMembersList({
  header,
  collapsable,
  organization,
  canEdit,
  admins,
  selectedUsername,
  pathPrefix = `/${organization.apikey}/members`,
  className = '',
  withContentBox = true,
  membershipPills = true,
}) {
  const { isAdmin, isCurator } = useMyOrganizationMembership(organization.apikey, 'OrganizationMembersList')
  if (typeof canEdit === 'undefined') canEdit = (isAdmin || isCurator)

  const myPublicProfileDid = useMyPublicProfileDid()
  const loggedIn = !!myPublicProfileDid
  const {
    removingMembership,
    removeMembership,
  } = useRemoveOrganizationMembership('OrganizationMembersList')
  const {
    acceptedOrganizationMemberships,
  } = useAcceptedMembershipsForOrganization(
    organization.apikey,
    'OrganizationMembersList'
  )
  const publicProfileDids = []
  acceptedOrganizationMemberships.forEach(organizationMembership => {
    if (
      organizationMembership.organizationApikey === organization.apikey &&
      organizationMembership.memberUserDid
    ) publicProfileDids.push(organizationMembership.memberUserDid)
  })
  const { publicProfiles } = usePublicProfiles(publicProfileDids, 'OrganizationMembersList')

  const disabled = !!removingMembership

  const people = publicProfiles
    .map(publicProfile => {
      const organizationMembership = acceptedOrganizationMemberships
        .find(organizationMembership => organizationMembership.memberUserDid === publicProfile.did)
      return {publicProfile, organizationMembership}
    })
    .filter(({organizationMembership, publicProfile}) => {
      if (!publicProfile.username) return false
      const isAdmin = organizationMembership.admin || organizationMembership.curator
      return admins ? isAdmin : !isAdmin
    })
    .sort((a, b) => {
      a = a.organizationMembership
      b = b.organizationMembership
      return (
        (a.admin && !b.admin) ? -1 : (!a.admin && b.admin) ? 1 :
        (a.curator && !b.curator) ? -1 : (!a.curator && b.curator) ? 1 :
        a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 :
        0
      )
    })
    .map(({publicProfile, organizationMembership}) => {
      const myMembership = organizationMembership.memberUserDid === myPublicProfileDid
      const linkToPublicProfile = publicProfileToPathname(publicProfile)
      const buttons = []
      if (canEdit){
        buttons.push(
          <EditMembershipButton {...{key: 'edit', publicProfile, organizationMembership}}/>
        )
        buttons.push(
          <IconButton {...{
            className: 'OrganizationMembersList-removeMembershipButton',
            key: 'remove',
            disabled: myMembership || disabled || organizationMembership.admin,
            type: 'cancel-circled',
            onClick: () => {
              if (myMembership || organizationMembership.admin) return
              if (!confirm(`Are you sure you want to remove ${publicProfile.username}?`)) return // eslint-disable-line
              removeMembership(organizationMembership)
            },
          }}/>
        )
      }
      buttons.push(
        <ChatButton
          organizationApikey={organizationMembership.organizationApikey}
          publicProfileDid={publicProfile.did}
        />
      )
      buttons.push(
        <Button
          className="OrganizationMembersList-visit"
          type="normal"
          value="visit"
          disabled={!linkToPublicProfile}
          href={linkToPublicProfile}
        />
      )
      return {
        publicProfile,
        organizationMembership,
        buttons,
        suffix: membershipPills && <OrganizationMembershipPills {...{organizationMembership}}/>
      }
    })

  const expandedIndex = selectedUsername
    ? people.findIndex(person =>
      person.publicProfile.username === selectedUsername
    )
    : -1

  const headerButtons = []

  const HeaderComponent = withContentBox ? HeaderedContentBox : HeaderedContent
  return <HeaderComponent {...{
    header, collapsable, buttons: headerButtons, className: classNames('OrganizationMembersList', { className }),
  }}>
    {people.length === 0
      ? <p>
        <Header size="sm" centered italic>
          {organization.name} has no {admins ? 'admins' : 'members'}
        </Header>
      </p>
      : <IconRowAccordion {...{expandedIndex}}>
        {people.map((person, index) =>
          <div>
            <PeopleListMember {...{
              ...person,
              href: (
                `${pathPrefix}` +
                (index === expandedIndex ? '' : `/@${person.publicProfile.username}`)
              ),
            }}/>
            <PersonPreview {...{loggedIn, ...person}}/>
          </div>
        )}
      </IconRowAccordion>
    }
  </HeaderComponent>
}

function PersonPreview({
  loggedIn, publicProfile, organizationMembership
}){
  return <div className="OrganizationMembersList-preview">
    <LinkToPublicProfile {...{publicProfile}}>
      <EndUserBanner {...{publicProfile}}/>
    </LinkToPublicProfile>
    {loggedIn &&
      <PublicProfileInfo {...{
        publicProfile,
        extraDetails: [
          ['Joined on', <Timestamp time={organizationMembership.createdAt} format="MMM Do YYYY"/>],
        ],
      }}/>
    }
  </div>
}

function EditMembershipButton({ organizationMembership, publicProfile }){
  const [isOpen, open, close] = useToggle(false)
  return <Fragment>
    <IconButton {...{
      className: 'OrganizationMembersList-EditMembershipButton',
      type: 'edit',
      onClick: open,
    }}/>
    <StyleishModal
      open={isOpen}
      onClose={close}
      title={
        <IconRow
          icon={<EndUserAvatar size="sm" publicProfile={publicProfile} />}
          row={`${publicProfileToDisplayName(publicProfile)}'s Roles`}
        />
      }
    >
      <OrganizationMembershipEditForm {...{
        organizationMembership,
        publicProfile,
        onSuccess: close,
      }}/>
    </StyleishModal>
  </Fragment>
}
