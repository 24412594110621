export function publicProfileToDisplayName({ displayName = '', username }){
  return (
    displayName.trim() ||
    (username && `@${username}`) ||
    '[user public profile creation in process]'
  )
}

export function publicProfileToPathname({ username, asMemberOf }){
  if (username) return asMemberOf
    ? `/${asMemberOf}/members/@${username}`
    : `/@${username}`
}
