import { h, Fragment } from 'preact'
import { useState, useCallback, useMemo } from 'preact/hooks'
import { memo } from 'preact/compat'
import PropTypes from 'prop-types'

import {
  repostFeedPostToForum,
  repostFeedPostToPublicProfile,
} from 'jlinc-shared/feedPosts'

import classNames from 'lib/classNames'
import {
  feedPostPropType,
  postToPathname,
  isValidVisibleToInt,
} from 'lib/feedPostHelpers'
import { useRepostFeedPost } from 'lib/feedPostHooks'
import {
  useMyOrganizationMemberships,
  useOrganizations,
  useMyPublicProfile,
} from 'lib/membershipAppStateHooks'
import { setLocation } from 'resources/location'

import StyleishModal from 'components/StyleishModal'
import FormBuilder from 'components/FormBuilder'
import Button from 'components/Button'
import Form from 'components/Form'
import Dropdown from 'components/Dropdown'
import Loading from 'components/Loading'
import FeedPostVisibilityDropdown from 'components/FeedPostVisibilityDropdown'
import OrganizationFeedPost from 'components/OrganizationFeedPost'
import HelpButton from 'components/HelpButton'
import FeedPostFormWithPreview from 'components/FeedPostFormWithPreview'

export default function FeedPostRepostModal({
  className,
  post,
  title,
  open,
  onClose
}){
  if (!title) title = `Repost ${post && post.title ? `"${post.title}"` : 'Feed Post'}`
  return <StyleishModal {...{
    padded: false,
    className: classNames('FeedPostRepostModal', { className }),
    title,
    open,
    onClose,
  }}>
    <Contents {...{onClose, post}}/>
  </StyleishModal>
}

FeedPostRepostModal.propTypes = {
  className: PropTypes.string,
  post: feedPostPropType.isRequired,
  title: StyleishModal.propTypes.title,
  open: StyleishModal.propTypes.open,
  onClose: StyleishModal.propTypes.onClose,
}

const Contents = memo(({ onClose, post }) => {
  const { myOrganizationMemberships } =
    useMyOrganizationMemberships('OrganizationFeedPost.RepostModal')

  const organizationsICouldRepostTo = []
  myOrganizationMemberships.forEach(membership => {
    if (membership.organizationApikey && membership.organizationApikey !== post.feedOrganizationApikey)
      organizationsICouldRepostTo.push(membership.organizationApikey)
  })

  const { myPublicProfile } = useMyPublicProfile('OrganizationFeedPost.RepostModal')
  const { organizations } = useOrganizations(
    organizationsICouldRepostTo, 'OrganizationFeedPost.RepostModal'
  )

  const organizationsICanPostAs = myOrganizationMemberships
    .filter(membership => membership.admin || membership.curator)
    .map(membership => membership.organizationApikey)

  const canPostToOwnFeed = !post.feedUserDid || post.feedUserDid !== myPublicProfile.did

  const postToOptions = useMemo(
    () => {
      const postToOptions = {}

      if (canPostToOwnFeed)
        postToOptions[`@${myPublicProfile.username}`] = undefined

      organizationsICouldRepostTo.forEach(organizationApikey => {
        if (post.feedOrganizationApikey && organizationApikey.apikey === post.feedOrganizationApikey) return
        const organization = organizations.find(o => o.apikey === organizationApikey)
        if (organization) postToOptions[organization.name] = organization.apikey
      })
      return postToOptions
    },
    [
      post,
      myPublicProfile,
      organizations.length,
      ...organizationsICouldRepostTo.map(o => o.apikey).sort(),
    ],
  )

  const maxMaxVisibleTo = Math.max(0, post.maxVisibleTo)
  let [state, setState] = useState({
    to: Object.values(postToOptions)[0],
    as: undefined,
    visibleTo: maxMaxVisibleTo,
  })

  const {
    repostFeedPost,
    repostingFeedPost,
    errorRepostingFeedPost,
    createdFeedPost,
  } = useRepostFeedPost(
    post,
    'OrganizationFeedPost.RepostModal',
  )

  const postAsOptions = useMemo(
    () => {
      const postAsOptions = {
        [`@${myPublicProfile.username}`]: undefined,
      }
      const feedOrganizationApikey = state.to
      if (
        feedOrganizationApikey &&
        organizationsICanPostAs.includes(feedOrganizationApikey)
      ){
        const organization = organizations.find(o => o.apikey === feedOrganizationApikey)
        postAsOptions[organization.name] = feedOrganizationApikey
      }
      return postAsOptions
    },
    [postToOptions, state.to],
  )

  const onChange = useCallback((newValue, changes) => {
    if ('to' in changes) newValue.as = undefined
    if (changes.to) newValue.visibleTo = 0
    else if (newValue.visibleTo < 2) newValue.visibleTo = 2
    setState(newValue)
  })

  const onSubmit = useCallback(
    state => {
      const {
        to: feedOrganizationApikey,
        as: posterOrganizationApikey,
        visibleTo,
      } = state
      repostFeedPost({
        feedOrganizationApikey,
        posterOrganizationApikey,
        visibleTo,
      })
    },
    [post.uid, repostFeedPost]
  )

  const onSuccess = useCallback(
    () => {
      if (createdFeedPost && myPublicProfile)
        setLocation(postToPathname(createdFeedPost, myPublicProfile))
    },
    [createdFeedPost],
  )

  if (organizations.length < organizationsICouldRepostTo.length) return <Loading type="block" />

  let postPreview = state.to
    ? repostFeedPostToForum({
      post: {ancestors: [], ...post},
      posterUserDid: myPublicProfile.did,
      feedOrganizationApikey: state.to,
      posterOrganizationApikey: state.as,
    })
    : repostFeedPostToPublicProfile({
      post: {ancestors: [], ...post},
      posterUserDid: myPublicProfile.did,
      visibleTo: state.visibleTo,
    })

  const visibleToMin = state.to ? 0 : Math.min(2, post.maxVisibleTo)
  const visibleToMax = state.to ? 0 : maxMaxVisibleTo
  const hasVisibleToOptions = (
    canPostToOwnFeed &&
    visibleToMax - visibleToMin > 0
  )

  return <FormBuilder {...{
    className: 'FeedPostRepostModal-form',
    value: state,
    onChange,
    submittable: !!(
      (canPostToOwnFeed || state.to) &&
      isValidVisibleToInt(state.visibleTo)
    ),
    onSubmit,
    submitting: !!repostingFeedPost,
    error: errorRepostingFeedPost,
    onSuccess,
    render: form => (
      <FeedPostFormWithPreview>
        <FeedPostFormWithPreview.ScrollArea>
          <Form.Row>
            {form.item({
              valueProp: 'to',
              label: 'POST TO',
              input: <Dropdown />,
              options: postToOptions,
            })}
            {Object.keys(postAsOptions).length > 1 &&
              form.item({
                valueProp: 'as',
                label: 'POST AS',
                input: <Dropdown />,
                options: postAsOptions,
              })
            }
          </Form.Row>
          <Form.Label>PREVIEW</Form.Label>
          <OrganizationFeedPost {...{
            disabled: true,
            post: postPreview,
          }}/>
        </FeedPostFormWithPreview.ScrollArea>
        <FeedPostFormWithPreview.ButtonRow>
          {hasVisibleToOptions &&
            <Fragment>
              <VisibleToHelpButton/>
              {form.bindInput({
                valueProp: 'visibleTo',
                placeholder: 'Visible To',
                input: <FeedPostVisibilityDropdown />,
                min: visibleToMin,
                max: visibleToMax,
              })}
            </Fragment>
          }
          <Button
            disabled={form.disabled}
            type="normal"
            value="cancel"
            onClick={onClose}
          />
          {form.submitButton({
            value: 'Repost',
            submittingValue: 'Reposting…',
          })}
        </FeedPostFormWithPreview.ButtonRow>
      </FeedPostFormWithPreview>
    ),
  }}/>
})

const VisibleToHelpButton = () =>
  <HelpButton title="Post Visibility">
    <p>
      Ullamco reprehenderit sunt amet et occaecat duis deserunt exercitation
      amet commodo proident nostrud excepteur duis nulla cupidatat duis ad
      exercitation nostrud ut est pariatur dolor aliquip nulla ex in amet.
    </p>
  </HelpButton>
