import { h } from 'preact'
import { useRef, useMemo, useEffect } from 'preact/hooks'
import PropTypes from 'prop-types'

import { createSharedState } from 'lib/preactHelpers'
import { useMediaBreakpoint } from 'lib/mediaBreakpointHelpers'
import { useLocalStorage } from 'lib/storageHooks'
import { useMyPublicProfile } from 'lib/membershipAppStateHooks'
import usePrevious from 'lib/usePreviousHook'
import { getRecentOrganizations } from 'lib/recentOrganizations'
import useHasSeenInterfaceWalkthrough from 'lib/useHasSeenInterfaceWalkthroughHook'
import { useLocation, setLocation, replaceLocation } from 'resources/location'

import InterfaceWalkthrough from 'components/InterfaceWalkthrough'
import STEPS from './steps'

const STEP_INDEX_PARAM = 'iw'
const ORG_APIKEY_PARAM = 'o'
const DEFAULT_ORGANIZATION_APIKEY = 'TruSocial' // 'TruWelcome',

const useOnOrganizationPage = createSharedState()

export function useAPortalInterfaceWalkthroughInitiator(organizationApikey){
  if (!organizationApikey) throw new Error('organizationApikey is required')
  const onOrganizationPage = useOnOrganizationPage()[1]
  useEffect(
    () => {
      onOrganizationPage(organizationApikey)
      return () => { onOrganizationPage(null) }
    },
    [organizationApikey]
  )
}

export default function APortalInterfaceWalkthrough(){
  const onOrganizationPage = useOnOrganizationPage()[0]

  const {
    hasSeenInterfaceWalkthrough: seen,
    setHasSeenInterfaceWalkthrough: setSeen,
  } = useHasSeenInterfaceWalkthrough()
  const [neverAgain, setNeverAgain] = useLocalStorage('APortalInterfaceWalkthrough.neverAgain')
  const location = useLocation()

  const organizationApikeyRef = useRef()
  useEffect(
    () => {
      organizationApikeyRef.current = (
        location.query[ORG_APIKEY_PARAM] ||
        onOrganizationPage ||
        organizationApikeyRef.current ||
        getRecentOrganizations()[0]
      )
    },
    [onOrganizationPage, location]
  )
  const organizationApikey = organizationApikeyRef.current || DEFAULT_ORGANIZATION_APIKEY

  const { narrowerThanPhone: isMobile } = useMediaBreakpoint('APortalInterfaceWalkthrough')
  const { myPublicProfile } = useMyPublicProfile('APortalInterfaceWalkthrough')

  const organizationJoinModalIsOpen = (
    onOrganizationPage &&
    location.query.join === '1' // are there other modals?
  )
  const organizationJoinModalWasOpen = usePrevious(organizationJoinModalIsOpen)
  const organizationJoinModalJustClosed =
    organizationJoinModalWasOpen && !organizationJoinModalIsOpen
  const currentStepIndex = STEP_INDEX_PARAM in location.query && parseInt(location.query[STEP_INDEX_PARAM], 10)
  const canOpen = !!(
    !isMobile &&  // not on mobile
    myPublicProfile && // logged in
    !organizationJoinModalIsOpen &&
    (
      typeof currentStepIndex === 'number' ||
      (!seen && organizationJoinModalJustClosed)
    )
  )

  if (canOpen) return <Walkthrough {...{
    seen,
    setSeen,
    neverAgain,
    setNeverAgain,
    location,
    setLocation,
    replaceLocation,
    myPublicProfile,
    onOrganizationPage,
    organizationApikey,
    currentStepIndex,
  }}/>
}

APortalInterfaceWalkthrough.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
}

function Walkthrough({
  seen,
  setSeen,
  neverAgain,
  setNeverAgain,
  location,
  setLocation,
  replaceLocation,
  myPublicProfile,
  onOrganizationPage,
  organizationApikey,
  currentStepIndex,
}){

  const isOpen = typeof currentStepIndex === 'number'
  const shouldOpen = (
    !seen &&
    !neverAgain &&
    !isOpen &&
    onOrganizationPage
  )

  useEffect(
    () => { if (shouldOpen) open() },
    [shouldOpen],
  )

  const {getLinkToStep, open, onClose, onNeverAgain} = useMemo(
    () => {
      const getLinkToStep = step => {
        if (!step) return location.merge({
          query: {
            [STEP_INDEX_PARAM]: null,
            [ORG_APIKEY_PARAM]: null
          },
        })
        let pathname = location.pathname
        let query = {
          [STEP_INDEX_PARAM]: step.id,
          [ORG_APIKEY_PARAM]: null,
        }
        if (
          organizationApikey &&
          organizationApikey !== DEFAULT_ORGANIZATION_APIKEY &&
          (!step.pathname || !step.pathname.startsWith('/:organizationApikey'))
        ) query[ORG_APIKEY_PARAM] = organizationApikey
        if (step.pathname){
          pathname = step.pathname
            .replace(/:username/g, myPublicProfile.username)
            .replace(/:organizationApikey/g, organizationApikey)
        }
        return location.merge({pathname, query})
      }
      const open = () => {
        setLocation(getLinkToStep(getStep(0)))
      }
      const close = () => {
        setLocation(getLinkToStep())
      }
      const onClose = () => {
        setSeen(true); close()
      }
      const onNeverAgain = () => {
        setNeverAgain(true); close()
      }
      return {getLinkToStep, open, onClose, onNeverAgain}
    },
    [location, organizationApikey, !!myPublicProfile]
  )

  const stepDots = useMemo(
    () => STEPS.map((_, index) => {
      const step = getStep(index)
      return {
        key: index,
        href: getLinkToStep(step),
        selected: index === currentStepIndex,
      }
    }),
    [getLinkToStep, currentStepIndex],
  )

  const step = useMemo(
    () => {
      if (!isOpen) return
      const step = getStep(currentStepIndex)
      if (step){
        step.closeButton = { onClick: onClose }
        step.neverAgainButton = { onClick: onNeverAgain }
        const prevStep = getStep(currentStepIndex - 1)
        if (prevStep) step.prevButton = { href: getLinkToStep(prevStep) }
        const nextStep = getStep(currentStepIndex + 1)
        if (nextStep) step.nextButton = { href: getLinkToStep(nextStep) }
        else step.doneButton = step.closeButton
      }
      return step
    },
    [getLinkToStep, isOpen, currentStepIndex],
  )

  useEffect(
    () => {
      if (!step) return
      const stepLocation = getLinkToStep(step)
      if (`${stepLocation}` !== `${location}`){
        replaceLocation(stepLocation)
      }
    },
    [location.pathname, step && step.pathname],
  )

  return <InterfaceWalkthrough {...{
    className: 'APortalInterfaceWalkthrough',
    stepDots,
    step,
  }}/>
}

const getStep = index => (
  typeof index === 'number' &&
  STEPS[index] &&
  {...STEPS[index], id: index}
)
