import { h, Component } from 'preact'
import PropTypes from 'prop-types'

import SwitchList from 'components/SwitchList'

import {
  VALID_COMMUNICATION_CHANNEL_KEYS,
  COMMUNICATION_CHANNELS_PROP_TYPE,
  DECORATED_COMMUNICATION_CHANNEL_TITLES,
} from 'lib/accountDataSpec'

export default class AliceDefaultCommunicationChannelsForm extends Component {

  static propTypes = {
    disabled: PropTypes.bool,
    className: PropTypes.string,
    defaultCommunicationChannels:COMMUNICATION_CHANNELS_PROP_TYPE.isRequired,
    defaultCommunicationChannelsStagedChanges: COMMUNICATION_CHANNELS_PROP_TYPE,
    onChange: PropTypes.func.isRequired,
  }

  render({
    disabled,
    className = '',
    defaultCommunicationChannels,
    defaultCommunicationChannelsStagedChanges = {},
    onChange,
  }){

    const content = VALID_COMMUNICATION_CHANNEL_KEYS
      .map(key => {
        const title = DECORATED_COMMUNICATION_CHANNEL_TITLES[key]
        const unsaved = key in defaultCommunicationChannelsStagedChanges
        const checked = unsaved
          ? isEnabled(defaultCommunicationChannelsStagedChanges, key)
          : isEnabled(defaultCommunicationChannels, key)
        return { key, title, checked, unsaved }
      })

    return <SwitchList
      className={`AliceDefaultCommunicationChannelsForm ${className}`}
      disabled={disabled}
      content={content}
      onChange={(index, enabled) => {
        onChange({ [content[index].key]: { enabled } })
      }}
    />
  }
}

const isEnabled = (communicationChannels, key) =>
  key in communicationChannels &&
  communicationChannels[key] &&
  !!communicationChannels[key].enabled
