import defer from 'lib/defer'

const LOCAL_EVENTS = new window.EventTarget()

const storageBus = {

  subscribe(onChange){
    LOCAL_EVENTS.addEventListener('storage', onChange)
    window.addEventListener('storage', onChange)
    return () => {
      LOCAL_EVENTS.removeEventListener('storage', onChange)
      window.removeEventListener('storage', onChange)
    }
  },

  getItem(storage, key){
    return storage[key]
  },

  setItem(storage, key, newValue){
    const oldValue = storage[key]
    if (newValue === oldValue) return
    if (newValue === undefined || newValue === null) {
      delete storage[key]
    }else{
      storage[key] = newValue
    }
    deferChange(storage, key, newValue, oldValue)
  },
}

export default storageBus


const deferredChangesByStorage = new Map()
function deferChange(storage, key, newValue, oldValue){
  let deferredChanges = deferredChangesByStorage.get(storage)
  if (!deferredChanges) deferredChangesByStorage.set(storage, deferredChanges = {})
  deferredChanges[key] = {
    oldValue, // oldest old
    ...(deferredChanges[key] || {}),
    newValue, // newest new
  }
  if (!handleDeferredChangesTimeout){
    handleDeferredChangesTimeout = defer(handleDeferredChanges)
  }
}

let handleDeferredChangesTimeout
function handleDeferredChanges(){
  handleDeferredChangesTimeout = undefined
  for (const storage of [window.sessionStorage, window.localStorage]){
    const deferredChanges = deferredChangesByStorage.get(storage)
    deferredChangesByStorage.delete(storage)
    if (!deferredChanges) continue
    for (const key in deferredChanges){
      const { newValue, oldValue } = deferredChanges[key]
      dispatchChangeEvent(storage, key, newValue, oldValue)
    }
  }
}

function dispatchChangeEvent(storage, key, newValue, oldValue){
  if (newValue === oldValue) return
  const changeEvent = new window.Event('storage')
  Object.assign(changeEvent, {
    storageArea: storage,
    key,
    oldValue,
    newValue,
  })
  LOCAL_EVENTS.dispatchEvent(changeEvent)
}
