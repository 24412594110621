import { h } from 'preact'
import PropTypes from 'prop-types'
import { useMemo } from 'preact/hooks'

import { useMyOrganizationFeedSubscriptions } from 'lib/feedSubscriptionHooks'

import OnlyWhenLoggedIn from 'components/OnlyWhenLoggedIn'
import Button from 'components/Button'

function UserSubscribeToFeedButton({ organizationApikey, disabled }) {
  const {
    subscribeToOrganizationFeed,
    subscribingToOrganizationFeed,
    unsubscribeFromOrganizationFeed,
    unsubscribingFromOrganizationFeed,
    myOrganizationFeedSubscriptions,
  } = useMyOrganizationFeedSubscriptions('UserSubscribeToFeedButton')

  const organizationFeedSubscription = (
    myOrganizationFeedSubscriptions &&
    myOrganizationFeedSubscriptions.find(subscription =>
      subscription.organizationApikey === organizationApikey
    )
  )

  const subscribing = (
    subscribingToOrganizationFeed &&
    subscribingToOrganizationFeed.organizationApikey === organizationApikey &&
    !subscribingToOrganizationFeed.subscriberOrganizationApikey
  )

  const unsubscribing = (
    unsubscribingFromOrganizationFeed &&
    unsubscribingFromOrganizationFeed === organizationFeedSubscription
  )

  const value = (
    subscribing ? 'Subscribing…' :
    unsubscribing ? 'Unsubscribing…' :
    organizationFeedSubscription ? 'Unsubscribe' :
    'Subscribe'
  )

  const onClick = useMemo(
    () =>
      organizationFeedSubscription
        ? () => { unsubscribeFromOrganizationFeed(organizationFeedSubscription) }
        : () => { subscribeToOrganizationFeed(organizationApikey) }
    ,
    [organizationFeedSubscription],
  )

  return <Button
    className="UserSubscribeToFeedButton"
    type={organizationFeedSubscription ? 'normal' : 'primary'}
    disabled={disabled || subscribing || unsubscribing}
    onClick={onClick}
    value={value}
  />
}

UserSubscribeToFeedButton.propTypes = {
  organizationApikey: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
}

export default OnlyWhenLoggedIn.wrap(UserSubscribeToFeedButton)
