let onNewServiceWorkerCallback

export function onNewServiceWorker(callback){
  onNewServiceWorkerCallback = callback
}

async function getRegistration(){
  return await global.navigator.serviceWorker.ready
}

export async function checkForNewServiceWorker(){
  const registration = await getRegistration()
  if (registration) {
    await registration.update()
    // registration.waiting.postMessage('skipWaiting')
  }
}

export async function reloadForNewServiceWorker(){
  const registration = await getRegistration()
  if (registration && registration.waiting) {
    // this requires serviceworker code to handle this
    // message in src/serviceworker.skipwaiting.js
    console.log(
      '%cInstalling new ServiceWorker!',
      'color: orange; font-size: 200%'
    )
    registration.waiting.postMessage('skipWaiting')
  }
}

if (
  process.env.NODE_ENV !== 'development' &&
  'serviceWorker' in global.navigator
) {
  global.navigator.serviceWorker.addEventListener(
    'controllerchange',
    function(){
      reloadForNewServiceWorker()
    },
  )

  global.navigator.serviceWorker.register('/service-worker.js').then(
    registration => {
      if (!registration) return
      if (onNewServiceWorkerCallback){
        function reportNewServiceWoker(){
          onNewServiceWorkerCallback()
        }
        function listenForInstalled(){
          registration.addEventListener('statechange', function(){
            if (this.state === 'installed') reportNewServiceWoker()
          })
        }
        if (registration.waiting) {
          reportNewServiceWoker()
        }else if (registration.installing){
          listenForInstalled()
        }else{
          registration.addEventListener('updatefound', listenForInstalled)
        }
      }
    },
    registrationError => {
      console.error('SW registration failed: ', registrationError)
    }
  )
}
