import { h } from 'preact'
import PNFO from 'jlinc-shared/PNFO'

const homePage = {
  position: 'bl',
  pathname: '/',
  selector: '.Navbar .Layout-TopNav-My-Feed',
  title: `Your Home Feed`,
  content: <div>
    <p>
      Your Home Feed combines published posts from the Hubs
      that you subscribe to.
    </p>
  </div>,
}

const allHubsPage = {
  position: 'bl',
  pathname: `/${PNFO.plural}/all`,
  selector: '.Navbar .Layout-TopNav-Hubs',
  title: `Lists of ${PNFO.plural}`,
  content: <div>
    All publicly listed {PNFO.plural} are shown under the tab on the right.
    When join a {PNFO.singular},
    it will be listed in the "Member" tab.
  </div>,
}

const hubCreatePage = {
  position: 'bl',
  pathname: `/${PNFO.singular}/new`,
  selector: '.HubsPage-createButton',
  title: `Create a ${PNFO.singular}`,
  content: <div>
    You can create {PNFO.plural} for friends or family, to
    publish personally, or for any group. You can
    also create {PNFO.plural} for your small business,
    non-profit or any other legal entity.
  </div>,
}

const publicProfilePage = {
  position: 'bl',
  pathname: '/@:username',
  selector: '.Navbar .LinkToPublicProfile .EndUserAvatar',
  title: 'Your Profile Page',
  content: <div>
    You can edit your profile to add a photo, bio
    and banner. You can post here for friends and
    re-post to {PNFO.plural} from here. People can message
    you here and Curators can invite people
    to {PNFO.plural} from their profiles.
  </div>,
}

const hubAboutPage = {
  position: 'tr',
  pathname: '/:organizationApikey',
  selector: '.OrganizationPage-NameAndStats',
  title: `This is the ${PNFO.singular}'s main page.`,
  content: <div>
    On {APP_NAME}, people connect and share on {PNFO.plural}. Each {PNFO.singular} has a home page that can be
    fully formatted or just list its basic info.
  </div>,
}

const hubPublishedPage = {
  position: 'tr',
  pathname: '/:organizationApikey/published',
  selector: '.OrganizationPage-navButton-Published',
  title: `Published posts from this ${PNFO.singular}`,
  content: <div>
    This page feed displays the posts that the curators of this {PNFO.singular} have chosen to publish. Click on
    the 3 dots in the upper right of any post to see the {PROVENANCE_CHAIN_NAME}, or to re-post it to
    another {PNFO.singular}.
  </div>,
}

const hubPeoplePage = {
  position: 'tl',
  pathname: '/:organizationApikey/members',
  selector: '.OrganizationPage-navButton-Members',
  title: 'Members of this Hub',
  content: <div>
    People who join this {PNFO.singular} as members are listed here.
    Curators, who manage {PNFO.singular} subscriptions, publishing and
    membership are listed first. Curators can also copy
    invite links from here to paste.
  </div>,
}

const hubForumPage = {
  position: 'tl',
  pathname: '/:organizationApikey/forum',
  selector: '.OrganizationPage-navButton-Forum',
  title: `Forum for members of this ${PNFO.singular}`,
  content: <div>
    This is the private social space where members can post and comment on content posted by members and fed by
    subscription from other {PNFO.plural}. Each forum has up-voting like a private subreddit. Curators
    select posts to publish.
  </div>,
}

// const hubChatPage = {
//   position: 'tl',
//   pathname: '/:organizationApikey/chat',
//   selector: '.OrganizationPage-navButton-Chat',
//   title: `Chat with members of this ${PNFO.singular}`,
//   content: <div>
//     You can direct message any member or group of members in Hubs you join.
//   </div>,
// }

const backToHubForumPage = {
  ...hubForumPage,
  position: 'tr',
  selector: '.FeedPostForm',
  title: `Post to the forum!`,
  spotlightOptions: {
    circular: false,
  },
  content: <div>
    Click here to make your first post.
  </div>,
}

const STEPS = [
  hubAboutPage,
  hubPublishedPage,
  hubForumPage,
  // hubChatPage,
  hubPeoplePage,
  homePage,
  allHubsPage,
  hubCreatePage,
  publicProfilePage,
  backToHubForumPage,
]

export default STEPS
