import PNFO from 'jlinc-shared/PNFO'

import PathnameRouter from 'lib/PathnameRouter'

import PageLoadingPage from './pages/PageLoadingPage'
import AuthPage from './pages/AuthPage'
import HomePage from './pages/HomePage'
import SearchPage from './pages/SearchPage'
import ChatPage from './pages/ChatPage'
import UserProfilePage from './pages/UserProfilePage'
import OrganizationPage from './pages/OrganizationPage'
import OrganizationJoinPage from './pages/OrganizationJoinPage'
import HubsPage from './pages/HubsPage'
import MyDataDefaultsPage from './pages/MyDataDefaultsPage'

// Lazy loaded pages
const lazy = loader => PageLoadingPage.forImport(loader)
const SettingsPage
  = lazy(() => import(/* webpackChunkName: "SettingsPage" */ './pages/SettingsPage'))
const OrganizationCreatePage
  = lazy(() => import(/* webpackChunkName: "OrganizationCreatePage" */ './pages/OrganizationCreatePage'))

// Page Options
// const requireNotLoggedIn = true
const requireLoggedIn = true
// const noLayout = true

export default new PathnameRouter(map => {
  map('/',                                                  HomePage,                    {})
  map('/(login|logout|signup|join)',                        AuthPage,                    {})
  map('/:organizationApikey/join/:inviteToken?',            OrganizationJoinPage,        {})
  map('/chat',                                              ChatPage,                    {requireLoggedIn})
  map(`/${PNFO.plural}`,                                    HubsPage,                    {})
  map(`/${PNFO.plural}/(subscriber|all|favorites)`,         HubsPage,                    {})
  map(`/${PNFO.singular}/new`,                              OrganizationCreatePage,      {requireLoggedIn})
  map(`/${PNFO.singular}/new/:path*`,                       OrganizationCreatePage,      {requireLoggedIn})
  map(`/search/:searchQuery`,                               SearchPage,                  {})
  map(`/search/(${PNFO.plural}|members|posts)/:searchQuery`, SearchPage,                 {})
  map('/my-defaults',                                       MyDataDefaultsPage,          {requireLoggedIn})
  map('/settings',                                          SettingsPage,                {requireLoggedIn})
  map('/@:username',                                        UserProfilePage,             {})
  map('/@:username/:path*',                                 UserProfilePage,             {})
  map('/:organizationApikey',                               OrganizationPage,            {})
  map('/:organizationApikey/:path*',                        OrganizationPage,            {})
})
