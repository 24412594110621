import { h } from 'preact'
import PropTypes from 'prop-types'

import Pill from 'components/Pill'
import './index.sass'

export default function OrganizationMembershipPills({ className = '', organizationMembership }){
  return <div className={`OrganizationMembershipPills ${className}`}>
    {organizationMembership.admin && <Pill type="info">admin</Pill>}
    {(organizationMembership.admin || organizationMembership.curator) && <Pill type="info">curator</Pill>}
  </div>
}

OrganizationMembershipPills.propTypes = {
  className: PropTypes.string,
  organizationMembership: PropTypes.shape({
    admin: PropTypes.bool,
    curator: PropTypes.bool,
  }).isRequired,
}
