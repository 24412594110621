import { h } from 'preact'
import PropTypes from 'prop-types'

import { useFeed } from 'lib/feedPostHooks'

import InfiniteScrollDown from 'components/InfiniteScrollDown'
import OrganizationFeedPost from 'components/OrganizationFeedPost'
import LoggedOutFeedPostsMessage from 'components/LoggedOutFeedPostsMessage'
import './index.sass'

export default function OrganizationFeedPosts({
  className = '',
  feed,
  emptyMessage,
  fullyLoadedMessage,
  hideConsumedPosts,
  loggedOut,
}){
  const {
    loading,
    loadingError,
    posts,
    fullyLoaded,
    loadMore,
  } = useFeed(feed, 'OrganizationFeedPosts')

  if (loggedOut && fullyLoaded && posts.length === 0) return <LoggedOutFeedPostsMessage />

  return <InfiniteScrollDown {...{
    className: `OrganizationFeedPosts ${className}`,
    name: 'posts',
    loading,
    loadingError,
    fullyLoaded,
    loadMore,
    emptyMessage,
    fullyLoadedMessage,
  }}>
    {posts
      .filter(post =>
        !hideConsumedPosts || !post.parentUid
      )
      .map(post =>
        <OrganizationFeedPost
          key={post.uid}
          sortBy={post.lastPublishedAt || post.createdAt}
          {...{
            post,
          }}
          withinFeed
          feed={feed}
        />
      )
    }
  </InfiniteScrollDown>
}

OrganizationFeedPosts.propTypes = {
  className: PropTypes.string,
  feed: PropTypes.string.isRequired,
  emptyMessage: InfiniteScrollDown.propTypes.emptyMessage,
  fullyLoadedMessage: InfiniteScrollDown.propTypes.fullyLoadedMessage,
  usersFeed: PropTypes.bool,
  hideConsumedPosts: PropTypes.bool,
  loggedOut: PropTypes.bool,
}
