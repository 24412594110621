import { h, Fragment } from 'preact'
import PropTypes from 'prop-types'

import { useCreateOrganizationFeedPostComment } from 'lib/feedPostHooks'
import { useMyPublicProfile } from 'lib/membershipAppStateHooks'
import { useLocalStorage } from 'lib/storageHooks'

import Form from 'components/Form'
import PostAsOrganizationDropdown from 'components/PostAsOrganizationDropdown'
import OrganizationFeedPostAuthorIcon from 'components/OrganizationFeedPostAuthorIcon'
import ContentBox from 'components/ContentBox'
import AuthorIconBox from 'components/AuthorIconBox'
import OrganizationMembershipButton from 'components/OrganizationMembershipButton'
import RichMediaOrWYSIWYGInput from 'components/RichMediaOrWYSIWYGInput'
import FeedPostAndCommentForm from 'components/FeedPostAndCommentForm'
import './index.sass'

export default function OrganizationFeedPostCommentCreateForm({
  organization,
  feedPostUid,
  isMember,
  ...props
}){
  const { myPublicProfileDid } = useMyPublicProfile('OrganizationFeedPostCommentCreateForm')

  const [
    draftComment = {},
    updateDraft,
  ] = useLocalStorage(
    `organizationFeedPostComment:${feedPostUid}:draft`
  )

  const {
    createOrganizationFeedPostComment,
    creatingOrganizationFeedPostComment,
    errorCreatingOrganizationFeedPostComment,
  } = useCreateOrganizationFeedPostComment(
    feedPostUid,
    'OrganizationFeedPostCommentCreateForm',
  )

  let className = 'OrganizationFeedPostCommentCreateForm'
  if (props.className) className += ` ${props.className}`

  const disabled = !!(props.disabled || creatingOrganizationFeedPostComment) || !isMember
  const organizationApikey = organization.apikey
  const authorIsOrg = draftComment && organizationApikey === draftComment.posterOrganizationApikey

  const authorIcon = <OrganizationFeedPostAuthorIcon {...{
    organizationApikey: authorIsOrg && organizationApikey,
    publicProfileDid: authorIsOrg || myPublicProfileDid,
    bordered: authorIsOrg
  }}/>
  return <AuthorIconBox {...{className}}>
    {authorIcon}
    <ContentBox>
      {!isMember &&
        <div>
          <OrganizationMembershipButton {...{organization}}/> to comment
        </div>
      }
      <FeedPostAndCommentForm {...{
        disabled,
        value: draftComment,
        onChange: updateDraft,
        onSubmit: createOrganizationFeedPostComment,
        submitting: creatingOrganizationFeedPostComment,
        error: errorCreatingOrganizationFeedPostComment,
        onSuccess: () => { updateDraft(undefined) },
        alwaysShowControls: true,
      }}>
        {
          ({ form, scheduleFocusInput }) =>
            <FormFields {...{form, authorIcon, organizationApikey, scheduleFocusInput}} />
        }
      </FeedPostAndCommentForm >
    </ContentBox>
  </AuthorIconBox>
}

function FormFields({ form, authorIcon, organizationApikey, scheduleFocusInput }) {
  return <Fragment>
    <div className="OrganizationFeedPostCommentCreateForm-topRow">
      {form.item({
        valueProp: 'posterOrganizationApikey',
        input: <PostAsOrganizationDropdown {...{
          label: authorIcon && <span>{authorIcon}</span>,
          organizationApikey
        }}/>,
      })}
      <Form.Item>
        {form.bindInput({
          valueProp: 'body',
          input: <RichMediaOrWYSIWYGInput.Toggle onChange={scheduleFocusInput} />,
        })}
      </Form.Item>
    </div>
    {form.bindInput({
      valueProp: 'body',
      bindTo: 'onInput',
      unsaved: false,
      placeholder: `Leave a comment…`,
      input: <RichMediaOrWYSIWYGInput {...{ bigify: false }}/>,
    })}
  </Fragment>
}

OrganizationFeedPostCommentCreateForm.propTypes = {
  className: PropTypes.string,
  organization: PropTypes.object.isRequired,
  feedPostUid: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isMember: PropTypes.bool.isRequired,
}
