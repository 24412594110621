
export function normalizeVimeoUrl(url){
  const VIMEO_REGEX = /^https?:\/\/(?:player\.)?vimeo.com\/(?:video\/)?(?:album\/[^\/]+\/video\/)?(?:channels\/[^\/]+\/)?(?:groups\/[^\/]+\/videos\/)?(?:ondemand\/[^\/]+\/)?([^\/]+)/ // eslint-disable-line
  if (typeof url === 'string' && url.match(VIMEO_REGEX))
    return `https://player.vimeo.com/video/${RegExp.$1}`
}

export function isVimeoUrl(url){
  return !!normalizeVimeoUrl(url)
}
