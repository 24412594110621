import PNFO from 'jlinc-shared/PNFO'
import { h } from 'preact'
import { useRef, useState, useCallback, useEffect } from 'preact/hooks'

import PropTypes from 'prop-types'

import classNames from 'lib/classNames'
import { useEditOrganization } from 'lib/organizationAdminHooks'
import { useCreateAlert } from 'lib/alertHooks'

import OrganizationBannerInput from 'components/OrganizationBannerInput'
import './index.sass'

export default function EditableOrganizationBanner({
  className, organization, ...props
}){
  const organizationApikey = organization.apikey
  const {
    updateOrganization,
    updatingOrganization,
    updatingOrganizationError,
  } = useEditOrganization(
    organizationApikey,
    'EditableOrganizationBanner'
  )
  const persistedValue = organization && organization.consumer_banner

  const updatingRef = useRef(!!updatingOrganization)
  const [value, setValue] = useState()

  const createAlert = useCreateAlert('EditableOrganizationBanner')

  const onChange = useCallback(banner => {
    setValue(banner)
    updateOrganization({ consumer_banner: banner })
    updatingRef.current = true
  }, [])

  useEffect(
    () => {
      if (updatingRef.current && !updatingOrganization){
        updatingRef.current = false
        if (updatingOrganizationError) {
          setValue()
          createAlert.error(`Failed to save new ${PNFO.singular} banner 🙁`)
        }else{
          createAlert.success(`New ${PNFO.singular} banner Saved 😀`)
        }
      }
    },
    [updatingOrganization, updatingOrganizationError]
  )

  return <div
    className={classNames('EditableOrganizationBanner', { className })}
  >
    <OrganizationBannerInput {...{
      ...props,
      class: undefined, // prevent dup classname
      disabled: !!(!organization || updatingOrganization),
      value: value || persistedValue,
      onChange,
      unsaved: (
        organization &&
        value &&
        value !== persistedValue
      ),
    }}/>
  </div>
}

EditableOrganizationBanner.propTypes = {
  className: PropTypes.string,
  organization: PropTypes.shape({
    apikey: PropTypes.string.isRequired,
    consumer_banner: PropTypes.string,
  }).isRequired,
}
