import { h } from 'preact'
import { useMemo, useCallback } from 'preact/hooks'

import pathnameMatcher from 'lib/pathnameMatcher'

import OrganizationHomePage from '../OrganizationHomePage'
import OrganizationFeedPage from '../OrganizationFeedPage'
import OrganizationFeedPostPage from '../OrganizationFeedPostPage'
import OrganizationChannelPage from '../OrganizationChannelPage'
import OrganizationChannelPostPage from '../OrganizationChannelPostPage'
import OrganizationSisaPage from '../OrganizationSisaPage'
import OrganizationPersonalDataPage from '../OrganizationPersonalDataPage'
import OrganizationSisaEventPage from '../OrganizationSisaEventPage'
import OrganizationAdminPage from '../OrganizationAdminPage'
import OrganizationChatPage from '../OrganizationChatPage'
import OrganizationMembersPage from '../OrganizationMembersPage'
import MarketplacePage from '../MarketplacePage'
// import OrganizationJoinPage from '../OrganizationJoinPage'
import OrganizationNetworkPage from '../OrganizationNetworkPage'

export function useOrganizationPagePages({
  orgIsClosed,
  orgIsPrivate,
  userIsLoggedIn,
  userIsMember,
  userIsCurator,
  userIsAdmin,
  forumMembersOnly,
}){
  const pages = useMemo(
    () => {
      const pages = getPages({orgIsClosed, orgIsPrivate, forumMembersOnly})
      for (const page of pages) page.unauthorized = (
        (page.reqs.isLoggedIn && !userIsLoggedIn) ? 'notLoggedIn' :
        (page.reqs.isMember && !userIsMember) ? 'notMember' :
        (page.reqs.isCurator && !userIsCurator) ? 'notCurator' :
        (page.reqs.isAdmin && !userIsAdmin) ? 'notAdmin' :
        undefined
      )
      return pages
    },
    [orgIsClosed, orgIsPrivate, userIsMember, userIsAdmin, userIsCurator],
  )
  const findPage = useCallback(
    subpath => {
      let params
      let page = pages.find(page =>
        params = pathnameMatcher.match(subpath, page.route)
      )
      return {page, params}
    },
    [pages],
  )
  return { pages, findPage }
}


function getPages({orgIsClosed, orgIsPrivate, forumMembersOnly}){
  return [
    {
      title: 'Home',
      route: '/',
      comp: OrganizationHomePage,
      reqs: {},
    },
    {
      title: 'SISA',
      route: '/sisa',
      comp: OrganizationSisaPage,
      reqs: {isLoggedIn: true},
    },
    {
      route: '/sisa-events/:sisaEventId',
      comp: OrganizationSisaEventPage,
      reqs: {isMember: true},
    },
    {
      title: 'My Data',
      route: '/my-data',
      comp: OrganizationPersonalDataPage,
      reqs: {isLoggedIn: true},
    },
    {
      title: 'Admin',
      route: '/admin/:path*',
      comp: OrganizationAdminPage,
      reqs: {isCurator: true},
    },
    // {
    //   title: 'Join',
    //   route: '/join/:inviteToken?',
    //   comp: OrganizationJoinPage,
    //   reqs: {},
    // },
    ...(isDataYogi ? [
      {
        title: 'Marketplace',
        route: '/marketplace',
        comp: MarketplacePage,
        reqs: {isLoggedIn: true},
      },
      {
        title: 'My Stuff',
        route: '/my-stuff',
        comp: MarketplacePage,
        reqs: {isLoggedIn: true},
      },
    ] : [
      {
        title: 'Published',
        route: '/published',
        comp: OrganizationFeedPage,
        reqs: {isMember: !!orgIsClosed},
      },
      {
        title: 'Published Post',
        route: '/published/:feedPostUid',
        comp: OrganizationFeedPostPage,
        reqs: {isMember: !!orgIsClosed},
      },
      {
        title: 'Forum',
        route: '/forum/(hot|top)?',
        comp: OrganizationChannelPage,
        reqs: {loggedIn: true, isMember: !!orgIsPrivate || forumMembersOnly},
      },
      {
        title: 'Internal Post',
        route: '/forum/:feedPostUid',
        comp: OrganizationChannelPostPage,
        reqs: {loggedIn: true, isMember: !!orgIsPrivate},
      },
      {
        title: 'Chat',
        route: '/chat/:channel?',
        comp: OrganizationChatPage,
        reqs: {isMember: true},
      },
      {
        title: 'Members',
        route: '/members',
        comp: OrganizationMembersPage,
        reqs: {isMember: true},
      },
      {
        title: 'Members',
        route: '/members/@:username',
        comp: OrganizationMembersPage,
        reqs: {isMember: true},
      },
      {
        title: 'Network',
        route: '/network/:path*',
        comp: OrganizationNetworkPage,
        reqs: {isLoggedIn: true, isMember: !!(orgIsPrivate || orgIsClosed)},
      },
    ]),
  ]
}
